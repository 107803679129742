import { task, all } from 'ember-concurrency';
import { filterBy } from '@ember/object/computed';
import { computed, observer } from '@ember/object';
import { alias } from '@ember/object/computed';
import  Controller  from '@ember/controller';
import { bind } from '@ember/runloop';
import { A } from '@ember/array';
import EmObject from '@ember/object';
import { inject as service } from '@ember/service';
import ENV from '../../config/environment';
/* global moment*/
export default Controller.extend({
  rate: 1,
  actions: {
    handleKeyPress(k) { //eslint-disable-line no-unused-vars
      // console.log(k);
    },
    deleteEvent(evt) {
      this.get('_deleteEvent').perform(evt);
    },
    navigate() {
      this.transitionToRoute('index');
    },
    startGame() {
      this.get('_startGame').perform();
    }
  },
  homeOnLeft: true,
  leftColor: '#FFFFFF',
  rightColor: '#FFFFFF',
  init() {
    this._super();
    this.set('originator', moment());
  },
  _startGame: task(function* () {
    let game = this.get('model.game');
    if (game) {
      game.set('status', 'live');
      yield game.save();
    }
  }),
  _resetFouls: task(function* (homeTeamGame, awayTeamGame) {
    if (homeTeamGame)
    {
      yield homeTeamGame.resetFouls();
    }
    if (awayTeamGame)
    {
      yield awayTeamGame.resetFouls();
    }
  }),
  _deleteEvent: task(function* (evt) {
    if (!evt.get('deleting')) {
      let originator = this.get('originator');
      evt.set('deleting', true);
      let scored = undefined;
      if (evt.get('type0') === 0) {
        scored = evt.get('shotMade') ? evt.get('type1') : 0;
      }
      let eventLedger = this.get('model.eventLedger');
      let events = eventLedger.get('events');
      let homeTeamSeason = this.get('model.homeTeamSeason');
      let isHome = evt.get('teamSeason') === homeTeamSeason.id;
      let playerSeason = null;
      let playerGame = null;
      let playerSeasons = this.get(isHome ? 'homePlayers' : 'awayPlayers').filterBy('id', evt.get('playerSeason'));
      if (playerSeasons && playerSeasons.length) {
        playerSeason = playerSeasons[0];
        playerGame = playerSeason.get('playerGame');
      }
      let _updates = [evt];
      let game = this.get('model.game');
      
      let teamGame = this.get(isHome ? 'model.homeTeamGame' : 'model.awayTeamGame');
      if (playerGame) {
        yield playerGame.rollback(_updates, originator);
      }
      if (teamGame) {
        yield teamGame.rollback(_updates, originator);
      }
      let saveGame = false;
      if (game)
      {
        if (game.get('isScheduled'))
        {
          game.set('status', 'live');
          saveGame = true;
        }
        else if (game.get('isFinished'))
        {
          game.set('status', 'edited');
          saveGame = true;
        }
        if (scored) {
          game.decrementProperty(isHome ? 'homeScore' : 'awayScore', scored);
          saveGame = true;
        }
        if (saveGame)
        {
          yield game.save();
        }
      }

      if (events && eventLedger) {
        //yield evt.destroyRecord();
        events.removeObject(evt);
        eventLedger.set('status', `${evt.id}`);
        yield eventLedger.save({ adapterOptions: { 'gameId' : game.id } });
      }
    }
  }),
  session: service(),
  courtName: 'USHS',
  courtNumber: 0,
  courtType: alias('model.game.courtType'),
  isLive: alias('model.game.isLive'),
  isScheduled: alias('model.game.isScheduled'),
  isValid: alias('model.game.isValid'),
  clockStart: alias('model.game.clockStart'),
  clockPause: alias('model.game.clockPause'),
  periodCurrent: alias('model.game.periodCurrent'),
  activeAwayPlayer: computed('awayPlayers.@each.selected', function() {
    let awayPlayers = this.get('awayPlayers');
    let active = undefined;
    if (awayPlayers) {
      awayPlayers.forEach((e) => {
        if (e.get('selected')) {
          active = e;
        }
      });
    }
    return active;
  }),
  activeHomePlayer: computed('homePlayers.@each.selected', function() {
    let homePlayers = this.get('homePlayers');
    let active = undefined;
    if (homePlayers) {
      homePlayers.forEach((e) => {
        if (e.get('selected')) {
          active = e;
        }
      });
    }
    return active;
  }),
  shots: computed('events.@each.type0', function () {
    return this.get('events').filter((shot) => {
      let type0 = shot.get('type0');
      let type1 = shot.get('type1');
      return type0 === 0 && type1 > 1;
    });
  }),
  events: computed('model.eventLedger.events.@each.stateName', function () {
    let ret = A();
    let events = this.get('model.eventLedger.events');
    events.forEach((e) => {
      if (e.get('isValid')) {
        ret.pushObject(e);
      }
    });
    return ret;
  }),
  timings: computed('model.eventLedger.timings.@each.stateName', function () {
    let ret = A();
    let timings = this.get('model.eventLedger.timings');
    timings.forEach((e) => {
      if (e.get('isValid')) {
        ret.pushObject(e);
      }
    });
    return ret;
  }),
  filterChanged: observer('filter', 'model', function(){
    let filter = this.get('filter');
    let model = this.get('model');
    if (filter !== undefined && model)
    {
      let eventFilter = [];
      let periodFilter = '';
      let teamSeasonFilter = '';
      let playerSeasonFilter = '';
      
      let isHome = undefined;
      let parts = filter.split('.');
      for (var i = 0;i<parts.length;i++)
      {
        let playerNumber = undefined;
        let part = parts[i];
        if (part.length === 1)
        {
          if (part === 'h') { isHome = true; teamSeasonFilter = this.get('model.homeTeamSeason.id');}
          if (part === 'a') { isHome = false; teamSeasonFilter = this.get('model.awayTeamSeason.id');}
          if (part % 1 === 0) { playerNumber = part;}
        }
        else if (part.length > 1)
        {
          if (part.startsWith('per') && part.length > 3) {
            var p = part.replace('per','');
            periodFilter += ` ${(Number(p))}`;
          }
          else if (part % 1 === 0) { playerNumber = part;}
          else { 
            eventFilter.push(part);
          }
        }

        if (playerNumber)
        {
          let players = this.get(isHome ? 'homePlayers' : 'awayPlayers');
          players.forEach((p) => {
            if (p.get('number') === playerNumber) { 
              playerSeasonFilter+= ` ${p.get('id')}`;
            }
          });
        }
      }
      this.set('eventFilter', eventFilter.length?eventFilter:undefined);
      this.set('periodFilter', periodFilter.length?periodFilter:undefined);
      this.set('teamSeasonFilter', teamSeasonFilter.length?teamSeasonFilter:undefined);
      this.set('playerSeasonFilter', playerSeasonFilter.length?playerSeasonFilter:undefined);
    }
    else
    {
      this.set('eventFilter', undefined);
      this.set('periodFilter', undefined);
      this.set('teamSeasonFilter', undefined);
      this.set('playerSeasonFilter', undefined);
    }
  }),
  filteredEvents: computed('events.[]', 'periodFilter', 'eventFilter', 'teamSeasonFilter', 'playerSeasonFilter', function(){
    let evts=this.get('events');
    let _periodFilter=this.get('periodFilter');
    let _eventFilter = this.get('eventFilter');
    let _teamSeasonFilter=this.get('teamSeasonFilter');
    let _playerSeasonFilter=this.get('playerSeasonFilter');
    let filter = _periodFilter || _eventFilter || _teamSeasonFilter || _playerSeasonFilter;
    return evts.filter(function(item) {
        if (filter) {
          return item.include(_eventFilter,_periodFilter,_teamSeasonFilter,_playerSeasonFilter);
        } else { return true; }
      });
  }),
  sortedEvents: computed('filteredEvents.@each.{secondsRemaining,period,timestamp}', function () {
    let evts = this.get('filteredEvents').toArray();
    return evts.sort((a, b) => {
      let periodA = a.get('period');
      let periodB = b.get('period');
      if (periodA === periodB) {
        let secondsRemainingA = a.get('secondsRemaining');
        let secondsRemainingB = b.get('secondsRemaining');
        if (secondsRemainingA === secondsRemainingB) {
          let timestampA = a.get('timestamp');
          let timestampB = b.get('timestamp');
          if (timestampA === timestampB) {
            return 0;
          }
          return timestampA > timestampB ? -1 : 1;
        }
        return secondsRemainingA > secondsRemainingB ? 1 : -1;
      }
      return periodA > periodB ? -1 : 1;
    });
  }),
  sortedTimings: computed('timings.@each.offset', function () {
    let timings = this.get('timings').toArray();
    return timings.sort((a, b) => {
      let offsetA = a.get('offset');
      let offsetB = b.get('offset');
      if (offsetA === offsetB)
      {
        return 0;
      }
      return offsetA > offsetB ? -1 : 1;
    });
  }),
  accumulatedGameSeconds: function () {
    return _getAccumulatedGameSeconds(this.get('model.game.periodCurrent'), this.secondsRemaining(), this.get('model.game.periodSeconds'), this.get('model.game.periodCount'), this.get('model.game.periodOvertimeSeconds'));
  },
  videoTime: function() {
    return undefined;
  },
  clock: function(start) {
    return undefined;
  },
  secondsRemaining: function () {
    var clockStart = this.get('model.game.clockStart');
    var clockPause = this.get('model.game.clockPause');
    var periodSecondsLeft = this.get('model.game.periodSecondsLeft');
    let rate = this.get('rate');
    var seconds = 0;
    if (clockPause) {
      let cpr = this.get('clockPauseRate');
      if (cpr)
      {
        rate=cpr;
      }
      let diff = clockPause.diff(clockStart)*rate;
      seconds = Math.floor(moment.duration(diff).asSeconds());
    }
    else if (clockStart) {
      let diff = moment().diff(clockStart)*rate;
      seconds = Math.floor(moment.duration(diff).asSeconds());
    }
    var remaining = periodSecondsLeft - seconds;
    return remaining >= 0 ? remaining : 0;
  },
  resetFoulsCallback: computed(function (homeTeamGame, awayTeamGame){ //eslint-disable-line no-unused-vars
    return bind(this, function (homeTeamGame, awayTeamGame) {
      this.get('_resetFouls').perform(homeTeamGame, awayTeamGame);
    });
  }),
  possessionCallback: computed(function (game, possession) { //eslint-disable-line no-unused-vars
    return bind(this, function (game, possession) {
      this.get('changePossession').perform(game, possession);
    });
  }),
  courtCallback: computed(function (eventType, shotType, type2, isLeft, pt) { //eslint-disable-line no-unused-vars
    return bind(this, function (eventType, shotType, type2, isLeft, pt) {
      this.buildEvents(this.store, eventType, shotType, type2, pt, this.get('homeOnLeft') ? isLeft: !isLeft);
    });
  }),
  clockCallback: computed(function (game, clockStart, secondsRemaining) { //eslint-disable-line no-unused-vars
    return bind(this, function (game,clockStart,secondsRemaining) {
      this.get('toggleClock').perform(game, clockStart, secondsRemaining);
    });
  }),
  periodCallback: computed(function (game, period) { //eslint-disable-line no-unused-vars
    return bind(this, function (game, period) {
      if (game) {
        game.set('periodCurrent', period);
        game.save();
      }
    });
  }),
  teamMouseCallback: computed(function (entry) { //eslint-disable-line no-unused-vars
    return bind(this, function (entry) {
      if (entry)
      {
        let homeTeamId = this.get('model.homeTeam.id');
        if (entry.get('id') === homeTeamId) {
          this.set('awayTeamEntry.selected', false);
        } else {
          this.set('homeTeamEntry.selected', false);
        }
      }
    });
  }),
  paletteCallback: computed(function (evt, isHome) { //eslint-disable-line no-unused-vars
    return bind(this, function (evt, isHome) {
      this.buildEvents(this.store, evt, undefined, undefined, undefined, isHome);
    });
  }),
  inputCallback: computed(function (evt, type1, type2, pt, player, isHome) { //eslint-disable-line no-unused-vars
    return bind(this, function (evt, type1, type2, pt, player, isHome) {
      if (evt) {
        this.buildEvents(this.store, evt, type1, type2, pt, isHome, player);
      } else {
        if (player) {
          let players = this.get(isHome ? 'homePlayers' : 'awayPlayers');
          players.forEach((p) => {
            p.set('selected', p.get('number') === player);
          });

        }
        let awaySelected = this.get('awayTeamEntry.selected');
        let homeSelected = this.get('homeTeamEntry.selected');
        if (isHome !== undefined) {
          if ((isHome && homeSelected) || (!isHome && awaySelected)) {
            let players = this.get(isHome ? 'homePlayers' : 'awayPlayers');
            let desiredPlayer = players.get('lastObject');
            let currentPlayer = players.findBy('selected');
            if (currentPlayer) {
              currentPlayer.set('selected', false);
              let i = players.indexOf(currentPlayer);
              desiredPlayer = i > 0 ? players.objectAt(i - 1) : desiredPlayer;
            }
            if (desiredPlayer) {
              desiredPlayer.set('selected', true);
            }
          } else {
            this.set('awayTeamEntry.selected', isHome ? false : true);
            this.set('homeTeamEntry.selected', isHome ? true : false);
          }
        }
      }
    });
  }),
  playerMouseCallback: computed(function (player) { //eslint-disable-line no-unused-vars
    return bind(this, function (player) {
      var playerId = player.get('id');
      var home = player.get('isHome');
      var players = home ? this.get('homePlayers').rejectBy('id', playerId) : this.get('awayPlayers').rejectBy('id', playerId);
      players.forEach((p) => {
        p.set('selected', false);
      });
    });
  }),
  playerSubCallback: computed(function (player, isInGame, isHome) { //eslint-disable-line no-unused-vars
    return bind(this, function (player, isInGame, isHome) {
      let secondsRemaining = this.secondsRemaining();
      let teamSeason = this.get(isHome ? 'model.homeTeamSeason' : 'model.awayTeamSeason');
      let game = this.get('model.game');
      let account = this.get('model.game.account');
      let teamGame = this.get(isHome ? 'model.homeTeamGame' : 'model.awayTeamGame');

      let events = [];
      let playerGame = player.get('playerGame');
      events.push(this.subPlayer(playerGame, isInGame, isHome, teamSeason, player, game, account, secondsRemaining, teamGame));
      this.get('saveEvents').perform(events, teamSeason, player, game, account, teamGame, playerGame, isHome, undefined);

    });
  }),
  toggleCourtEndsCallback: computed(function(){
    return bind(this, function () {
      let homeOnLeft=!this.get('homeOnLeft');
      if (homeOnLeft)
      {
        this.set('leftColor', this.get('homeTeamEntry.homeColor'));
        this.set('rightColor', this.get('awayTeamEntry.awayColor'));
      }
      else
      {
        this.set('rightColor', this.get('homeTeamEntry.homeColor'));
        this.set('leftColor', this.get('awayTeamEntry.awayColor'));
      }
      this.set('homeOnLeft', homeOnLeft);
    });
  }),
  finalizeCallback: computed(function(gameId){ //eslint-disable-line no-unused-vars
    return bind(this, function (gameId) {
      this.transitionToRoute('game.finalize',gameId);
    });
  }),
  homeTeamEntry: computed('model.homeTeam', function () {
    return EmObject.create({
      selected: false,
      team: this.get('model.homeTeam'),
      homeColor: `#${this.get('model.homeTeam.homeColor')}`,
      id: this.get('model.homeTeam.id')
    });
  }),
  awayTeamEntry: computed('model.awayTeam', function () {
    return EmObject.create({
      selected: false,
      team: this.get('model.awayTeam'),
      awayColor: `#${this.get('model.awayTeam.awayColor')}`,
      id: this.get('model.awayTeam.id')
    });
  }),
  homePlayers: computed('model.{homePlayers.[],playerGames.[]}', function () {
    let p = this.get('model.homePlayers');
    let pg = this.get('model.playerGames');
    let o=0;
    return p.forEach((item) => {
      var playerGame = pg.findBy('playerSeason', item.id);
      if (playerGame) {
        item.set('isHome', true);
        item.set('playerGame', playerGame);
        item.set('selected', false);
        item.set('order', o++);
      }
    }).filterBy('playerGame');
  }),
  inGameHomePlayers: filterBy('homePlayers', 'isInGame', true),
  benchHomePlayers: filterBy('homePlayers', 'isInGame', false),
  awayPlayers: computed('model.{awayPlayers.[],playerGames.[]}', function () {
    let p = this.get('model.awayPlayers');
    let pg = this.get('model.playerGames');
    let o=0;
    return p.forEach((item) => {
      var playerGame = pg.findBy('playerSeason', item.id);
      if (playerGame) {
        item.set('isHome', false);
        item.set('selected', false);
        item.set('playerGame', playerGame);
        item.set('order', o++);
      }
    }).filterBy('playerGame');
  }),
  inGameAwayPlayers: filterBy('awayPlayers', 'isInGame', true),
  benchAwayPlayers: filterBy('awayPlayers', 'isInGame', false),
  buildEvents: function (store, type0, type1, type2, pt, isHome, player) {
    let secondsRemaining = this.secondsRemaining();
    if (isHome === undefined)
    {
      if (this.get('awayTeamEntry.selected'))
      {
        isHome=false;
      }
      else if (this.get('homeTeamEntry.selected'))
      {
        isHome=true;
      }
      else if (this.get('activeHomePlayer'))
      {
        isHome=true;
      }
      else if (this.get('activeAwayPlayer'))
      {
        isHome=false;
      }
    }
    let teamSeason = this.get(isHome ? 'model.homeTeamSeason' : 'model.awayTeamSeason');
    let playerSeason = this.get(isHome ? 'activeHomePlayer' : 'activeAwayPlayer');
    let playerGame = this.get(isHome ? 'activeHomePlayer.playerGame' : 'activeAwayPlayer.playerGame');
    if (player) {
      var playerSeasons = this.get(isHome ? 'homePlayers' : 'awayPlayers').filterBy('number', player);
      if (playerSeasons && playerSeasons.length) {
        playerSeason = playerSeasons[0];
        playerGame = playerSeason.get('playerGame');
      }
    }
    let game = this.get('model.game');
    let account = this.get('model.game.account');
    let teamGame = this.get(isHome ? 'model.homeTeamGame' : 'model.awayTeamGame');

    let events = [];
    let scored = undefined;
    switch (type0) {
      case 'shotMake':
        scored = type1;
        events.push(this.buildEvent(store, 0, type1, type2, true, teamSeason, playerSeason, game, account, pt.x, pt.y, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'shotMiss':
        events.push(this.buildEvent(store, 0, type1, type2, false, teamSeason, playerSeason, game, account, pt.x, pt.y, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'putbackMake':
        scored = type1;
        events.push(this.buildEvent(store, 1, 0, undefined, undefined, teamSeason, playerSeason, game, account, pt.x, pt.y, secondsRemaining, teamGame, playerGame, isHome));
        events.push(this.buildEvent(store, 0, type1, type2, true, teamSeason, playerSeason, game, account, pt.x, pt.y, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'putbackMiss':
        events.push(this.buildEvent(store, 1, 0, undefined, undefined, teamSeason, playerSeason, game, account, pt.x, pt.y, secondsRemaining, teamGame, playerGame, isHome));
        events.push(this.buildEvent(store, 0, type1, type2, false, teamSeason, playerSeason, game, account, pt.x, pt.y, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'OR':
        events.push(this.buildEvent(store, 1, 0, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'DR':
        events.push(this.buildEvent(store, 1, 1, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'TO':
        events.push(this.buildEvent(store, 2, undefined, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'AST':
        events.push(this.buildEvent(store, 3, undefined, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'BLK':
        events.push(this.buildEvent(store, 4, undefined, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'STL':
        events.push(this.buildEvent(store, 5, undefined, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'PF':
        events.push(this.buildEvent(store, 6, 0, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'OF':
        events.push(this.buildEvent(store, 6, 1, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'IF':
        events.push(this.buildEvent(store, 6, 2, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'TF':
        events.push(this.buildEvent(store, 6, 3, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'FF':
        events.push(this.buildEvent(store, 6, 4, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'DF':
        events.push(this.buildEvent(store, 6, 5, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'SUB': {
        if (playerGame && isHome !== undefined) {
          events.push(this.subPlayer(playerGame, playerGame.get('isInGame'), isHome, teamSeason, playerSeason, game, account, secondsRemaining, teamGame));
        }
      } break;
      case 'DEF':
        events.push(this.buildEvent(store, 8, undefined, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'timeout20s':
        events.push(this.buildEvent(store, 9, 0, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'timeout30s':
        events.push(this.buildEvent(store, 9, 1, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'timeout60s':
        events.push(this.buildEvent(store, 9, 2, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'timeoutMedia':
        events.push(this.buildEvent(store, 9, 3, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'CD':
        events.push(this.buildEvent(store, 10, undefined, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'POS':
        events.push(this.buildEvent(store, 11, undefined, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'TIP':
        events.push(this.buildEvent(store, 11, 0, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'TIEUP':
        events.push(this.buildEvent(store, 11, 1, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'BLKR':
        events.push(this.buildEvent(store, 12, undefined, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      case 'PFR':
        events.push(this.buildEvent(store, 13, undefined, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome));
        break;
      default:
        break;
    }
    this.get('saveEvents').perform(events, teamSeason, playerSeason, game, account, teamGame, playerGame, isHome, scored);
  },
  subPlayer: function (playerGame, isInGame, isHome, teamSeason, playerSeason, game, account, secondsRemaining, teamGame) {
    if (isInGame) {
      let seconds = this.accumulatedGameSeconds();
      let entered = playerGame.get('enteredAt');
      let sub = this.buildEvent(this.store, 7, 1, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome);
      sub.set('subOutDuration', entered ? seconds - entered : 0);
      sub.set('subOutPlusMinus', playerGame.get('currentPlusMinus'));
      playerGame.set('enteredAt', undefined);
      playerGame.set('currentPlusMinus', undefined);
      playerGame.set('isInGame', false);
      return sub;
    } else {
      let sub = this.buildEvent(this.store, 7, 0, undefined, undefined, teamSeason, playerSeason, game, account, undefined, undefined, secondsRemaining, teamGame, playerGame, isHome);
      playerGame.set('isInGame', true);
      playerGame.set('enteredAt', this.accumulatedGameSeconds());
      playerGame.set('currentPlusMinus', 0);
      return sub;
    }
  },
  buildEvent: function (store, type0, type1, type2, made, teamSeason, playerSeason, game, account, shotX, shotY, secondsRemaining, teamGame, playerGame, isHome) { //eslint-disable-line no-unused-vars
    let clipMidpoint = this.videoTime();
    let filmLength = this.get('filmLength');
    let halfDefaultClipLength = ENV.defaultClipLength/2;
    let clipStart = (clipMidpoint - halfDefaultClipLength) > 0 ? clipMidpoint - halfDefaultClipLength : 0;
    let clipStop = (clipMidpoint +  halfDefaultClipLength) <= filmLength ? clipMidpoint + halfDefaultClipLength : filmLength;
    let evt = store.createRecord('bask-event',
      {
        timestamp: moment(),
        secondsRemaining,
        type0,
        type1,
        type2,
        shotMade: made,
        game: game.id,
        teamSeason: teamSeason.id,
        playerSeason: playerSeason ? playerSeason.id : undefined,
        account,
        period: game.get('periodCurrent'),
        shotX,
        shotY,
        clipStart,
        clipStop
      });
    return evt;
  },
  toggleClock: task(function* (game, _clockStart, secondsRemaining) {
    let originator=this.get('originator');
    if (secondsRemaining === undefined)
    {
      secondsRemaining = this.secondsRemaining();
    }
    
    if (_clockStart) {
      let period = game.get('periodCurrent');
      let periodSeconds = game.get('periodSeconds');
      let periodCount = game.get('periodCount');
      let periodOvertimeSeconds = game.get('periodOvertimeSeconds')
      let gamePeriodSecondsLeft = game.get('periodSecondsLeft');
      let timestampStart = game.get('clockStart');
      let timestampStop = moment();
      let clockStart = _getAccumulatedGameSeconds(period, gamePeriodSecondsLeft, periodSeconds, periodCount, periodOvertimeSeconds);
      let clockStop = _getAccumulatedGameSeconds(period, secondsRemaining, periodSeconds, periodCount, periodOvertimeSeconds);
      let clockDuration = clockStop-clockStart;
      let clipStop = this.videoTime();
      let clipStart = undefined;
      if (clipStop !==undefined)
      {
        clipStart = clipStop-clockDuration > 0 ? clipStop-clockDuration:0;
      }
      if (clockStart!=clockStop)
      {
        let timing = this.store.createRecord('timing',{
          timestampStart,
          timestampStop,
          secondsRemaining: gamePeriodSecondsLeft,
          secondsRemainingStop: secondsRemaining,
          clockStart,
          clockStop,
          clipStart,
          clipStop,
          game: game.id,
          account: game.get('account'),
          period
        });
        let eventLedger = this.get('model.eventLedger');
        let timings = eventLedger.get('timings');
        timings.pushObject(timing);
        yield eventLedger.save({ adapterOptions: { 'gameId' : game.id } });
      }
      game.set('periodSecondsLeft', secondsRemaining);
      game.set('clockStart', undefined);
      game.set('clockPause', undefined);
      this.clock(false);
    } else if (game.get('periodSecondsLeft') >= 0) {
      game.set('clockStart', moment());
      game.set('clockPause', undefined);
      this.clock(true);
    }
    game.set('originator', originator);
    yield game.save();
  }),
  changePossession: task(function* (game, possession) {
    yield this.buildEvents(this.store, 'POS', undefined, undefined, undefined, possession === -1);
    game.set('possessionCurrent', possession);
    yield game.save();

  }),
  saveEvents: task(function* (_events, teamSeason, playerSeason, game, account, teamGame, playerGame, isHome, scored) {
    let eventLedger = this.get('model.eventLedger');
    let events = eventLedger.get('events');
    let originator = this.get('originator');
    for (let i = 0; i < _events.length; i++) {
      let evt = _events[i];
      events.pushObject(evt);
    }
    if (teamGame) {
      yield teamGame.update(_events, originator);
    }
    if (playerGame) {
      yield playerGame.update(_events, originator);
    }
    yield eventLedger.save({ adapterOptions: { 'gameId' : game.id } });
    if (scored) {
      let homePlayers = this.get('inGameHomePlayers');
      let awayPlayers = this.get('inGameAwayPlayers');
      let updates = [];
      homePlayers.forEach((u) => {
        let pg = u.get('playerGame');
        pg.incrementProperty('currentPlusMinus', isHome ? scored : -scored);
        updates.push(pg.save());
      });
      awayPlayers.forEach((u) => {
        let pg = u.get('playerGame');
        pg.incrementProperty('currentPlusMinus', isHome ? -scored : scored);
        updates.push(pg.save());
      });
      yield all(updates);
      if (game.get('isScheduled'))
      {
        game.set('status', 'live');
      }
      else if (game.get('isFinished'))
      {
        game.set('status', 'edited');
      }
      game.incrementProperty(isHome ? 'homeScore' : 'awayScore', scored);
      game.set('originator', originator);
      yield game.save();
    }
  })
});

function _getAccumulatedGameSeconds(period, secondsRemaining, secondsPerPeriod, periodCount, secondsPerOvertime) {
  if (period + 1 <= periodCount) {
    return period * secondsPerPeriod + (secondsPerPeriod - secondsRemaining);
  }
  else {
    let completedOvertimes = period - periodCount;
    return periodCount * secondsPerPeriod + completedOvertimes * secondsPerOvertime + (secondsPerOvertime - secondsRemaining);
  }
}

