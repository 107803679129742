
import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),
  model() {
    let account = this.modelFor('account');
    if (account) {
      return this.get('loadData').perform(account);
    } else {
      throw new Error('Active account returned as null');
    }
  },
  loadData: task(function* (account) {
    let user = this.get('session.user');
    let users = yield account.get('users');
    let admins = yield account.get('admins');
    let invites = yield account.get('invites');
    return {
      account,
      user,
      users,
      admins,
      invites
    }
  }),
});
