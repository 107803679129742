define("stattracker-live/mixins/fb-upload", ["exports", "stattracker-shared/mixins/fb-upload"], function (_exports, _fbUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fbUpload.default;
    }
  });
});
