import SessionService from 'ember-simple-auth/services/session';
import { or, and, alias } from '@ember/object/computed';
import { htmlSafe } from '@ember/string';
import { task } from 'ember-concurrency';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import { all } from 'rsvp';
import { isEmpty } from '@ember/utils';
import { assert } from '@ember/debug';
import { computed, observer } from '@ember/object';
/* global moment */
export default SessionService.extend({
  cookies: service(),
  store: service(),
  metrics: service(),
  intl: service(),
  'st-api': service(),

  loadCurrentSession(queryParams) {
    return this.get('_loadCurrentSession').perform(queryParams);
  },

  _loadCurrentSession: task(function* (queryParams) {
    try {
      let userId = this.get('userId');
      if (!isEmpty(userId)) {
        let store = this.get('store');
        let user;
        try {
          user = yield store.find('user', userId);
        } catch(reason) {
          if (reason && reason.message && reason.message.startsWith('no record was found')) {
            store._removeFromIdMap(store._internalModelForId('user', userId));
            let provider = this.get('provider');
            yield this.signup(this.get('authDisplayName'), null, null, provider, userId);
            user = yield store.find('user', userId);
          }
        }
        assert('User is invalid', user);
        this.set('user', user);
        let status = this.get('user.status');
        this.set('userStatus', status ? status : 0);
        let activeAccountId = user.get('activeAccount');
        assert('No specified active account on user', !isEmpty(activeAccountId));
        let account = yield store.find('account', activeAccountId);
        assert('No account with specified active account', account);
        this.set('account', account);
        let api = this.get('st-api');
        if (api) {
          let params = { id: account.id };
          yield api.validate(params);
        }

        this.set('accountId', activeAccountId);
        this.set('owner', account.get('owner'));
        let admins = yield account.get('admins');
        let _isAdmin = admins.any((item) => userId === item.id);
        this.set('_isAdmin', _isAdmin);
        let activeSeasonId = yield account.get('activeSeason');
        this.set('activeSeasonId', activeSeasonId);
        this.set('isPublic', false);
        this.set('plan', account.get('plan'));
        if (account.get('cultureName'))
        {
          let intl = this.get('intl');
          if (intl.get('locale') !== account.get('cultureName'))
          {
            intl.set('locale',account.get('cultureName'));
          }
        }
        let accountPlan = yield store.find('plan', account.get('plan'));
        this.set('accountPlan', accountPlan);
        if (queryParams && !isEmpty(queryParams.inviteId)) {
          this.acceptInvitation(queryParams.inviteId, user);
        }
      }
    } catch(err) {
      this.set('error', err.message || err);
      this.invalidate();
    }
  }),

  authenticateUser(options) {
    this.set('provider', options.provider);
    return this.authenticate('authenticator:firebase', options);
  },

  setActiveReference(accountId, season, owner, plan, slug) {
    this.set('accountId', accountId);
    this.set('activeSeasonId', season);
    this.set('owner', owner);
    this.set('isPublic', true);
    this.set('userStatus', 0);
    this.set('plan', plan);
    if (slug) {
      let cookies = this.get('cookies');
      cookies.write('slug', slug, { 'maxAge': 86400, 'path': '/' });
      cookies.write('plan', plan, { 'maxAge': 86400, 'path': '/' });
    }
  },

  setActiveAccount(account) {
    assert('Trying to set active account to invalid object', account);
    this.set('account', account);
    let accountId = account.get('id');
    this.set('accountId', accountId);
    let userId = this.get('userId');
    if (!isEmpty(userId)) {
      let store = this.get('store');
      return store.find('user', userId).then(function(user) {
        user.set('activeAccount', accountId);
        user.save();
      });
    }
  },

  setActiveSeasonId(season) {
    this.set('activeSeasonId', season);
  },

  acceptInvitation(inviteId, user) {
    return this.get('_acceptInvitation').perform(inviteId, user);
  },

  _acceptInvitation: task(function* (inviteId, user) {
    let invite = yield this.get('store').find('invite', inviteId);
    let account = yield invite.get('account');
    if (invite && account) {
      account.get('users').pushObject(user);
      user.get('accounts').pushObject(account);
      account.get('invites').removeObject(invite);
      yield account.save();
      yield invite.destroyRecord();
      yield user.save();
      this.get('metrics').trackEvent('GoogleAnalytics', {
        category: 'account',
        action: 'accept invite'
      });
    }
  }),

  signup(name, inviteId, email, provider, uid) {
    let accountName = name.trim();
    let intl = this.get('intl');
    if (intl.get('locale').indexOf('en') > -1) {
      accountName = accountName[accountName.length - 1] === 's' ? `${accountName}'` : `${accountName}'s`;
    }
    let account = this.get('store').createRecord('account', {
      name: intl.t('account.accountFor', { name: htmlSafe(accountName) }).toString(),
      plan: 'free',
      subscriptionProvider: 'hachisoft',
      email,
      firstName: name.split(' ').slice(0, -1).join(' '),
      lastName: name.split(' ').slice(-1).join(' '),
      expirationDate: moment().add(6, 'months'),
      owner: uid
    });
    let user = this.get('store').createRecord('user', {
      id: uid,
      firstName: account.get('firstName'),
      lastName: account.get('lastName'),
      email,
      activeAccount: account.id,
      adminAccounts: A([account]),
      created: moment(),
      provider,
      status: provider === 'password' ? 1 : 2
    });
    account.get('admins').pushObject(user);
    this.setProperties({
      user,
      account
    });
    if (inviteId) {
      this.acceptInvitation(inviteId, user);
    }
    return all([account.save(), user.save()]);
  },

  isStatistician: alias('user.isStatistician'),
  isReviewer: alias('user.isReviewer'),
  isSuper: alias('user.isSuper'),
  canEdit: alias('isAdmin'),
  canDelete: alias('isAdmin'),
  canAdd: alias('isAdmin'),
  hasBreakdowns: alias('hasVideo'),
  hasVideo: alias('isVideo'),
  canTrack: alias('paidAccount'),
  paidAccount: or('isLeague', 'isTeam', 'isVideo'),

  isVideo: computed('account.{subscriptionProvider,stripeVideoSubscription}', function(){
    let account = this.get('account');
    if (account) {
      let provider=account.get('subscriptionProvider');
      if (provider === 'hachisoft_beta') {
        return true;
      }
      let videoSubscription = account.get('stripeVideoSubscription');
      if (videoSubscription)
      {
        return true;
      }
    }
    return false;
  }),

  canManageUsers: and('isAdmin', 'paidAccount'),

  isLeague: computed('plan', 'accountPlan', function(){
    let accountPlan = this.get('accountPlan');
    if (accountPlan) {
      let features=accountPlan.get('features');
      return features.includes('league');
    }
    let plan = this.get('plan');
    return plan === 'league';
  }),

  isTeam: computed('plan', 'accountPlan', function(){
    let accountPlan = this.get('accountPlan');
    if (accountPlan) {
      let features=accountPlan.get('features');
      return features.includes('team');
    }
    let plan = this.get('plan');
    return plan === 'team';
  }),

  isVideoLeague: and('isVideo', 'isLeague'),
  isVideoTeam:  and('isVideo', 'isTeam'),

  downloadPermissionsFilter(downloads, plan) {
    let result = A();
    downloads.forEach((o) => {
      let t = o.get('type');
      if (plan !== 'free') {
        //if (!t.includes('Json')) {
          result.pushObject(o);
        //}
      } else if (t === 'GameReportHTML') {
        result.pushObject(o);
      }
    });
    return result;
  },

  userId: alias('data.authenticated.uid'),

  accountActiveSeason: observer('account.activeSeason', function(){
    let activeSeasonId = this.get('activeSeasonId');
    let accountActiveSeason = this.get('account.activeSeason');
    if (activeSeasonId !== accountActiveSeason)
    {
      this.set('activeSeasonId', accountActiveSeason);
    }
  }),

  isAdmin: computed('_isAdmin', 'isSuper', function(){
    let isAdmin=this.get('_isAdmin');
    let isSuper = this.get('isSuper');
    return isAdmin || isSuper;
  }),

  isOwner: computed('owner', 'data.authenticated.uid', 'isSuper', function(){
    let owner=this.get('owner');
    let isSuper=this.get('isSuper');
    let uid=this.get('data.authenticated.uid');
    return (owner === uid) || isSuper;
  }),

  authDisplayName: alias('data.authenticated.displayName')
});
