import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
export default Route.extend({
  session: service(),
  model() {
    return this.get('loadData').perform(this.get('session.account.id'));
  },
  loadData: task(function* (accountId) {
    return yield this.store.query('bask-team', {
      orderBy: 'account',
      equalTo: accountId
    })
  })
});
