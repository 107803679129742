import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import $ from 'jquery';
export default Controller.extend({
  session: service(),
  actions: {
    delete() {
      let teamSeason = this.get('deletingTeamSeason');
      return teamSeason.destroyRecord();
    },
    confirmDelete(teamSeason) {
      $('.ui.modal').modal('show');
      this.set('deletingTeamSeason', teamSeason);
    },
    denyModal() {

    },
    toggleButtons(player) {
      player.toggleProperty('showingButtons');
    }
  }

});
