import Ember from 'ember';
import { hash } from 'ember/rsvp';
const { Route, inject: { service }, A } = Ember;
export default Route.extend({
  session: service(),
  firebaseApp: service(),
  model(params) {
    let api = this.get('st-api');
    let session = this.get('session');
    let _this = this;
    if (api) {
      return api.lookup(params).then(function(result) {
        if (session && result && result.season && result.teamSeason && result.customToken) {
          if (!session.get('isAuthenticated')) {
            let auth = _this.get('firebaseApp').auth();
            auth.signInWithCustomToken(result.customToken).catch(function(error) {
              console.log(error);
            });
          }

          session.setActiveReference(result.account, result.season, result.owner,'team',params.slug);
          return hash({
            activeSeason: result.season,
            homeGames: _this.store.query('bask-game', {
              orderBy: 'homeTeam',
              equalTo: result.teamSeason
            }),
            awayGames: _this.store.query('bask-game', {
              orderBy: 'awayTeam',
              equalTo: result.teamSeason
            })
          }).then((hash) => {
            let games = A();
            games.addObjects(hash.awayGames);
            games.addObjects(hash.homeGames);
            hash.games = games;
            return hash;
          }).catch((err) => {
            console.log(err);
          });
        } else {
          _this.transitionTo('index');
        }
      });
    }
  }
});

