import { task } from 'ember-concurrency';
import { observer } from '@ember/object';
import Controller from '@ember/controller';
import divisionData from '../../division-data';
import { inject as service } from '@ember/service';
import { all } from 'rsvp';
/* global moment*/
export default Controller.extend({
  session: service(),
  afterEditRoute: 'season.games',
  afterEditParams: 'model.season',
  actions: {
    clearData() {
      this.get('_clearData').perform();
    },
    save() {
      this.get('_save').perform();
    },
    cancel() {
      this.transitionToRoute('season.games', this.get('model.season'));
    }
  },
  _save: task(function* (){
    let model = this.get('model');
      let attrs = this.getProperties([
        'date',
        'hour',
        'minute',
        'periodCount',
        'periodMinutes',
        'overtimeMinutes',
        'to20sec',
        'to30sec',
        'to60sec',
        'location',
        'description',
        'tags',
        'neutralCourt',
        'league',
        'playoff',
        'runningClock',
        'courtType',
        'allowTimeTracking'
      ]);
      
      attrs.courtType = attrs.courtType.id;
      attrs.periodSeconds = attrs.allowTimeTracking ? attrs.periodMinutes * 60 : 0;
      attrs.periodOvertimeSeconds = attrs.allowTimeTracking ? attrs.overtimeMinutes * 60 : 0;
      attrs.timeout20Seconds = attrs.to20sec;
      attrs.timeout30Seconds = attrs.to30sec;
      attrs.timeout60Seconds = attrs.to60sec;
      if (attrs.date) {
        attrs.date = moment(attrs.date, 'MM/DD/YYYY');
        attrs.date.add(attrs.hour, 'hours');
        attrs.date.add(attrs.minute, 'minutes');
      }
      yield model.setProperties(attrs);
      yield model.save();
      this.transitionToRoute('season.games', model.get('season'));
  }),
  _clearData: task(function* () {
    let game = this.get('model');
    let homeTeam = yield game.get('homeTeam');
    let awayTeam = yield game.get('awayTeam');
    let playerGames=yield this.store.query('bask-player-game', {
      orderBy: 'game',
      equalTo: game.id
    });
    let teamGames=yield this.store.query('bask-team-game', {
      orderBy: 'game',
      equalTo: game.id
    });
    
    let homePlayers = yield homeTeam.get('playerSeasons');
    let awayPlayers = yield awayTeam.get('playerSeasons');
    let promises = [];
    if (homePlayers) {
      homePlayers.forEach((ps)=>{
        let hpg = playerGames.findBy('playerSeason', ps.id);
        if (!hpg)
        {
          let pg = this.store.createRecord('bask-player-game', {
            account: ps.get('account.id'),
            game: game.id,
            player: ps.get('player.id'),
            playerSeason: ps.id
          });
          promises.push(pg.save());
        }
      });
    }
    if (awayPlayers) {
      awayPlayers.forEach((ps)=>{
        let apg = playerGames.findBy('playerSeason', ps.id);
        if (!apg)
        {
          let pg = this.store.createRecord('bask-player-game', {
            account: ps.get('account.id'),
            game: game.id,
            player: ps.get('player.id'),
            playerSeason: ps.id
          });
          promises.push(pg.save());
        }
      });
    }  
    let ct=this.get('courtType');
    playerGames.forEach((pg)=>{
      promises.push(pg.reset());
    });
    teamGames.forEach((tg)=>{
      promises.push(tg.reset(ct.to20sec,ct.to30sec,ct.to60sec));
    });
    promises.push(game.reset());
    return yield all(promises);
  }),
  gameData: divisionData.gameData,
  courtTypeChanged: observer('courtType', function() {
    let ct = this.get('courtType');
    this.setProperties({
      periodCount: ct.periodCount,
      periodMinutes: ct.periodMinutes,
      overtimeMinutes: ct.overtimeMinutes,
      to20sec: ct.to20sec,
      to30sec: ct.to30sec,
      to60sec: ct.to60sec,
      bonus: ct.bonus,
      doubleBonus: ct.doubleBonus
    });
  }),
});
