import { task } from 'ember-concurrency';
import { computed, observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import ENV from '../../config/environment';
/* global moment */
import Controller from '@ember/controller';
export default Controller.extend({
  session: service(),
  intl: service(),
  rate: 1,
  currentClipTime: 0,

  init () {
    this._super(...arguments);
    this.setup= {
      playbackRates: [0.5, 1, 1.1, 1.25, 1.5]
    };
    this.set('include720', true);
    this.set('include360', true);
  },

  homeOnLeft: true,
  leftColor: '#FFFFFF',
  rightColor: '#FFFFFF',
  queryParams: ['clipTime', 'playbackRate', 'destination', 'filter'],
  quality: computed('include4k', 'include1080', 'include720', 'include480', 'include360', function() {
    let ret=[];
    if (this.get('include4k')) { ret.push(4000);}
    if (this.get('include1080')) { ret.push(1080);}
    if (this.get('include720')) { ret.push(720);}
    if (this.get('include480')) { ret.push(480);}
    if (this.get('include360')) { ret.push(360);}
    return ret;
  }),
  actions: {
    ready(player, component) {
      // This is pretty much the minimum required for a useful video player.
      component.bindPropertyToPlayer(player, 'src');
      component.sendActionOnPlayerEvent(player, 'loadedmetadata');
      component.sendActionOnPlayerEvent(player, 'play');
      component.sendActionOnPlayerEvent(player, 'playing');
      component.sendActionOnPlayerEvent(player, 'pause');
      component.sendActionOnPlayerEvent(player, 'stalled');
      component.sendActionOnPlayerEvent(player, 'waiting');
      component.sendActionOnPlayerEvent(player, 'ratechange');
      component.sendActionOnPlayerEvent(player, 'timeupdate');
      this.set('player', player);
      
      let clipTime=this.get('clipTime');
      if (clipTime)
      {
        player.currentTime(clipTime);
      }
      let rate = this.get('playbackRate');
      if (rate)
      {
        player.defaultPlaybackRate(rate);
      }
    },
    play(player, component) { //eslint-disable-line no-unused-vars
      let clockPause = this.get('clockPause');
      if (clockPause)
      {
        let clockStart=this.get('clockStart');
        let game = this.get('model.game');
        let diff = clockPause.diff(clockStart);
        game.set('clockPause', undefined);
        game.set('clockStart', moment().subtract(diff, 'ms'));
        this.get('saveGame').perform(game);
      }
      let clockStart = this.get('clockStart');
      if (clockStart)
      {
        this.allowRateChange(false);
      }
    },
    playing(player, component) { //eslint-disable-line no-unused-vars
      console.log('playing');
    },
    pause(player, component) { //eslint-disable-line no-unused-vars
      let clockStart = this.get('clockStart');
      if (clockStart)
      {
        let game = this.get('model.game');
        game.set('clockPause', moment());
        this.set('clockPauseRate', this.get('rate'));
        this.get('saveGame').perform(game);
      }
      this.allowRateChange(true);
    },
    stalled(player, component) { //eslint-disable-line no-unused-vars
      if (this.get('clockStarted'))
      {
        console.log('stalled while clock running')
      }
      else {
        console.log('stalled');
      }
    },
    waiting(player, component) { //eslint-disable-line no-unused-vars
      if (this.get('clockStarted'))
      {
        console.log('waiting while clock running')
      }
      else {
        console.log('waiting');
      }
    },
    ratechange(player, component) { //eslint-disable-line no-unused-vars
      let rate=player.playbackRate();
      this.set('rate', rate);
    },
    loadedmetadata(player, component) { //eslint-disable-line no-unused-vars
      this.set('filmLength', player.duration());
    },
    seekClipStart(evt) {
      let player = this.get('player');
      let clipStart = evt.get('clipStart');

      let rawFilm = evt.get('gameRef.rawFilm');
      if (rawFilm && this.get('rawFilm')!==rawFilm)
      {
        this.set('rawFilm', rawFilm);
      }
      if (rawFilm && player && clipStart !== undefined)
      {
        player.pause();
        player.currentTime(clipStart);
        player.play();
      }
    },
    timeupdate(player, component) //eslint-disable-line no-unused-vars
    {
      this.set('currentClipTime', player.currentTime());
    },
    useAll(){
      let events=this.get('sortedEvents');
      let useAll=!this.get('useAll')
      events.forEach(u=>{
        u.set('use', useAll);
      });
      this.set('useAll', useAll);
    }
  },
  allowRateChange(allow)
  {
    let player=this.get('player');
    if (player)
    {
      if (allow)
      {
        player.controlBar.playbackRateMenuButton.enable();
      }
      else
      {
        player.controlBar.playbackRateMenuButton.disable();
      }
    }
  },
  events: computed('allEvents.@each.stateName', function () {
    let ret = A();
    let events = this.get('allEvents');
    if (events) 
    {
      events.forEach((e) => {
        if (e.get('isValid')) {
          ret.pushObject(e);
        }
      });
    }
    return ret;
  }),
  filterChanged: observer('filter', 'model', 'playerSeason', function(){
    let filter = this.get('filter');
    let model = this.get('model');
    let playerSeason = this.get('playerSeason');
    if (filter !== undefined && model)
    {
      let eventFilter = [];
      let periodFilter = '';
      let teamSeasonFilter = '';
      let playerSeasonFilter = '';
      
      let parts = filter.split('.');
      for (var i = 0;i<parts.length;i++)
      {
        let playerNumber = undefined;
        let part = parts[i];
        if (part.length === 1)
        {
          if (part === 't') { teamSeasonFilter = this.get('model.teamSeasonId');}
          if (part % 1 === 0) { playerNumber = part;}
        }
        else if (part.length > 1)
        {
          if (part.startsWith('per') && part.length > 3) {
            var p = part.replace('per','');
            periodFilter += ` ${(Number(p))}`;
          }
          else if (part % 1 === 0) { playerNumber = part;}
          else { 
            eventFilter.push(part);
          }
        }
        if (playerSeason)
        {
          playerSeasonFilter+= ` ${playerSeason}`;
        }
        if (playerNumber)
        {
          let players = this.get('model.players');
          if (players)
          {
            players.forEach((p) => {
              if (p.get('number') === playerNumber) { 
                playerSeasonFilter+= ` ${p.get('id')}`;
              }
            });
          }
        }
      }
      this.set('eventFilter', eventFilter.length?eventFilter:undefined);
      this.set('periodFilter', periodFilter.length?periodFilter:undefined);
      this.set('teamSeasonFilter', teamSeasonFilter.length?teamSeasonFilter:undefined);
      this.set('playerSeasonFilter', playerSeasonFilter.length?playerSeasonFilter:undefined);
    }
    else
    {
      this.set('eventFilter', undefined);
      this.set('periodFilter', undefined);
      this.set('teamSeasonFilter', undefined);
      this.set('playerSeasonFilter', undefined);
    }
  }),
  filteredEvents: computed('events.[]', 'periodFilter', 'eventFilter', 'teamSeasonFilter', 'playerSeasonFilter', function(){
    let evts=this.get('events');
    let _periodFilter=this.get('periodFilter');
    let _eventFilter = this.get('eventFilter');
    let _teamSeasonFilter=this.get('teamSeasonFilter');
    let _playerSeasonFilter=this.get('playerSeasonFilter');
    let filter = _periodFilter || _eventFilter || _teamSeasonFilter || _playerSeasonFilter;
    return evts.filter(function(item) {
        if (filter) {
          return item.include(_eventFilter,_periodFilter,_teamSeasonFilter,_playerSeasonFilter);
        } else { return true; }
      });
  }),
  sortedEvents: computed('filteredEvents.@each.{secondsRemaining,period,timestamp}', function () {
    let evts = this.get('filteredEvents').toArray();
    return evts.sort((a, b) => {
      let periodA = a.get('period');
      let periodB = b.get('period');
      if (periodA === periodB) {
        let secondsRemainingA = a.get('secondsRemaining');
        let secondsRemainingB = b.get('secondsRemaining');
        if (secondsRemainingA === secondsRemainingB) {
          let timestampA = a.get('timestamp');
          let timestampB = b.get('timestamp');
          if (timestampA === timestampB) {
            return 0;
          }
          return timestampA > timestampB ? -1 : 1;
        }
        return secondsRemainingA > secondsRemainingB ? 1 : -1;
      }
      return periodA > periodB ? -1 : 1;
    });
  }),
  canMakeReel: computed('makeReel.isRunning', 'reelName', function() {
    let reelName = this.get('reelName');
    return !this.get('makeReel.isRunning') && reelName && reelName.length>0;
  }),
  
  videoTime: function() {
    let player = this.get('player');
    if (player)
    {
      return player.currentTime();
    }
    return undefined;
  },
  makeReel: task(function* (){
    let api = this.get('st-api');
    let allSegments = [];
    let halfDefaultClipLength = ENV.defaultClipLength/2;
    let events = this.get('sortedEvents').toArray();
    let reelName = this.get('reelName');
    let reelDesc = this.get('reelDesc');
    let playerSeasonId = this.get('playerSeason');
    let season = this.get('model.season.id');
    let teamSeasonId = this.get('model.teamSeasonId');
    let quality=this.get('quality');
    for (let i =0;i<events.length;i++) {
      let t=events[i];
      if (t.get('use'))
      {
        let filmLength = t.get('gameRef.fullFilmLength') || this.get('filmLength');
        let clipStart = t.get('clipStart');
        let fullFilm = t.get('gameRef.fullFilm');
        let playerNumber = yield t.get('playerObject.number');
        let playerName = yield t.get('playerObject.name');
        let description = t.get('description');
        let caption = `${playerNumber?'#'+playerNumber+' ':''}${playerName?playerName+'-':''}${description}`;
        
        if (clipStart && fullFilm && filmLength)
        {
          let clipStop = t.get('clipStop');
          if (!clipStop)
          {
            let clipMidpoint=clipStart;
            clipStart = (clipMidpoint - halfDefaultClipLength) > 0 ? clipMidpoint - halfDefaultClipLength : 0;
            clipStop = (clipMidpoint +  halfDefaultClipLength) <= filmLength ? clipMidpoint + halfDefaultClipLength : filmLength;
          }
          allSegments.push({
            clipStart,
            clipStop,
            caption,
            source: fullFilm
          });
        }
      }
    }
    allSegments.sort((current, next) => {
      if ((typeof current !== 'object') || (typeof next !== 'object')) {
        return -1;
      }
      if (current.clipStart < next.clipStart) {
        return -1;
      }
      if (current.clipStart > next.clipStart) {
        return 1;
      }
      return 0;
    });
    let segments = [];
    allSegments.reduce((accumulator, currentValue, currentIndex, incoming) =>{
      if (accumulator.length)
      {
        let prev=accumulator[accumulator.length-1];
        if (prev.clipStop > currentValue.clipStart)
        {
          prev.clipStop=currentValue.clipStart;
        }
      }
      accumulator.push(currentValue);
      
      return accumulator;
    }, segments);
    if (api && segments && segments.length)
    {
      var result = yield api.clipFilm({
        account: this.get('session.accountId'),
        teamSeason: teamSeasonId,
        playerSeason: playerSeasonId,
        segments,
        season,
        reelName,
        description: reelDesc,
        quality
      });
      this.transitionToRoute('teamseason.reels');
    }
  }),

  saveGame: task(function* (game) {
    yield game.save();
  }),

  saveEvent: task(function* (evt) {
    yield evt.save();
  })
});
