import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { A } from '@ember/array';
export default Route.extend({
  session: service(),
  model(params) {
    return this.get('loadData').perform(params);
  },
  loadData: task(function* (params) {
    let account = yield this.get('session.account');
    let slug = undefined;
    let slugId = params['slug_id'];
    if (!isEmpty(slugId)) {
        slug = yield this.store.find('slug', slugId);
    }
    
    var plan= slug ? (yield slug.get('plan')):null;
    if (!plan)
    {
      plan=yield account.get('plan');
    }
    
    var season, teamSeason, reference;
    let all = A();
    if (slug)
    {
      season = yield slug.get('season');
      teamSeason = yield slug.get('teamSeason');
    }
    if (plan==='league')
    {
      let allSeasons = yield this.store.query('bask-season', {
        orderBy: 'account',
        equalTo: account.id
      });
      reference = season ? (yield this.store.find('bask-season', season)) : null;
      allSeasons.forEach((s) => {
        all.pushObject({
          id: s.get('id'),
          name: s.get('name')
        });
      });
    }
    else if (plan==="team")
    {
      let allTeamSeasons = yield this.store.query('bask-team-season', {
        orderBy: 'season',
        equalTo: yield account.get('activeSeason')
      });
      
      reference=teamSeason ? (yield this.store.find('bask-team-season',teamSeason)) : null;
      let length=yield allTeamSeasons.get('length');
      for (var i=0;i<length ;i++)
      {
        let s=yield allTeamSeasons.objectAt(i);
        let team = yield s.get('team');
        all.pushObject({
          id: s.get('id'),
          name: team.get('name')
        });
      }
      
    }
    
    return {
      season, 
      teamSeason,
      all,
      plan,
      slug,
      reference: reference.id
    };
  }),
  resetController(controller, isExiting, transition) {
    if (isExiting) {
      controller.set('publicUrl', '');
      controller.set('displayName', '');
      controller.set('teamSeason', '');
      controller.set('season', '');
    }
  },
  setupController(controller, model) {
    
    if (model.plan==='team')
    {
      controller.set('modelType', 'team');
    }
    else if (model.plan==='league')
    {
      controller.set('modelType', 'season');
    }
    controller.set('plan', model.plan);
    controller.set('publicUrl', model.slug.get('publicUrl'));
    controller.set('displayName', model.slug.get('displayName'));
    controller.set('display', model.all);
    this._super(controller, model);
  },
  actions: {
    cancel() {
      this.transitionTo('account');
    }
  }
});
