import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
export default Route.extend({
  session: service(),
  model(params) {
    let teamSeasonId = params['bask-team-season_id'];
    if (isPresent(teamSeasonId)) {
      return this.get('loadModel').perform(teamSeasonId);
    }
  },

  loadModel: task(function* (teamSeasonId) {
    let account = this.get('session.account');
    let teamSeason = yield this.store.find('bask-team-season', teamSeasonId);
    let season = yield teamSeason.get('season');
    return {
      account,
      season,
      teamSeason
    };
  }),
});
