import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
export default Controller.extend({
  actions: {
    save() {
      this.get('saveTask').perform();
    },
  },
  reference: computed('model.reference', {
    get(key) { //eslint-disable-line no-unused-vars
      return this.get('model.reference');
    },
    set(key, val) {
      let display = this.get('model.all');
      let item=display.findBy('id', val);
      if (item)
      {
        this.set('model.reference', item.id);
        this.set('referenceName', item.name);
      }
      return val;
    }
  }),
  canSave: computed('saveTask.isRunning', 'reference',  function() {
    return !this.get('saveTask.isRunning') && this.get('reference');
  }),
  saveTask: task(function* () {
    var slug=this.get('model.slug');
    var reference=this.get('reference');
    var referenceName = this.get('referenceName');
    if (slug&&reference)
    {
      var plan=slug.get('plan');
      if (plan==='team')
      {
        slug.set('teamSeason',reference);
        slug.set('season',this.get('model.season'));
        slug.set('reference', referenceName);
      }
      else if (plan==='league')
      {
        slug.set('season',reference);
        slug.set('reference', referenceName);
      }
      yield slug.save();
      this.transitionToRoute('public');
      
    }
    else
    {
      this.transitionToRoute('public');
    }
  })
});
