import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
export default Route.extend({
  lazyload: service(),
  beforeModel() {
    return this.get('lazyload').loadGrid();
  },
  model(params) {
    if (isPresent(params.teamSeasonId)) {
      return this.get('loadData').perform(params.teamSeasonId);
    } else {
      return new Error('No Team Season available');
    }
  },
  loadData: task(function* (teamSeasonId) {
    let teamSeason = yield this.store.findRecord('bask-team-season', teamSeasonId);
    let team = yield teamSeason.get('team');
    let season = yield teamSeason.get('season');
    return {
      teamSeason,
      team,
      season
    };
  })
});
