
import { computed } from '@ember/object';
import { alias, filter, filterBy } from '@ember/object/computed';
import  Controller  from '@ember/controller';
import { bind } from '@ember/runloop';
import EmObject from '@ember/object';
import { inject as service } from '@ember/service';
export default Controller.extend({
  rate: 1,
  courtName: 'USHS',
  courtNumber: 0,
  isLive: alias('model.game.isLive'),
  isValid: alias('model.game.isValid'),
  session: service(),
  courtType: alias('model.game.courtType'),
  fieldGoals: filterBy('model.eventLedger.events', 'type0', 0),
  shots: filter('fieldGoals', (shot) => shot.get('type1') > 1),
  events: computed('model.eventLedger.events', function(){
    return this.get('model.eventLedger.events').toArray().reverse();
  }),
  teamMouseCallback: computed(function(entry){ //eslint-disable-line no-unused-vars
    return bind(this,function(entry){ //eslint-disable-line no-unused-vars
      // var homeTeamId=this.get('model.homeTeam.id');
      // if (entry.get('id')===homeTeamId)
      // {
      //   this.set('awayTeamEntry.selected', false); 
      // }
      // else{
      //   this.set('homeTeamEntry.selected', false); 
      // }
    });
  }),
  playerMouseCallback: computed(function(player){ //eslint-disable-line no-unused-vars
    return bind(this,function(player){ //eslint-disable-line no-unused-vars
      // var playerId=player.get('id');
      // var home=player.get('isHome');
      // var players=home?this.get('homePlayers').rejectBy('id', playerId):this.get('awayPlayers').rejectBy('id', playerId);
      // players.forEach((p)=>{
      //   p.set('selected',false);
      // });
    });
  }),
  playerSubCallback: computed(function(player, isInGame, isHome){ //eslint-disable-line no-unused-vars
    return bind(this,function(player, isInGame, isHome){ //eslint-disable-line no-unused-vars
    });
  }),
  homeTeamEntry: computed('model.homeTeam', function()
  {
    return EmObject.create({
            selected:false,
            team:this.get('model.homeTeam'),
            homeColor: `#${this.get('model.homeTeam.homeColor')}`,
            id: this.get('model.homeTeam.id')
          });
  }),
  awayTeamEntry: computed('model.awayTeam', function()
  {
    return EmObject.create({
            selected:false,
            team:this.get('model.awayTeam'),
            awayColor: `#${this.get('model.awayTeam.awayColor')}`,
            id:this.get('model.awayTeam.id')
          });
  }),
  homePlayers: computed('model.{homePlayers.[],playerGames.[]}', function() {
    let p = this.get('model.homePlayers');
    let pg = this.get('model.playerGames');
    return p.forEach((item) => {
      var playerGame=pg.findBy('playerSeason', item.id);
      if (playerGame)
        {
          item.set('isHome',true);
          item.set('playerGame', playerGame);
          item.set('selected',false);
        }
    });
  }),
  inGameHomePlayers: filterBy('homePlayers','isInGame',true),
  benchHomePlayers: filterBy('homePlayers','isInGame',false),
  awayPlayers: computed('model.{awayPlayers.[],playerGames.[]}', function() {
    let p = this.get('model.awayPlayers');
    let pg = this.get('model.playerGames');
    return p.forEach((item) => {
      var playerGame=pg.findBy('playerSeason', item.id);
      if (playerGame)
        {
          item.set('isHome',false);
          item.set('selected',false);
          item.set('playerGame', playerGame);
        }
    });
  }),
  inGameAwayPlayers: filterBy('awayPlayers','isInGame',true),
  benchAwayPlayers: filterBy('awayPlayers','isInGame',false),
  actions:
  {
    navigate()
    {
      this.transitionToRoute('index');
    }
  }
});
