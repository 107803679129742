import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
export default Route.extend({
  session: service(),
  model(params) {
    let baskTeamId = params['bask-team_id'];
    if (isPresent(baskTeamId)) {
      return this.get('loadData').perform(baskTeamId);
    }
  },
  loadData: task(function* (baskTeamId) {
    let team = yield this.store.find('bask-team', baskTeamId);
    let teamSeasons = yield team.get('teamSeasons');
    let players = yield team.get('players');
    return {
      team,
      teamSeasons,
      players
    };
  })
});
