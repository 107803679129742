
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import ENV from '../../config/environment';
/* global moment*/
/* global StripeCheckout */
export default Controller.extend( {
  init(){
    this._super(...arguments);
    this.periodValidators = {
      positive:
      {
        message: 'Value must be >= 0',
        validator(value, item) { //eslint-disable-line no-unused-vars
          if (value) {
            return value >= 0;
          }
          return true;
        }
      }
    };
  },

  firebaseApp: service(),
  intl: service(),
  session: service(),
  metrics: service(),
  queryParams: ['game', 'destination'],
  overtimes: 0,

  usePrepaid: computed('plans', 'selected', function () {
    let plans = this.get('plans');
    if (plans)
    {
      let plan=plans[this.get('selected')];
      if (plan)
      {
        return plan.prepaid;
      }
    }
    return false;
  }),

  hoursToComplete: computed('plans', 'selected', function () {
    let plans = this.get('plans');
    if (plans)
    {
      let plan=plans[this.get('selected')];
      if (plan)
      {
        return plan.hoursToComplete;
      }
    }
    return 36;
  }),

  statsToInclude: computed('plans', 'selected', function () {
    let plans = this.get('plans');
    if (plans)
    {
      let plan=plans[this.get('selected')];
      if (plan)
      {
        return plan.statsToInclude;
      }
    }
    return 36;
  }),

  breakdown: false,
  selected: 1,

  plans: computed('model.{plans,prepaid}', function () {
    let intl = this.get('intl');
    let prepaid = this.get('model.prepaid');
    let plans = this.get('model.plans').filterBy('type','breakdown').sortBy('monthPrice');
    let _plans = [];
    let index=0;
    if (prepaid)
    {
      prepaid.forEach((p) => {
        let plan = plans.findBy('id', p.get('plan'));
        if (plan)
        {
          _plans.push({
            prepaid: true,
            index,
            id: plan.get('id'),
            statsToInclude: plan.get('statsToInclude'),
            hoursToComplete: plan.get('hoursToComplete'),
            name: plan.get('name'),
            description: plan.get('description'),
            available: `${intl.t('common.prepaid')} (${p.get('available')} ${intl.t('common.available')})`
          });
          index++;
        }
      });  
    }
    plans.forEach((p) => {
      _plans.push({
        prepaid: false,
        id: p.get('id'),
        index,
        hoursToComplete: p.get('hoursToComplete'),
        name: p.get('name'),
        description: p.get('description'),
        price: p.get('price')
      });
      index++;
    });
    return _plans;
  }),

  canSave: computed('_save.isRunning', function() {
    return !this.get('_save.isRunning');
  }),

  actions: {
    save() {
      this.get('_save').perform();
    },
    cancel() {
      this.get('metrics').trackEvent('GoogleAnalytics', {
        category: 'breakdown',
        action: 'submit cancelled'
      });
      let destination = this.get('destination');
      let game  = this.get('model.game');
      if (destination){ 
        this.transitionToRoute(destination);
      }
      else
      {
        this.transitionToRoute('game', game);
      }
    },
    addOT(){
      if (this.overtimes<10) {
        this.incrementProperty('overtimes',1);
      }
    },
    removeOT() {
      if (this.get('overtimes')>0)
      {
        this.decrementProperty('overtimes',1);
      }
    },
    updateSelected(index, txt, value) {
      this.set('selected', index);
    }
  },

  _save: task(function* () {
    let destination = this.get('destination');
    let game  = this.get('model.game');
    let homeTeam=yield game.get('homeTeam.name');
    let awayTeam=yield game.get('awayTeam.name');
    let location=yield game.get('location');
    if (location)
    {
      location=` at ${location}`;
    }
    else
    {
      location = '';
    }
    let dt = moment(yield game.get('date')).format('MM-DD-YYYY');
    let gameName=`${dt}  ${homeTeam} vs ${awayTeam}${location}`;
    let user = this.get('model.user');
    let periodRows=this.get('periodRows');
    let periodCount=this.get('periodCount');
    let overtimes=this.get('overtimes');
    let periods = [];
    let intl = this.get('intl');
    let homePeriodScores =[];
    let visitorPeriodScores =[];
    for (var i=0;i<periodCount;i++) {
      periods.push(intl.t(`periods.regulation._${i}`).toString());
      homePeriodScores.push(periodRows[0][`r${i}`]);
      visitorPeriodScores.push(periodRows[1][`r${i}`]);
    }
    for (i=0;i<overtimes;i++) {
      periods.push(intl.t(`periods.ot._${i}`).toString());
      homePeriodScores.push(periodRows[0][`ot${i}`]);
      visitorPeriodScores.push(periodRows[1][`ot${i}`]);
    }
    let auth=this.get('firebaseApp').auth();
    let authToken = yield auth.currentUser.getToken();
    let plans=this.get('plans');
    let breakdownPlan = plans[this.get('selected')];
    let _this=this;
    let api = this.get('st-api');
    if (breakdownPlan && breakdownPlan.price)
    {
      let _amount=breakdownPlan.price*100;   
      var handler = StripeCheckout.configure({
        key: ENV.stripe.key,
        image: '/../icon.png',
        locale: 'auto',
        token: function (token, other) { //eslint-disable-line no-unused-vars
          let body = {
            'game': game.id,
            'gameDescription': gameName,
            'account': game.get('account'),
            'submittingAccount': game.get('account'),
            'submitter': user.get('id'),
            'submitterName': user.get('name'),
            'submitted': moment(),
            'receiptEmail': user.get('email'),
            'status': 'Uploaded',
            'notes': _this.get('notes'),
            'home': _this.get('model.homeTeam.name'),
            'visitor': _this.get('model.awayTeam.name'),
            'homeFinal': periodRows[0]['final'],
            'visitorFinal': periodRows[1]['final'],
            'hoursToComplete': _this.get('hoursToComplete'),
            'statsToInclude': _this.get('statsToInclude'),
            periods,
            homePeriodScores,
            visitorPeriodScores,
            plan: breakdownPlan.id,
            stripeToken: token.id
          };
          
          api.charge(body, 'breakdown', authToken).then(function(){
            let params = {
              breakdown: body.game,
              game: body.game,
              sourceAccount: body.account,
              action: 'create'
            };
            api.breakdown(params).then(function(){
              if (destination){ 
                _this.transitionToRoute(destination);
              }
              else
              {
                _this.transitionToRoute('game', game);
              }
            });
          });
          this.get('metrics').trackEvent('GoogleAnalytics', {
            category: 'breakdown',
            action: 'submitted',
            label: `Purchased Plan ${breakdownPlan.id}`
          });
        }
      });
      handler.open({
        name: intl.t('common.stLive').toString(),
        description: breakdownPlan.get('description'),
        amount: _amount,
        shippingAddress: false,
        billingAddress: true,
        zipCode: true
      });
    }
    else //charge prepaid
    {
      let body = {
        'game': game.id,
        'gameDescription': gameName,
        'account': game.get('account'),
        'submittingAccount': game.get('account'),
        'submitter': user.get('id'),
        'submitterName': user.get('name'),
        'submitted': moment(),
        'receiptEmail': user.get('email'),
        'status': 'Uploaded',
        'notes': _this.get('notes'),
        'home': _this.get('model.homeTeam.name'),
        'visitor': _this.get('model.awayTeam.name'),
        'homeFinal': periodRows[0]['final'],
        'visitorFinal': periodRows[1]['final'],
        'hoursToComplete': _this.get('hoursToComplete'),
        'statsToInclude': _this.get('statsToInclude'),
        periods,
        homePeriodScores,
        visitorPeriodScores,
        plan: breakdownPlan.id
      };
      api.charge(body, 'useprepaid', authToken).then(function(){
        let params = {
          breakdown: body.game,
          game: body.game,
          sourceAccount: body.account,
          action: 'create'
        };
        api.breakdown(params).then(function(){
          if (destination){ 
            _this.transitionToRoute(destination);
          }
          else
          {
            _this.transitionToRoute('game', game);
          }
        });
        this.get('metrics').trackEvent('GoogleAnalytics', {
          category: 'breakdown',
          action: 'submitted',
          label: `Prepaid Plan ${breakdownPlan.id}`
        });
      });
    }
  }),

  periodCount: computed.alias('model.game.periodCount'),

  periodRows: computed('periodCount','overtimes', function() { 
    let rows=[];
    let periodCount = this.get('periodCount');
    let overtimes=this.get('overtimes');
    let home = this.get('model.homeTeam.name');
    let away = this.get('model.awayTeam.name');
    let rowHome = {name: home};
    let rowAway = {name: away};
    for (var i=0;i<periodCount;i++)
    {
      rowAway[`r${i}`]=rowHome[`r${i}`]=0;
    }
    for (i=0;i<overtimes;i++)
    {
      rowAway[`ot${i}`]=rowHome[`ot${i}`]=0;
    }
    rowHome['final']=rowAway['final']=0;
    rows.push(rowHome);
    rows.push(rowAway);
    return rows; 
  }),

  fields: computed('periodCount','overtimes',  function() { 
    let intl = this.get('intl'); 
    let periodCount = this.get('periodCount');
    let overtimes=this.get('overtimes');
    let fields =[];
    fields.push({ name: 'name', title: intl.t(`models.team`).toString(), type: 'text', width: 120, readOnly: true });
    for (var i=0;i<periodCount;i++)
    {
      fields.push({ 
          name: `r${i}`,
          title: intl.t(`periods.regulation._${i}`).toString(), 
          type: 'number', 
          width: 45, 
          validate: 'positive'
        });  
    }
    for (i=0;i<overtimes;i++)
    {
      fields.push({
        name: `ot${i}`,
        title: intl.t(`periods.ot._${i}`).toString(), 
        type: 'number', 
        width: 45, 
        validate: 'positive'
      });
    }
    fields.push({ name: 'final', title: intl.t('common.final').toString(), type: 'number', width: 45, readOnly: true });
    fields.push({ type: 'control', deleteButton: false });
    return fields;
  }),

  config: computed('fields', function () {
    let fields = this.get('fields');
    let periodCount = this.get('periodCount');
      let overtimes=this.get('overtimes');
    return {
      width: '100%',
      inserting: false,
      editing: true,
      sorting: false,
      paging: false,
      onItemUpdated(p) {
        
        let originalFinal = p.item['final'];
        let final=0;
        for (var i=0;i<periodCount;i++)
        {
          final+=p.item[`r${i}`];
        }
        for (i=0;i<overtimes;i++)
        {
          final+=p.item[`ot${i}`];
        }
        p.item['final']=final;
        if (final!=originalFinal)
        {
          p.grid.updateItem(p.item);
        }
      },
      fields
    };
  })
});
