import Ember from 'ember';

const { Controller, RSVP: { hash } } = Ember;
export default Controller.extend({
  itemActions: [ 'Edit', 'Remove', 'Hide'],
  selectedTeam: null,
  selectionCount: 0,
  actions: {
    updateSelected(component, id, value) {
      this.set('selectedTeam', id);
      this.store.find('bask-team', id).then((team) => {
        hash({
          players: team.get('players')
        }).then((hash) => {
            this.set('players', hash.players);
          });
      });
    },
    selectPlayer(player) {
      player.toggleProperty('isSelected');
      let isSelected = player.get('isSelected');
      let selectionCount = this.get('selectionCount');
      if (isSelected) {
        selectionCount = selectionCount + 1;
      } else {
        selectionCount = selectionCount - 1;
        if (selectionCount < 0) {
          selectionCount = 0;
        }
      }
      this.set('selectionCount', selectionCount);
    },
    onImport() {
      let teamseason = this.get('model.teamseason');
      let team = this.get('model.team');
      let players = this.get('players').forEach(function(player) {
        if (player.isSelected) {
          teamseason.get('players').addObject(player);
          player.get('teamSeasons').addObject(teamseason);
          player.get('teams').addObject(team);
          team.get('players').addObject(player);
          teamseason.save();
          team.save();
          player.save();
        }
        player.set('isSelected', false);
      }, this);
      this.transitionToRoute('teamseason', teamseason);
    }
  }
});
