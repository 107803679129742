import Controller from '@ember/controller';
import $ from 'jquery';
import { inject as service } from '@ember/service';
export default Controller.extend({
  session: service(),
  actions: {
    delete() {
      let team = this.get('deletingTeam');
      let promise = team.destroyRecord();
      this.set('deletionPromise', promise);
      return promise;
    },
    confirmDelete(team) {
      $('.ui.modal').modal('show');
      this.set('deletingTeam', team);
    },
    denyModal() {

    },
    toggleButtons(team) {
      team.toggleProperty('showingButtons');
    }
  }

});
