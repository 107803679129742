import divisionData from '../../division-data';
import { computed } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';

export default Route.extend({
  session: service(),
  model(params) {
    return this.store.find('bask-season', params.seasonId).then((season) => {
      return hash({
        teamSeasons: season.get('teamSeasons'),
        season,
        account: this.get('session.account')
      });
    });
  },
  setupController(controller, model) {
    this._super(controller, model);
    controller.set('isSameTeam', computed('homeTeam.id', 'awayTeam.id', function() {
      let h = this.get('homeTeam.id');
      let a = this.get('awayTeam.id');
      return !isEmpty(a) && !isEmpty(h) && a === h;
    }));
    controller.set('gameData', divisionData.gameData);
    let [ct] = divisionData.gameData;
    controller.set('courtType', ct);
    controller.setProperties({
      periodCount: ct.periodCount,
      periodMinutes: ct.periodMinutes,
      overtimeMinutes: ct.overtimeMinutes,
      to20sec: ct.to20sec,
      to30sec: ct.to30sec,
      to60sec: ct.to60sec,
      bonus: ct.bonus,
      doubleBonus: ct.doubleBonus
    });
    controller.set('hasEnoughTeams', computed.gte('model.teamSeasons.length', 2));
  },
  resetController(controller, isExiting, transition) {
    if (isExiting) {
      controller.set('homeTeam', '');
      controller.set('awayTeam', '');
      controller.set('date', '');
      controller.set('courtType', '');
      controller.set('location', '');
      controller.set('description', '');
      controller.set('tags', '');
    }
  }
});
