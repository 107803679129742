import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  queryParams: ['inviteId'],
  session: service(),
  fastboot: service(),
  firebaseApp: service(),
  actions: {
    signUp(email, password, name) {
      var _session=this.get('session');
      this.get('firebaseApp').auth().createUserWithEmailAndPassword(email, password).then((result) => {
        this.set('errorMessage', null);
        _session.signup(name,this.get('inviteId'),email,"password",result.uid).then(() => {
          _session.authenticateUser( {
            provider: 'password',
            email,
            password
          }).catch((err) => console.error(err));
        });
      }).catch((err) => {
        this.set('errorMessage', err.message);
      });
    }
  }
});
