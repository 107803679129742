import Ember from 'ember';

const { Route, inject: { service } } = Ember;
export default Route.extend({
  session: service(),

  model() {
    return this.get('session.account');
  },
  setupController(controller, model) {
    controller.set('start', moment().format('MM/DD/YYYY') || null);
    this._super(controller, model);
  },
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('name', '');
      controller.set('start', '');
      controller.set('seasonType', '');
      controller.set('tags', '');
    }
  },
  actions: {
    cancel() {
      this.transitionTo('seasons');
    }
  }
});
