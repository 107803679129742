import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
export default Controller.extend({
  session: service(),
  actions: {
    refreshStats(){
      let model=this.get('model');
      let params = {
          player: model.playerId,
          account: model.account,
          reportType: 'all',
          report: 'playerCareer'
      };
      let api = this.get('st-api');
      if (api) {
        return api.finalize(params).then((response) => { //eslint-disable-line no-unused-vars
          //console.log(response);
          //this.transitionToRoute('index');
        });
      }
    }
  }
});
