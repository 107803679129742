import Ember from 'ember';

const { Route, inject: { service }, isEmpty, RSVP: { hash } } = Ember;
export default Route.extend({
  session: service(),
  model(params) {
    if (!isEmpty(params['bask-team_id'])) {
      return this.store.find('bask-team', params['bask-team_id']).then((team) => {
        return hash({
            account: this.get('session.account'),
            team,
            players: team.get('players')

          }).then((hash) => {
          return this.store.query('bask-team', {
            orderBy: 'account',
            equalTo: hash.account.id
          }).then((hashbrown) => {
            hash.teams = hashbrown.toArray();
            return hash;
          });
        });
      });
    }
  },
  resetController(controller, isExiting, transition) {
    if (isExiting) {
      controller.set('selectionCount', null);
      controller.set('players', null);
      controller.set('selectedTeam', null);
    }
  }
});
