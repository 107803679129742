import { A } from '@ember/array';
import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
export default Route.extend({
  model(params) {
    let evt = params.event;
    let game = params.game;
    if (!isEmpty(evt) && !isEmpty(game)) {
      return this.get('loadData').perform(evt, game);
    }
  },

  loadData: task(function* (evtId, gameId) {
    let event = yield this.store.findRecord('bask-event', evtId, { adapterOptions: { 'gameId' : gameId } });
    let game = yield this.store.find('bask-game', gameId);
    let playerGames = yield this.store.query('bask-player-game', {
      orderBy: 'game',
      equalTo: gameId
    });
    let periodCount = yield game.get('periodCount');
    let periodCurrent = yield game.get('periodCurrent');
    let teamSeasons = A();
    let homeTeamSeason = yield game.get('homeTeam');
    let awayTeamSeason = yield game.get('awayTeam');
    let homeTeam = yield homeTeamSeason.get('team');
    let awayTeam = yield awayTeamSeason.get('team');
    let homePlayerSeasons = yield homeTeamSeason.get('playerSeasons');
    let awayPlayerSeasons = yield awayTeamSeason.get('playerSeasons');
    teamSeasons.pushObject({
      name: homeTeam.get('name'),
      id: homeTeamSeason.get('id')
    });
    teamSeasons.pushObject({
      name: awayTeam.get('name'),
      id: awayTeamSeason.get('id')
    });
    return {
      event,
      game,
      teamSeasons,
      homePlayerSeasons,
      awayPlayerSeasons,
      playerGames,
      homeTeamSeason: homeTeamSeason.get('id'),
      periodCount,
      periodCurrent
    };
  }),
  setupController(controller, model)
  {
    let scored = undefined;
    let evt = model.event;
    if (evt)
    {
      let type0=evt.get('type0');
      if (type0 === 0) {
        scored = evt.get('shotMade') ? evt.get('type1') : 0;
      }
      controller.set('oldPlayerSeason', evt.get('playerSeason'));
      controller.set('scored', scored);
    }
    this._super(controller, model);
  }
});
