import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
import cmMixin from '../../mixins/color-manipulation';  
export default Route.extend(cmMixin, {

  model() {
    let game = this.modelFor('game');
    return this.get('loadData').perform(game);
  },
  loadData: task(function* (game) {
    let eventLedger = yield this.store.find('game-event-ledger',game.id);
    let homeTeamSeason = yield game.get('homeTeam');
    let awayTeamSeason = yield game.get('awayTeam');
    let playerGames = yield this.store.query('bask-player-game', {
        orderBy: 'game',
        equalTo: game.id
      });
    let teamGames = yield this.store.query('bask-team-game', {
        orderBy: 'game',
        equalTo: game.id
      });
    let homeTeam = yield homeTeamSeason.get('team');
    let awayTeam = yield awayTeamSeason.get('team');
    let awayColor = '#' + awayTeam.get('awayColor');
    let homeColor = '#' + homeTeam.get('homeColor');
    let homeSecondColor = this.pSBC((homeColor === '#FFFFFF'?-.05:.25),homeColor);
    let awaySecondColor = this.pSBC((awayColor === '#FFFFFF'?-.05:.25),awayColor);
    document.body.style.setProperty('--home-primary-color', homeColor);
    document.body.style.setProperty('--away-primary-color', awayColor);
    document.body.style.setProperty('--home-secondary-color', homeSecondColor);
    document.body.style.setProperty('--away-secondary-color', awaySecondColor);
    document.body.style.setProperty('--home-text-color', this.getTextColor(homeColor));
    document.body.style.setProperty('--away-text-color', this.getTextColor(awayColor));

    return {
      game,
      homeTeam,
      awayTeam,
      timings: yield eventLedger.get('timings'),
      homeTeamGame: yield teamGames.findBy('id',game.get('homeTeamGame')),
      awayTeamGame: yield teamGames.findBy('id',game.get('awayTeamGame')),
      homePlayers: yield homeTeamSeason.get('playerSeasons'),
      awayPlayers: yield awayTeamSeason.get('playerSeasons'),
      awayTeamSeason,
      homeTeamSeason,
      playerGames,
      teamGames,
      eventLedger
    };
  }),
  actions: {
    willTransition(transition){
      // if the transition is not in the same route
      if (!transition.targetName.startsWith('game.')) {
        document.body.style.removeProperty('--home-primary-color');
        document.body.style.removeProperty('--away-primary-color');
        document.body.style.removeProperty('--home-secondary-color');
        document.body.style.removeProperty('--away-secondary-color');
        document.body.style.removeProperty('--home-text-color');
        document.body.style.removeProperty('--away-text-color');
      }
    }
  }
});
