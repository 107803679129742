import Controller from '@ember/controller';
import { all } from 'rsvp';
export default Controller.extend({
  queryParams: ['teamId', 'seasonId'],
  actions: {
    submit() {
      let add = this.get('add');
      add = add.map((a) => {
        let ts = this.store.createRecord('bask-team-season');
        if (this.get('isAddingToTeam')) {
          ts.set('team', this.get('model.primary'));
          ts.set('season', a);
        } else {
          ts.set('season', this.get('model.primary'));
          ts.set('team', a);
        }
        this.get('model.primary.teamSeasons').pushObject(ts);
        this.get('model.primary').save();
        a.get('teamSeasons').pushObject(ts);
        a.save();

        ts.set('account', this.get('model.account.id'));
        return ts.save();
      });
      return all(add).then(() => this.transitionToRoute(this.get('returnRoute'), this.get('model.primary.id')));
    }
  }

});
