
import { task } from 'ember-concurrency';
import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend(ApplicationRouteMixin, {
  session: service(),
  intl: service(),
  beforeModel(transition) {
    
    if (this.useForThisTarget(transition.targetName))
    {
      this.set('session.attemptedTransition', transition);
    }
    return this._loadCurrentSession(transition);
  },

  model() {
    return this.get('loadAccounts').perform();
  },

  sessionAuthenticated() {
    let self = this;
    this._loadCurrentSession().then(function() {
      let attemptedTransition = self.get('session.attemptedTransition');
      if (attemptedTransition)
      {
        attemptedTransition.retry();
        self.set('session.attemptedTransition', null);
      }
      else
      {
        self.transitionTo(self.get('routeAfterAuthentication'));
      }
      self.get('loadAccounts').perform().then((model) => {
        self.render('authed-nav', {
          outlet: 'nav',
          into: 'application',
          model
        });
      });
    });
  },

  renderTemplate(controller, model) {
    this._super(controller, model);
    let template = this.get('session.isAuthenticated') ? 'authed-nav' : 'unauthed-nav';
    this.render(template, {
      outlet: 'nav',
      into: 'application',
      controller,
      model
    });
  },

  sessionInvalidated() {
    this.render('unauthed-nav', {
      outlet: 'nav',
      into: 'application'
    });
    if (this.get('isLogin'))
    {
      this.transitionTo('login');
    }
    else
    {
      this._super(...arguments);
    }
  },
  useForThisTarget(targetName)
  {
    if (this._isLogin(targetName) || this._isSignup(targetName))
      return false;

    return true;
  },
  _isLogin(targetName)
  {
    let ret = (targetName==='login');
    this.set('isLogin', ret);
    return ret;
  },
  _isSignup(targetName)
  {
    let ret = (targetName==='signup');
    this.set('isSignup', ret);
    return ret;
  },
  _loadCurrentSession(transition) {
    let queryParams = transition? transition.queryParams:undefined;
    let session = this.get('session');
    if (session && !session.get('isAuthenticated'))
    {
      if (queryParams && queryParams.token)
      {
        let token = queryParams.token;
        return session.authenticateUser({
          token,
          provider: 'token'
        }).then(() =>{
          return session.loadCurrentSession(queryParams).catch((err) => { //eslint-disable-line no-unused-vars
            session.invalidate();
          });
        }).catch((err) => { //eslint-disable-line no-unused-vars
          session.invalidate();
        });
      }
    }
    return session.loadCurrentSession(queryParams).catch((err) => { //eslint-disable-line no-unused-vars
      session.invalidate();
    });
  },
  loadAccounts: task(function* () {
    let user = this.get('session.user');
    if (user) {
      let accounts = yield user.get('accounts');
      let adminAccounts = yield user.get('adminAccounts');
      return {
        user,
        adminAccounts,
        accounts
      };
    }
  }),

  actions: {
    changeActiveAccount(account) {
      this.get('session').setActiveAccount(account);
    },

    invalidate() {
      this.get('session').invalidate();
    }
  }
});
