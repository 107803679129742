import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
export default Route.extend({
  intl: service(),

  model() {
    let account = this.modelFor('account');
    if (account) {
      return this.get('loadData').perform(account);
    } else {
      throw new Error('Active account returned as null');
    }
  },

  loadData: task(function* (account) {
    let breakdowns = yield this.store.query('breakdown', { //eslint-disable-line
      orderBy: 'account',
      equalTo: account.id
    });
    let plans = yield this.store.query('plan', {});
    let prepaid = yield account.get('prepaidBreakdowns');
  
    return { breakdowns, prepaid, plans };
  })
});
