import axios from 'axios';

var apiGateway = apiGateway || {};
apiGateway.core = apiGateway.core || {};

apiGateway.core.apiGatewayClientFactory = {};
apiGateway.core.apiGatewayClientFactory.newClient = function(simpleHttpClientConfig, sigV4ClientConfig) {
  let apiGatewayClient = {};
  // Spin up 2 httpClients, one for simple requests, one for SigV4
  let sigV4Client = apiGateway.core.sigV4ClientFactory.newClient(sigV4ClientConfig);
  let simpleHttpClient = apiGateway.core.simpleHttpClientFactory.newClient(simpleHttpClientConfig);

  apiGatewayClient.makeRequest = function(request, authType, additionalParams, apiKey) {
    // Default the request to use the simple http client
    let clientToUse = simpleHttpClient;

    // Attach the apiKey to the headers request if one was provided
    if (apiKey !== undefined && apiKey !== '' && apiKey !== null) {
      request.headers['x-api-key'] = apiKey;
    }

    if (request.body === undefined || request.body === '' || request.body === null || Object.keys(request.body).length === 0) {
      request.body = undefined;
    }

    // If the user specified any additional headers or query params that may not have been modeled
    // merge them into the appropriate request properties
    request.headers = apiGateway.core.utils.mergeInto(request.headers, additionalParams.headers);
    request.queryParams = apiGateway.core.utils.mergeInto(request.queryParams, additionalParams.queryParams);

    // If an auth type was specified inject the appropriate auth client
    if (authType === 'AWS_IAM') {
      clientToUse = sigV4Client;
    }

    // Call the selected http client to make the request, returning a promise once the request is sent
    return clientToUse.makeRequest(request);
  };
  return apiGatewayClient;
};

apiGateway.core.simpleHttpClientFactory = {};
apiGateway.core.simpleHttpClientFactory.newClient = function(config) {
  function buildCanonicalQueryString(queryParams) {
    // Build a properly encoded query string from a QueryParam object
    if (Object.keys(queryParams).length < 1) {
      return '';
    }

    let canonicalQueryString = '';
    for (let property in queryParams) {
      if (queryParams.hasOwnProperty(property)) {
        canonicalQueryString += `${encodeURIComponent(property)}=${encodeURIComponent(queryParams[property])}&`;
      }
    }

    return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
  }

  let simpleHttpClient = {};
  simpleHttpClient.endpoint = apiGateway.core.utils.assertDefined(config.endpoint, 'endpoint');

  simpleHttpClient.makeRequest = function(request) {
    let verb = apiGateway.core.utils.assertDefined(request.verb, 'verb');
    let path = apiGateway.core.utils.assertDefined(request.path, 'path');
    let queryParams = apiGateway.core.utils.copy(request.queryParams);
    if (queryParams === undefined) {
      queryParams = {};
    }
    let headers = apiGateway.core.utils.copy(request.headers);
    if (headers === undefined) {
      headers = {};
    }

    // If the user has not specified an override for Content type the use default
    if (headers['Content-Type'] === undefined) {
      headers['Content-Type'] = config.defaultContentType;
    }

    // If the user has not specified an override for Accept type the use default
    if (headers.Accept === undefined) {
      headers.Accept = config.defaultAcceptType;
    }

    let body = apiGateway.core.utils.copy(request.body);
    if (body === undefined || verb === 'GET') { // override request body and set to empty when signing GET requests
      body = '';
    } else {
      body = JSON.stringify(body);
    }

    let url = config.endpoint + path;
    let queryString = buildCanonicalQueryString(queryParams);
    if (queryString != '') {
      url += `?${queryString}`;
    }
    let simpleHttpRequest = {
      method: verb,
      url,
      headers,
      data: body
    };
    return axios(simpleHttpRequest);
  };
  return simpleHttpClient;
};

apiGateway.core.sigV4ClientFactory = {};
apiGateway.core.sigV4ClientFactory.newClient = function(config) {
  let AWS_SHA_256 = 'AWS4-HMAC-SHA256';
  let AWS4_REQUEST = 'aws4_request';
  let AWS4 = 'AWS4';
  let DATE_FORMAT = 'YYYYMMDD';
  let TIME_FORMAT = 'HHmmss';
  let X_AMZ_DATE = 'x-amz-date';
  let X_AMZ_SECURITY_TOKEN = 'x-amz-security-token';
  let HOST = 'host';
  let AUTHORIZATION = 'Authorization';

  function hash(value) {
    return CryptoJS.SHA256(value);
  }

  function hexEncode(value) {
    return value.toString(CryptoJS.enc.Hex);
  }

  function hmac(secret, value) {
    return CryptoJS.HmacSHA256(value, secret, { asBytes: true });
  }

  function buildCanonicalRequest(method, path, queryParams, headers, payload) {
    return `${method}\n${
      buildCanonicalUri(path)}\n${
      buildCanonicalQueryString(queryParams)}\n${
      buildCanonicalHeaders(headers)}\n${
      buildCanonicalSignedHeaders(headers)}\n${
      hexEncode(hash(payload))}`;
  }

  function hashCanonicalRequest(request) {
    return hexEncode(hash(request));
  }

  function buildCanonicalUri(uri) {
    return encodeURI(uri);
  }

  function buildCanonicalQueryString(queryParams) {
    if (Object.keys(queryParams).length < 1) {
      return '';
    }

    let sortedQueryParams = [];
    for (let property in queryParams) {
      if (queryParams.hasOwnProperty(property)) {
        sortedQueryParams.push(property);
      }
    }
    sortedQueryParams.sort();

    let canonicalQueryString = '';
    for (let i = 0; i < sortedQueryParams.length; i++) {
      canonicalQueryString += `${sortedQueryParams[i]}=${encodeURIComponent(queryParams[sortedQueryParams[i]])}&`;
    }
    return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
  }

  function buildCanonicalHeaders(headers) {
    let canonicalHeaders = '';
    let sortedKeys = [];
    for (let property in headers) {
      if (headers.hasOwnProperty(property)) {
        sortedKeys.push(property);
      }
    }
    sortedKeys.sort();

    for (let i = 0; i < sortedKeys.length; i++) {
      canonicalHeaders += `${sortedKeys[i].toLowerCase()}:${headers[sortedKeys[i]]}\n`;
    }
    return canonicalHeaders;
  }

  function buildCanonicalSignedHeaders(headers) {
    let sortedKeys = [];
    for (let property in headers) {
      if (headers.hasOwnProperty(property)) {
        sortedKeys.push(property.toLowerCase());
      }
    }
    sortedKeys.sort();

    return sortedKeys.join(';');
  }

  function buildStringToSign(date, credentialScope, hashedCanonicalRequest) {
    return `${AWS_SHA_256}\n${
      buildXAmzDate(date)}\n${
      credentialScope}\n${
      hashedCanonicalRequest}`;
  }

  function buildCredentialScope(date, region, service) {
    return `${date.format(DATE_FORMAT)}/${region}/${service}/${AWS4_REQUEST}`;
  }

  function calculateSigningKey(secretKey, date, region, service) {
    return hmac(hmac(hmac(hmac(AWS4 + secretKey, date.format(DATE_FORMAT)), region), service), AWS4_REQUEST);
  }

  function calculateSignature(key, stringToSign) {
    return hexEncode(hmac(key, stringToSign));
  }

  function buildXAmzDate(date) {
    return `${date.format(DATE_FORMAT)}T${date.format(TIME_FORMAT)}Z`;
  }

  function buildAuthorizationHeader(accessKey, credentialScope, headers, signature) {
    return `${AWS_SHA_256} Credential=${accessKey}/${credentialScope}, SignedHeaders=${buildCanonicalSignedHeaders(headers)}, Signature=${signature}`;
  }

  let awsSigV4Client = {};
  if (config.accessKey === undefined || config.secretKey === undefined) {
    return awsSigV4Client;
  }
  awsSigV4Client.accessKey = apiGateway.core.utils.assertDefined(config.accessKey, 'accessKey');
  awsSigV4Client.secretKey = apiGateway.core.utils.assertDefined(config.secretKey, 'secretKey');
  awsSigV4Client.sessionToken = config.sessionToken;
  awsSigV4Client.serviceName = apiGateway.core.utils.assertDefined(config.serviceName, 'serviceName');
  awsSigV4Client.region = apiGateway.core.utils.assertDefined(config.region, 'region');
  awsSigV4Client.endpoint = apiGateway.core.utils.assertDefined(config.endpoint, 'endpoint');

  awsSigV4Client.makeRequest = function(request) {
    let verb = apiGateway.core.utils.assertDefined(request.verb, 'verb');
    let path = apiGateway.core.utils.assertDefined(request.path, 'path');
    let queryParams = apiGateway.core.utils.copy(request.queryParams);
    if (queryParams === undefined) {
      queryParams = {};
    }
    let headers = apiGateway.core.utils.copy(request.headers);
    if (headers === undefined) {
      headers = {};
    }

    // If the user has not specified an override for Content type the use default
    if (headers['Content-Type'] === undefined) {
      headers['Content-Type'] = config.defaultContentType;
    }

    // If the user has not specified an override for Accept type the use default
    if (headers.Accept === undefined) {
      headers.Accept = config.defaultAcceptType;
    }

    let body = apiGateway.core.utils.copy(request.body);
    if (body === undefined || verb === 'GET') { // override request body and set to empty when signing GET requests
      body = '';
    } else {
      body = JSON.stringify(body);
    }

    // If there is no body remove the content-type header so it is not included in SigV4 calculation
    if (body === '' || body === undefined || body === null) {
      delete headers['Content-Type'];
    }

    let date = moment.utc();
    headers[X_AMZ_DATE] = buildXAmzDate(date);
    let parser = document.createElement('a');
    parser.href = awsSigV4Client.endpoint;
    headers[HOST] = parser.host;

    let canonicalRequest = buildCanonicalRequest(verb, path, queryParams, headers, body);
    let hashedCanonicalRequest = hashCanonicalRequest(canonicalRequest);
    let credentialScope = buildCredentialScope(date, awsSigV4Client.region, awsSigV4Client.serviceName);
    let stringToSign = buildStringToSign(date, credentialScope, hashedCanonicalRequest);
    let signingKey = calculateSigningKey(awsSigV4Client.secretKey, date, awsSigV4Client.region, awsSigV4Client.serviceName);
    let signature = calculateSignature(signingKey, stringToSign);
    headers[AUTHORIZATION] = buildAuthorizationHeader(awsSigV4Client.accessKey, credentialScope, headers, signature);
    if (awsSigV4Client.sessionToken !== undefined && awsSigV4Client.sessionToken !== '') {
      headers[X_AMZ_SECURITY_TOKEN] = awsSigV4Client.sessionToken;
    }
    delete headers[HOST];

    let url = config.endpoint + path;
    let queryString = buildCanonicalQueryString(queryParams);
    if (queryString != '') {
      url += `?${queryString}`;
    }

    // Need to re-attach Content-Type if it is not specified at this point
    if (headers['Content-Type'] === undefined) {
      headers['Content-Type'] = config.defaultContentType;
    }

    let signedRequest = {
      method: verb,
      url,
      headers,
      data: body
    };
    return axios(signedRequest);
  };

  return awsSigV4Client;
};

apiGateway.core.utils = {
  assertDefined(object, name) {
    if (object === undefined) {
      throw `${name  } must be defined`;
    } else {
      return object;
    }
  },
  assertParametersDefined(params, keys, ignore) {
    if (keys === undefined) {
      return;
    }
    if (keys.length > 0 && params === undefined) {
      params = {};
    }
    for (let i = 0; i < keys.length; i++) {
      if (!apiGateway.core.utils.contains(ignore, keys[i])) {
        apiGateway.core.utils.assertDefined(params[keys[i]], keys[i]);
      }
    }
  },
  parseParametersToObject(params, keys) {
    if (params === undefined) {
      return {};
    }
    let object = {};
    for (let i = 0; i < keys.length; i++) {
      object[keys[i]] = params[keys[i]];
    }
    return object;
  },
  contains(a, obj) {
    if (a === undefined) {
      return false;
    }
    let i = a.length;
    while (i--) {
      if (a[i] === obj) {
        return true;
      }
    }
    return false;
  },
  copy(obj) {
    if (null == obj || 'object' != typeof obj) {
      return obj;
    }
    let copy = obj.constructor();
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = obj[attr];
      }
    }
    return copy;
  },
  mergeInto(baseObj, additionalProps) {
    if (null == baseObj || 'object' != typeof baseObj) {
      return baseObj;
    }
    let merged = baseObj.constructor();
    for (var attr in baseObj) {
      if (baseObj.hasOwnProperty(attr)) {
        merged[attr] = baseObj[attr];
      }
    }
    if (null == additionalProps || 'object' != typeof additionalProps) {
      return baseObj;
    }
    for (attr in additionalProps) {
      if (additionalProps.hasOwnProperty(attr)) {
        merged[attr] = additionalProps[attr];
      }
    }
    return merged;
  }
};

export default apiGateway;
