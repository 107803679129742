import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';

export default Route.extend({
  model() {
    return this.get('loadData').perform();
  },
  loadData: task(function* () {
    let plans = yield this.store.query('plan', {});
    return {
      account: yield this.modelFor('account'),
      plans
    }
  }),
  
  setupController(controller, model) {
    // let plans = model.plans.toArray();
    // plans.findBy('id', model.account.get('plan')).isActive = true;
    // controller.set('plans', plans);
    this._super(controller, model);
  }
});
