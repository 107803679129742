import divisionData from '../../division-data';
import { isNone, isEmpty } from '@ember/utils';
import Route from '@ember/routing/route';

export default Route.extend({
  setupController(controller, model) {
    let date=model.get('date');
    let _ct=model.get('courtType');
    controller.set('date', date.format('MM/DD/YYYY') || null);
    controller.set('hour', date.hour());
    controller.set('minute', date.minute());
    controller.set('location', model.get('location'));
    controller.set('description', model.get('description'));
    let tags = model.get('tags');
    // if (!isEmpty(tags))
    controller.set('tags', tags);
    controller.set('neutralCourt', model.get('neutralCourt'));
    controller.set('league', model.get('league'));
    controller.set('playoff', model.get('playoff'));
    controller.set('runningClock', model.get('runningClock'));
    controller.set('gameData', divisionData.gameData);
    let [ct] = divisionData.gameData;
    if (!isNone(_ct))
    {
      if (_ct === 7 )
      {
        _ct = 6; //handle the fiba 2009 drop
      }
      ct=divisionData.gameData[_ct]; 
    }
    controller.set('courtType', ct);
    controller.setProperties({
      periodCount: model.get('periodCount'),
      allowTimeTracking: model.get('periodSeconds') !== 0,
      periodMinutes: model.get('periodSeconds')/60,
      overtimeMinutes: model.get('periodOvertimeSeconds')/60,
      to20sec: model.get('timeout20Seconds'),
      to30sec: model.get('timeout30Seconds'),
      to60sec: model.get('timeout60Seconds'),
      bonus: model.get('bonus'),
      doubleBonus: model.get('doubleBonus')
    });
    this._super(controller, model);
  }
});
