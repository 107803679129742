import Ember from 'ember';
const { Route, isEmpty, RSVP } = Ember;
export default Route.extend({
  model(params) {
    if (!isEmpty(params.teamId)) {
      return this.store.find('bask-team', params.teamId).then((team) => {
        return {
          team
        };
      });
    } else if (!isEmpty(params.teamSeasonId)) {
      return this.store.find('bask-team-season', params.teamSeasonId).then((teamSeason) => {
        return RSVP.hash({
          teamSeason,
          team: teamSeason.get('team')
        });
      });
    }
  },
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('firstName', '');
      controller.set('lastName', '');
      controller.set('number', '');
      controller.set('awayNumber', '');
      controller.set('year', '');
      controller.set('homeTown', '');
      controller.set('height', '');
      controller.set('weight', '');
      controller.set('email', '');
      controller.set('phone', '');

    }
  }
});
