import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),
  model() {
    return this.get('session.account');
  },
  setupController(controller, model) {
    controller.set('plan', model.get('plan'));
    this._super(controller, model);
  }
});
