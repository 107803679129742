import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
export default Route.extend({
  intl: service(),

  model() {
    let account = this.modelFor('account');
    if (account) {
      return this.get('loadData').perform(account);
    } else {
      throw new Error('Active account returned as null');
    }
  },
  loadData: task(function* (account) {
    let videos = yield this.store.query('video', { //eslint-disable-line
      orderBy: 'account',
      equalTo: account.id
    });
    let slugs= yield account.get('slugs');
    let fm = new Intl.NumberFormat('en',{
      style: 'currency',
      currency: 'USD'
    });

    

    let monthlyCost=`(${fm.format(account.get('videoCost')/100)} ${this.get('intl').t('common.perMo')})`;
    let videoCostDescription = account.get('videoCostDescription');
    return { 
      videos, 
      monthlyCost, 
      videoCostDescription,
      slugs: slugs.toArray(),
      account
    };
  })
});
