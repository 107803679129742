import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
export default Controller.extend({
  sortedSubscriptions: computed('model.plans.[]', function() {
    let plans=this.get('model.plans');
    let ret=[];
    let subscriptions = plans.filterBy('type','live').sortBy('monthPrice');
    subscriptions.forEach((o) => ret.push(o));
    return ret;
  }),
  sortedBreakdownPlans: computed('model.plans.[]', function() {
    let plans=this.get('model.plans');
    let ret=[];
    let breakdowns = plans.filterBy('type','breakdown').sortBy('price');
    breakdowns.forEach((o) => ret.push(o));
    let packages = plans.filterBy('type','breakdown package').sortBy('price');
    packages.forEach((o) => ret.push(o));
    return ret;
  }),
  session: service(),
  monthPricing: true,
  actions:
  {
  togglePricing() {
      this.toggleProperty('monthPricing');
    }
  }
});
