import { computed } from '@ember/object';
import Controller from '@ember/controller';
import $ from 'jquery';
import { inject as service } from '@ember/service';
export default Controller.extend({
  session: service(),
  sortedPlayerSeasons: computed('model.playerSeasons.[]', function() {
    return this.get('model.playerSeasons').sortBy('sortableNumber');
  }),
  actions: {
    delete() {
      let player = this.get('deletingPlayer');
      return player.destroyRecord();
    },

    confirmDelete(player) {
      $('.ui.modal').modal('show');
      this.set('deletingPlayer', player);
    },
    denyModal() {

    },
    toggleButtons(player) {
      player.toggleProperty('showingButtons');
    }
  }
});
