import { observer, computed } from '@ember/object';
import Controller from '@ember/controller';
import divisionData from '../../division-data';
import { task } from 'ember-concurrency';
import { all } from 'rsvp';
/* global moment */
export default Controller.extend({
  hour: 12,
  minute: 0,
  uploadFilm: false,
  allowTimeTracking: true,
  date: moment(),
  canSave: computed('date', 'homeTeam', 'awayTeam', function() { 
    let date=this.get('date');
    let homeTeam=this.get('homeTeam');
    let awayTeam=this.get('awayTeam');
    return (date && homeTeam && awayTeam);
  }),
  sortedTeamSeasons: computed('model.teamSeasons', function () {
    return this.get('model.teamSeasons').sortBy('name');
  }),
  queryParams: ['teamSeasonId', 'seasonId', 'destination'],
  gameData: divisionData.gameData,
  courtTypeChanged: observer('courtType', function() {
    let ct = this.get('courtType');
    this.setProperties({
      periodCount: ct.periodCount,
      periodMinutes: ct.periodMinutes,
      overtimeMinutes: ct.overtimeMinutes,
      to20sec: ct.to20sec,
      to30sec: ct.to30sec,
      to60sec: ct.to60sec,
      bonus: ct.bonus,
      doubleBonus: ct.doubleBonus
    });
  }),
  _save: task(function* () {
    let attrs = this.getProperties([
      'date',
      'hour',
      'minute',
      'periodMinutes',
      'overtimeMinutes',
      'to20sec',
      'to30sec',
      'to60sec',
      'seasonId',
      'allowTimeTracking'
    ]);
    attrs.periodSeconds = attrs.allowTimeTracking ? attrs.periodMinutes * 60 : 0;
    attrs.periodOvertimeSeconds = attrs.allowTimeTracking ? attrs.overtimeMinutes * 60 : 0;
    if (attrs.date) {
      attrs.date = moment(attrs.date, 'MM/DD/YYYY');
      attrs.date.add(attrs.hour, 'hours');
      attrs.date.add(attrs.minute, 'minutes');
    }

    let homeTeam = this.get('homeTeam');
    let awayTeam = this.get('awayTeam');
    let game = this.store.createRecord('bask-game', this.getProperties([
      'courtType',
      'location',
      'description',
      'tags',
      'neutralCourt',
      'league',
      'playoff',
      'runningClock',
      'periodCount',
      'bonus',
      'doubleBonus'
    ]));
    game.setProperties({
      date: attrs.date,
      periodSeconds: attrs.periodSeconds,
      periodOvertimeSeconds: attrs.periodOvertimeSeconds,
      timeout20Seconds: attrs.to20sec,
      timeout30Seconds: attrs.to30sec,
      timeout60Seconds: attrs.to60sec,
      periodCurrent: 0,
      periodSecondsLeft: attrs.periodSeconds,
      homeFouls: 0,
      awayFouls: 0,
      possessionCurrent: 0,
      status: 'scheduled',
      homeTeam,
      awayTeam,
      season: attrs.seasonId,
      account: this.get('model.account.id')
    });
    yield game.save();
    let homeTeamGame = this.store.createRecord('bask-team-game', {
      account: this.get('model.account.id'), 
      game: game.id,
      timeout20Seconds: attrs.to20sec,
      timeout30Seconds: attrs.to30sec,
      timeout60Seconds: attrs.to60sec 
    });
    homeTeamGame.set('teamSeason', homeTeam);
    let awayTeamGame = this.store.createRecord('bask-team-game', { 
      account: this.get('model.account.id'), 
      game: game.id,
      timeout20Seconds: attrs.to20sec,
      timeout30Seconds: attrs.to30sec,
      timeout60Seconds: attrs.to60sec 
    });
    awayTeamGame.set('teamSeason', awayTeam);
    homeTeam.get('teamGames').pushObject(homeTeamGame);
    homeTeam.get('homeGames').pushObject(game);
    awayTeam.get('teamGames').pushObject(awayTeamGame);
    awayTeam.get('awayGames').pushObject(game);
    game.set('homeTeamGame', homeTeamGame.id);
    game.set('awayTeamGame', awayTeamGame.id);
    yield game.save();
    yield homeTeam.save();
    yield awayTeam.save();
    yield homeTeamGame.save();
    yield awayTeamGame.save();
    let gameId = game.get('id');
    let ledger = this.store.createRecord('game-event-ledger', { id: game.id, status: 'initial' });
    yield ledger.save({ adapterOptions: { 'gameId' : game.id } });
    let homePlayers = yield homeTeam.get('playerSeasons');
    let awayPlayers = yield awayTeam.get('playerSeasons');
    let promises = [];
    if (homePlayers) {
      homePlayers.forEach((ps)=>{
        let pg = this.store.createRecord('bask-player-game', {
          account: ps.get('account.id'),
          game: game.id,
          player: ps.get('player.id'),
          playerSeason: ps.id
        });
        promises.push(pg.save());
      });
    }
    if (awayPlayers) {
      awayPlayers.forEach((ps)=>{
        let pg = this.store.createRecord('bask-player-game', {
          account: ps.get('account.id'),
          game: game.id,
          player: ps.get('player.id'),
          playerSeason: ps.id
        });
        promises.push(pg.save());
      });
    }  
    
    return all(promises).then(() => {
      let destination = this.get('destination');
      let params = undefined;
      let qp = undefined;
      if (this.get('uploadFilm')) {
        if (destination)
        {
          qp={
            queryParams:{
              destination
            }
          };
        }
        destination='game.upload-film';
        params=gameId;
      }
      
      if (!destination) {
        destination = 'season.games';
        params=attrs.seasonId;
      }

      if (qp)
      {
        this.transitionToRoute(destination,params, qp);
      }
      else if (params) 
      {
        this.transitionToRoute(destination,params);
      }
      else
      {
        this.transitionToRoute(destination);
      }
      
    });
  }),
  actions: {
    save() {
      this.get('_save').perform();
    },
    cancel() {
      return this.transitionToRoute('season.games', this.get('seasonId'));
    }
  }
});
