


import { Lambda, util } from 'aws-sdk';
import Service from '@ember/service';
import EmObject from '@ember/object';
import { Promise, allSettled } from 'rsvp';
import Evaporate from 'evaporate';
import { task } from 'ember-concurrency';
import { A } from '@ember/array';
import ENV from '../config/environment';

var bucket = "video.input.stattracker.com";
let region = 'us-west-2';

/* eslint-disable no-console */

function formatBytes(a,b){if(0==a)return"0 Bytes";var c=1024,d=b||2,e=["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"],f=Math.floor(Math.log(a)/Math.log(c));return parseFloat((a/Math.pow(c,f)).toFixed(d))+" "+e[f]}

function authByAwsLambda (signParams, signHeaders, stringToSign, dateString) {
  return new Promise(function(resolve, reject) {
     var awsLambda = new Lambda({
        region,
        accessKeyId: 'AKIAJXYMTLIKWV7MUN6Q',
        secretAccessKey: 'QxmDlqf/RoKHJn9SEzpUu0dOH0mDju782Kqrr4VT'
     })
     awsLambda.invoke({
        FunctionName: 'arn:aws:lambda:us-west-2:172166497234:function:sign', // arn of your lambda function
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
           to_sign: stringToSign,
           sign_params: signParams,
           sign_headers: signHeaders,
           dateString
        })
     }, function (err, data) {
        if (err) {
           return reject(err);
        }
        resolve(JSON.parse(data.Payload));
     });
  });
}

function processFilmSegments(segments, gameId, account, season, name, quality, description)
{
  return new Promise(function(resolve, reject) {
    var awsLambda = new Lambda({
        region,
        accessKeyId: 'AKIAJJIEFQ3VOQP334KQ',
        secretAccessKey: 'H93caPbYDBDq7D2JhCgIrqSdr8Q1oAJYCQvlMa2C'
    })
    awsLambda.invoke({
        FunctionName: 'arn:aws:lambda:us-west-2:172166497234:function:video', // arn of your lambda function
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
          segments,
          account,
          season,
          gameId,
          name,
          stage: ENV.stage,
          quality,
          description
        })
    }, function (err, data) {
        if (err) {
          return reject(err);
        }
        resolve(JSON.parse(data.Payload));
    });
  });
}
export default Service.extend({
  init() {
    this._super(...arguments);
    this.get('_init').perform();
  },
  _init: task(function* () {
    this.set('uploads', A());
    this.set('evaporate', yield Evaporate.create({
      awsRegion: region,
      aws_key: 'AKIAIN4A4XRXKCYAX5KQ',
      bucket: bucket,
      customAuthMethod: authByAwsLambda,
      computeContentMd5: true,
      cryptoMd5Method: function (data) { return util.crypto.md5(data, 'base64'); },
      cryptoHexEncodedHash256: function (data) { return util.crypto.sha256(data, 'hex'); },
      aws_url: 'https://s3.us-west-2.amazonaws.com',
      allowS3ExistenceOptimization: true,
      logging: false
    }));
  }),
  addFile(key, file, model) {
    if (!key) {
      throw new Error('Key not found on when trying to add a file to upload');
    }
    if (!file) {
      throw new Error('File not found on when trying to add a file to upload');
    }
    let modelId=model.id;
    let plural=model.constructor.modelName.camelize();
    let p = `${plural}s/${modelId}/${file.name}`;
    let account = model.get('account');
    let s3 = `s3://${bucket}/${p}`;
    
    if (account) {
      p = `${account}/${plural}s/${model.id}/${file.name}`;
    }
    
    let file_key = `${bucket}/${p}`;

    let u = EmObject.create({
      id: modelId,
      file,
      p,
      s3,
      file_key,
      status: 'Pending',
      canPause: false,
      canResume: false,
      name: file.name,
      readableSpeed:0,
      remainingSize:0,
      secondsLeft:0,
      type: file.type,
      percent: 0,
      description: formatBytes(file.size)
    });
    this.get('uploads').pushObject(u);
  },
  clear() {
    this.set('uploads', A());
  },
  pauseUpload(upload) {
    let evaporate=this.get('evaporate');
    upload.set('canPause', false);
    return evaporate.pause(upload.get('file_key'));
  },
  resumeUpload(upload) {
    let evaporate=this.get('evaporate');
    upload.set('canResume', false);
    return evaporate.resume(upload.get('file_key'));
  },
  cancelUploads() { //eslint-disable-line no-unused-vars
    this.set('uploads', A());
    let evaporate=this.get('evaporate');
    return evaporate.cancel();
  },
  
  saveUploads(s3model, name, account, season, quality, description) {
    this.get('_saveUploads').perform(s3model, name, account, season, quality, description);
  },
  _saveUploads: task(function* (s3model, name, account, season, quality, description){
    const modelId=s3model.get('id');
    const uploads = this.get('uploads');
    let evaporate=this.get('evaporate');
    let promises=[];
    if (uploads.length > 0)
    {
      s3model.set('rawFilmStatus', 'uploading');
      yield s3model.save();
      uploads.forEach((item) => {
        if (item.id === modelId)
        {
          promises.push(evaporate.add({
            file:item.file,
            name: item.p,
            contentType: item.type,
            started: (fk) => { //eslint-disable-line no-unused-vars
              item.set('status', 'Started');
              item.set('canPause', true);
              item.set('canResume', false);
            },
            paused: (fk) => { //eslint-disable-line no-unused-vars
              item.set('status', 'Paused');
              item.set('canPause', false);
              item.set('canResume', true);
            },
            cancelled: (fk) => { //eslint-disable-line no-unused-vars
              item.set('status', 'Canceled');
              item.set('canPause', false);
              item.set('canResume', false);
            },
            resumed: (fk) => { //eslint-disable-line no-unused-vars
              item.set('status', 'Resumed');
              item.set('canPause', true);
              item.set('canResume', false);
            },
            uploadInitiated: (fk) => { //eslint-disable-line no-unused-vars
              item.set('status', 'Initiated');
              item.set('canPause', true);
              item.set('canResume', false);
            },
            complete: (fk) => { //eslint-disable-line no-unused-vars
              item.set('status', 'Complete');
              item.set('canPause', false);
              item.set('canResume', false);
            },
            info: (msg) => {
              msg;
              //item.set('message', msg);
              //console.log(msg);
            },
            warn: (msg) => {
              //item.set('message', msg);
              console.log(msg);
            },
            error: (msg) => {
              //item.set('message', msg);
              console.log(msg);
            },
            progress: (p, stats) => {
              let timeLeft = stats.secondsLeft < 60 ? `${stats.secondsLeft} seconds` : `${(stats.secondsLeft/60).toFixed(2)} minutes`;
              item.set('description', `${formatBytes(stats.totalUploaded)} of ${formatBytes(stats.fileSize)} at ${stats.readableSpeed}/s  ( ${(p*100).toFixed(2)}% - ${timeLeft} )`)
            }
          }));
        }
      });
      let responses = yield allSettled(promises);
      let succeeded=true;
      
      let segments = [];
      responses.forEach((item) => {
        if (item.state === 'fulfilled') {
          segments.push(item.value);
        }
        else {
          succeeded=false;
        }
      });
      if (succeeded)
      {
        s3model.set('rawFilmStatus', 'processing');
        yield s3model.save();
        let removes=A();
        uploads.filterBy('id', modelId).forEach((item) => {
          removes.pushObject(item);
        });
        uploads.removeObjects(removes);
        yield processFilmSegments(segments, modelId, account, season, name, quality, description);
      }
    }
  })
});
