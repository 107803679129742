import { task } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
export default Route.extend({
  session: service(),
  model(params) {
    let seasonId = params.season_id;
    if (isPresent(seasonId)) {
      return this.get('loadModel').perform(seasonId);
    }
  },

  loadModel: task(function* (seasonId) {
    let account = this.get('session.account');
    let season = yield this.store.find('bask-season', seasonId);
    let teamSeasons = yield season.get('teamSeasons');
    let games = yield this.store.query('bask-game', {
      orderBy: 'season',
      equalTo: seasonId
    });
    return {
      account,
      season,
      teamSeasons,
      games
    };
  })
});
