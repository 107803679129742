export let courtData = [
  {
    id:  0,
    name:  'USHS',
    length:  84.00,
    width: 50.00,
    restrictedAreaRadius: 0.0,
    centerCircleRadius: 6.000,
    threePointRadius: 19.75,
    threePointRadius2: 19.75,
    keyWidthTop: 12.00,
    keyWidthBottom: 12.00,
    freeThrowToBackboard: 15.00,
    straight: 5.25
  },
  {
    id: 1,
    name: 'USJHS',
    length: 74.00,
    width: 42.00,
    restrictedAreaRadius: 0.0,
    centerCircleRadius: 6.000,
    threePointRadius: 19.75,
    threePointRadius2: 19.75,
    keyWidthTop: 12.00,
    keyWidthBottom: 12.00,
    freeThrowToBackboard: 15.00,
    straight: 5.25
  },
  {
    id: 2,
    name: 'WNCAA',
    length: 94.00,
    width: 50.00,
    restrictedAreaRadius: 3.0,
    centerCircleRadius: 6.000,
    threePointRadius: 20.75,
    threePointRadius2: 20.75,
    keyWidthTop: 12.00,
    keyWidthBottom: 12.00,
    freeThrowToBackboard: 15.00,
    straight: 5.25
  },
  {
    id: 3,
    name: 'NCAA',
    length: 94.00,
    width: 50.00,
    restrictedAreaRadius: 4.0,
    centerCircleRadius: 6.000,
    threePointRadius: 20.75,
    threePointRadius2: 20.75,
    keyWidthTop: 12.00,
    keyWidthBottom: 12.00,
    freeThrowToBackboard: 15.00,
    straight: 5.25
  },
  {
    id: 4,
    name: 'WNBA',
    length: 94.00,
    width: 50.00,
    restrictedAreaRadius: 4.0,
    centerCircleRadius: 6.000,
    threePointRadius: 22.14,
    threePointRadius2: 22.00,
    keyWidthTop: 16.00,
    keyWidthBottom: 16.00,
    freeThrowToBackboard: 15.00,
    straight: 5.25
  },
  {
    id: 5,
    name: 'NBA',
    length: 94.00,
    width: 50.00,
    restrictedAreaRadius: 4.0,
    centerCircleRadius: 6.000,
    threePointRadius: 23.75,
    threePointRadius2: 22.00,
    keyWidthTop: 16.00,
    keyWidthBottom: 16.00,
    freeThrowToBackboard: 15.00,
    straight: 14.00
  },
  {
    id: 7,
    name: 'FIBA',
    length: 91.86,
    width: 49.21,
    restrictedAreaRadius: 4.1,
    centerCircleRadius: 5.905,
    threePointRadius: 22.15,
    threePointRadius2: 21.65,
    keyWidthTop: 16.08,
    keyWidthBottom: 16.08,
    freeThrowToBackboard: 15.09,
    straight: 9.81
  }];
 export let gameData = [
    {
      id: 0,
      name: 'USHS',
      periodMinutes: 8,
      overtimeMinutes: 4,
      to20sec: 0,
      to30sec: 2,
      to60sec: 3,
      periodCount: 4,
      bonus: 7,
      doubleBonus: 10
    },
    {
      id: 1,
      name: 'USJHS',
      periodMinutes: 8,
      overtimeMinutes: 4,
      to20sec: 0,
      to30sec: 0,
      to60sec: 3,
      periodCount: 4,
      bonus: 7,
      doubleBonus: 10
    },
    {
      id: 2,
      name: 'WNCAA',
      periodMinutes: 10,
      overtimeMinutes: 5,
      to20sec: 0,
      to30sec: 2,
      to60sec: 4,
      periodCount: 4,
      bonus: 0,
      doubleBonus: 5
    },
    {
      id: 3,
      name: 'NCAA',
      periodMinutes: 20,
      overtimeMinutes: 5,
      to20sec: 0,
      to30sec: 2,
      to60sec: 4,
      periodCount: 2,
      bonus: 7,
      doubleBonus: 10
    },
    {
      id: 4,
      name: 'WNBA',
      periodMinutes: 10,
      overtimeMinutes: 5,
      to20sec: 0,
      to30sec: 2,
      to60sec: 3,
      periodCount: 4,
      bonus: 7,
      doubleBonus: 10
    },
    {
      id: 5,
      name: 'NBA',
      periodMinutes: 12,
      overtimeMinutes: 5,
      to20sec: 1,
      to30sec: 0,
      to60sec: 6,
      periodCount: 4,
      bonus: 7,
      doubleBonus: 10
    },
    {
      id: 7,
      name: 'FIBA',
      periodMinutes: 10,
      overtimeMinutes: 5,
      to20sec: 0,
      to30sec: 2,
      to60sec: 1,
      periodCount: 4,
      bonus: 5,
      doubleBonus: 0
    }
  ];
