import groupBy from 'stattracker-shared/utils/group-by';
import mapValues from 'stattracker-shared/utils/mapValues';
import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
export default Route.extend({
  session: service(),

  model() {
    let active = this.get('session.activeSeasonId');
    if (active) {
      return this.get('loadData').perform(active);
    } else {
      return {
        games: {},
        standings: {}
      };
    }
  },

  loadData: task(function* (active) {
    let games = yield this.store.query('bask-game', {
      orderBy: 'season',
      equalTo: active
    });
    let standings = yield this.store.query('bask-standing', {
      orderBy: 'season',
      equalTo: active
    });
    let season=yield this.store.find('bask-season',active);
    return {
      season,
      games: games.toArray(),
      standings: this.groupStandings(standings)
    };
  }),
  setupController(controller, model) {
    controller.set('season', model.season);
    let games=this.store.peekAll('bask-game');
    controller.set('games',games);
    this._super(controller, model);
  },
  renderTemplate() {
    let session = this.get('session');
    if (session.get('isAuthenticated') || session.get('isPublic')) {
      let status=session.get('userStatus');
      if (status==1) {
        this.render('welcome');
      }
      else if (status==2)
      {
        this.render('augment',{ controller: 'augment'});
      }
      else {
        this.render('dashboard');
      }
    } else {
      this.render('default');
    }
  },

  groupStandings(standings) {
    standings = groupBy(standings.toArray(), (s) => s.get('league'));
    standings = mapValues(standings, (value) => groupBy(value, (s) => s.get('division')));
    return standings;
  },

  actions: {
    changeActiveAccount(account) {
      let session = this.get('session');
      if (session.get('accountId') !== account.get('id')) {
        session.setActiveAccount(account);
        let active = account.get('activeSeason');
        this.get('loadData').perform(active).then((model) => {
          session.setActiveSeasonId(active);
          let status = session.get('user.status');
          let template = 'dashboard';
          if (status==1)
          {
            template="welcome";  
          }
          else if (status==2)
          {
            template="augment";  
          }
          this.render(template, {
            model
          });
        });
      }
      return false;
    }
  }
});
