import Controller from '@ember/controller';
import $ from 'jquery';
import { inject as service } from '@ember/service';
import UploadMixin from '../../mixins/fb-upload';
import { task } from 'ember-concurrency';
import firebase from 'firebase';
import emailVal from '../../mixins/email-validation';
import { computed } from '@ember/object';
export default Controller.extend(UploadMixin, emailVal, {
  firebaseApp: service(),
  session: service(),
  intl: service(),

  hasFirstName: computed('model.firstName', function() {
    let fn=this.get('model.firstName');
    return fn && fn.length>0;
  }),

  hasLastName: computed('model.lastName', function() {
    let ln=this.get('model.lastName');
    return ln && ln.length>0;
  }),

  hasName: computed.and('hasFirstName','hasLastName'),

  hasEmail: computed('model.email', function() {
    let email = this.get('model.email');
    return this._checkEmail(email);
  }),

  canSave: computed('save.isRunning', 'hasName', 'hasEmail', function() {
    return !this.get('save.isRunning') && this.get('hasName') && this.get('hasEmail');
  }),

  actions: {
    changePassword()
    {
      this.set('errorMessage', '');
      this.set('successMessage', '');
      $('.ui.changePassword.modal').modal('show');
    },
    save() {
      let user = this.get('model');
      this.saveUploads(user, undefined).then((model) => {
        this.clear();
        if (user.get('provider') === 'password' && user.get('email') !== this.get('oldEmail')) {
          $('.ui.changeEmail.modal').modal('show');
        } else {
          model.set('useGravatar', this.get('useGravatar'));
          model.save();
        }
      });
    },

    sendResetPasswordEmail() {
      this.get('firebaseApp').sendPasswordResetEmail(this.get('oldEmail')).catch((err) => {
        this.set('errorMessage', err.message);
      });
    },
    approveChangePassword() {
      this.get('_approveChangePassword').perform()
      .catch((err) => {
        this.set('errorMessage', err.message);
        this.set('password', '');
        this.set('oldPassword', '');
      });
    },
    approveChangeEmail() {
      this.get('_approveChangeEmail').perform()
      .catch((err) => {
        this.set('errorMessage', err.message);
        this.set('password', '');
      });
    },
    denyChangeEmail() {
      this.set('password', '');
      this.set('errorMessage', '');
    },
    denyChangePassword() {
      this.set('password', '');
      this.set('oldPassword', '');
    },
    closeErrorMessage() {
      this.set('errorMessage', '');
    },
    closePositiveMessage() {
      this.set('successMessage', '');
    }
  },

  _approveChangeEmail: task(function* () {
    var user = this.get('firebaseApp').auth().currentUser;
    if (user) {
      let credential = firebase.auth.EmailAuthProvider.credential(
          this.get('oldEmail'),
          this.get('password')
      );
      yield user.reauthenticateWithCredential(credential);
      yield user.updateEmail(this.get('model.email')).then(() => {
        return this.get('model').save().then((user) => {
          this.set('successMessage', this.get('intl').t('app.emailChanged'));
          this.setProperties({
            oldEmail: user.get('email'),
            errorMessage: '',
            password: ''
          });
        });
      }).catch((err) => {
        this.set('errorMessage', err.message);
      });
    }
  }),

  _approveChangePassword: task(function* () {
    var user = this.get('firebaseApp').auth().currentUser;
    if (user) {
      let credential = firebase.auth.EmailAuthProvider.credential(
          this.get('model.email'),
          this.get('oldPassword')
      );
      yield user.reauthenticateWithCredential(credential);
      user.updatePassword(this.get('password')).then(() => {
        this.set('successMessage', this.get('intl').t('password.changed'));
        this.setProperties({
          errorMessage: '',
          password: '',
          oldPassword: ''
        });
      }).catch((err) => {
        this.set('errorMessage', err.message);
      });
    }
  })
});
