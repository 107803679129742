import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
export default Route.extend({
  session: service(),
  model(params) {
    let seasonId = params['bask-season_id'];
    if (isPresent(seasonId)) {
      return this.get('loadModel').perform(seasonId);
    }
  },

  loadModel: task(function* (seasonId) {
    let account = this.get('session.account');
    let season = yield this.store.find('bask-season', seasonId);
    let teamSeasons = yield season.get('teamSeasons');
    let seasonsTeams = yield teamSeasons.toArray().getEach('team');
    let teams = yield this.store.findAll('bask-team');
    return {
      account,
      season,
      seasonsTeams,
      teams
    };
  }),

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('selectionCount', null);
      controller.set('selectTeam', null);
    }
  }
});
