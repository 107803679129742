import { task } from 'ember-concurrency';
import { all } from 'rsvp';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
export default Controller.extend({
  queryParams: ['destination'],
  statFormat: 0,
  actions: {
    finalize() {
      this.get('_finalize').perform();
    }
  },
  init(){
    this._super(...arguments);
    this.statValidators = {
      _FGA:
      {
        message: 'FGA must equal or exceed FG',
        validator(value, item) {
          if (item['FG'] !== undefined) {
            if (value !== undefined) {
              return value >= item['FG'];
            }
            return false;
          }
          return true;
        }
      },
      _2FGA:
      {
        message: '2FGA must equal or exceed 2FG',
        validator(value, item) {
          if (item['2FG'] !== undefined ) {
            if (value !== undefined) {
              return value >= item['2FG'];
            }
            return false;
          }
          return true;
        }
      },
      _3FGA: {
        message: '3FGA must equal or exceed 3FG',
        validator(value, item) {
          if (item['3FG'] !== undefined) {
            if (value !== undefined) {
              return value >= item['3FG'];
            }
            return false;
          }
          return true;
        }
      },
      _FTA: {
        message: 'FTA must equal or exceed FT',
        validator(value, item) {
          if (item.FT !== undefined) {
            if (value !== undefined) {
              return value >= item.FT;
            }
            return false;
          }
          return true;
        }
      },
      positive:
      {
        message: 'Value must be >= 0',
        validator(value, item) { //eslint-disable-line
          if (value) {
            return value >= 0;
          }
          return true;
        }
      },
      _numberReq:
        {
          message: 'Must be a number, 00-99',
          validator(value, item) { //eslint-disable-line
            if (value && value.length <= 2 && value.length>0) {
              let parsed=Number.parseInt(value);
              if (Number.isNaN(parsed)) {
                return false;
              }
              return true;
            }
            return false;
          }
        }
    };
  },
  _finalize: task(function* () {
    let model = this.get('model');
    let promises = [];
    model.awayPlayerGames.forEach((pg) => {
      if (pg.id) {
        let playerGame = model.playerGames.findBy('id', pg.id);
        if (playerGame) {
          playerGame.setProperties(this.shimPlayerGame(pg));
          promises.push(playerGame.save());
        }
      } else {
        pg.account=this.get('model.game.account');
        pg.game=this.get('model.game.id');
        let playerGame = this.store.createRecord('bask-player-game', this.shimPlayerGame(pg));
        promises.push(playerGame.save());
      }
    });
    model.homePlayerGames.forEach((pg) => {
      if (pg.id) {
        let playerGame = model.playerGames.findBy('id', pg.id);
        if (playerGame) {
          playerGame.setProperties(this.shimPlayerGame(pg));
          promises.push(playerGame.save());
        }
      } else {
        pg.account=this.get('model.game.account');
        pg.game=this.get('model.game.id');
        let playerGame = this.store.createRecord('bask-player-game', this.shimPlayerGame(pg));
        promises.push(playerGame.save());
      }
    });
    if (model.game.get('homeScore')!=model.homeScore) {
      model.game.set('homeScore', model.homeScore);
    }
    if (model.game.get('awayScore')!=model.awayScore) {
      model.game.set('awayScore', model.awayScore);
    }
    promises.push(model.game.save());
    yield all(promises);
    let params = {
      game: model.game.get('id'),
      season: model.game.get('season'),
      account: model.game.get('account'),
      reportType: 'all'
    };
    let api = this.get('st-api');
    if (api) {
      return api.finalize(params).then((response) => { //eslint-disable-line
        //console.log(response); //eslint-disable-line
        if (this.get('destination'))
        {
          this.transitionToRoute(this.get('destination'));
        }
        else
        {
          this.transitionToRoute('index');
        }
      });
    }
  }),
  shimPlayerGame(pg) {
    if (pg['FG'] && !pg['2FG']) {
      let _3pm = pg['3FG'] || 0;
      pg['2FG'] = pg['FG'] - _3pm;
    }
    if (pg['FGA']){
      let _3pa = pg['3FGA'] || 0;
      pg['2FGA'] = pg['FGA'] - _3pa;
    }
    return {
      account: pg.account,
      game: pg.game,
      player: pg.player,
      playerSeason: pg.playerSeason,
      points: pg.PTS,
      pointsOffTurnovers: pg.POT,
      secondChancePoints: pg.SCP,
      fastbreakPoints: pg.FBP,
      plusMinus: pg.PM,
      fouls: pg.PF,
      foulsReceived: pg.PFR,
      ftm: pg.FT,
      fta: pg.FTA,
      _3pa: pg['3FGA'],
      _3pm: pg['3FG'],
      _2pa: pg['2FGA'],
      _2pm: pg['2FG'],
      turnovers: pg.TO,
      assists: pg.AST,
      steals: pg.STL,
      blocks: pg.BLK,
      blocksReceived: pg.BLKR,
      deflections: pg.DEFL,
      chargesTaken: pg.CD,
      offensiveRebounds: pg.OFF,
      defensiveRebounds: pg.DEF,
      secondsPlayed: pg.MIN * 60,
      played: pg.played
    };
  },
  statFormats: computed(function(){
    return [
      { name: 'Stattracker', index: 0},
      { name: 'Box Score', index: 1},
      { name: 'Krossover', index: 2},
      { name: 'Stattracker All', index: 3},
    ];
  }),
  fields: computed('statFormat', function() {
    let statFormat = this.get('statFormat');
    switch (statFormat)
    {
      case 0: {   
        return [
          { name: 'Name', type: 'text', width: 120, css: 'jsgrid-edit', readOnly: true },
          { type: 'control' },
          { name: 'number', title: '#', type: 'text', width: 50, css: 'jsgrid-edit', readOnly: true, editTemplate },
          { name: 'played', title: 'Played', type: 'checkbox', css: 'jsgrid-edit', width: 50 },
          { name: 'PTS', type: 'number', width: 45, css: 'jsgrid-edit', readOnly: true, editTemplate  },
          { name: '2FG', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: '2FGA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_2FGA' },
          { name: '3FG', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: '3FGA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_3FGA' },
          { name: 'FT', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'FTA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_FTA' },
          { name: 'OFF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'DEF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'REB', type: 'number', width: 45, css: 'jsgrid-edit', readOnly: true, editTemplate },
          { name: 'TO', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'AST', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'BLK', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'BLKR', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'STL', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'DEFL', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'CD', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'PF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'PFR', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'TF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'MIN', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' }
        ];
      }
      case 1: {   
        return [
          { name: 'Name', type: 'text', width: 120,css: 'jsgrid-edit', readOnly: true },
          { type: 'control' },
          { name: 'number', title: '#', type: 'text', width: 50, css: 'jsgrid-edit', readOnly: true, editTemplate },
          { name: 'played', title: 'Played', type: 'checkbox',css: 'jsgrid-edit', width: 50 },
          { name: 'FG', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'FGA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_FGA' },
          { name: '3FG', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: '3FGA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_3FGA' },
          { name: 'FT', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'FTA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_FTA' },
          { name: 'OFF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'DEF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'REB', type: 'number', width: 45, css: 'jsgrid-edit', readOnly: true, editTemplate },
          { name: 'PF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'PTS', type: 'number', width: 45, css: 'jsgrid-edit', readOnly: true, editTemplate },
          { name: 'AST', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'TO', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'BLK', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'STL', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'MIN', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' }
          
        ];
      }
      case 2: {   
        return [
          { name: 'Name', type: 'text', width: 120, css: 'jsgrid-edit', readOnly: true},
          { type: 'control' },
          { name: 'number', title: '#', type: 'text', width: 50, css: 'jsgrid-edit', readOnly: true, editTemplate },
          { name: 'played', title: 'Played', type: 'checkbox', css: 'jsgrid-edit', width: 50 },
          { name: 'FG', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'FGA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_FGA' },
          { name: '3FG', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: '3FGA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_3FGA' },
          { name: 'FT', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'FTA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_FTA' },
          { name: 'OFF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'DEF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'REB', type: 'number', width: 45, css: 'jsgrid-edit', readOnly: true, editTemplate },      
          { name: 'AST', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'STL', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'BLK', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'TO', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'CD', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'PF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'PTS', type: 'number', width: 45, css: 'jsgrid-edit', readOnly: true, editTemplate }
        ];
      }
      case 3: {   
        return [
          { name: 'Name', type: 'text', width: 120, css: 'jsgrid-edit', readOnly: true},
          { type: 'control' },
          { name: 'number', title: '#', type: 'text', width: 50, css: 'jsgrid-edit', readOnly: true, editTemplate },
          { name: 'played', title: 'Played', type: 'checkbox',css: 'jsgrid-edit', width: 50 },
          { name: 'PTS', type: 'number', width: 45, css: 'jsgrid-edit', readOnly: true, editTemplate  },
          { name: '2FG', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: '2FGA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_2FGA' },
          { name: '3FG', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: '3FGA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_3FGA' },
          { name: 'FT', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'FTA', type: 'number', width: 45, css: 'jsgrid-edit', validate: '_FTA' },
          { name: 'POT', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'SCP', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'FBP', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'PM', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'OFF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'DEF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'REB', type: 'number', width: 45, css: 'jsgrid-edit', readOnly: true, editTemplate },
          { name: 'TO', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'AST', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'BLK', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'BLKR', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'STL', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'DEFL', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'CD', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'PF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'PFR', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'TF', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' },
          { name: 'MIN', type: 'number', width: 45, css: 'jsgrid-edit', validate: 'positive' }
        ];
      }
    }
  }),
  config: computed('fields', function () {
    let fields = this.get('fields');
    return {
      width: '100%',
      //height: '400px',
  
      inserting: false,
      editing: true,
      sorting: false,
      paging: false,
      onItemInserting(args){
        if (args.item['Name'] && args.item['number'])
        {
          if (args.item['Name'].length == 0 || args.item['number'].length == 0)
          {
            args.cancel=true;
          }
        }
        else {
          args.cancel=true;
        }
      },
      onItemUpdated(p) {
        let _2pt = 0;
        let _3pt = 0;
        let _ft = 0;
        let _OR = 0;
        let _DR = 0;
        let needsRefresh = false;
        
        if (p.item.POT || p.item.SCP || p.item.FBP || p.item.PM || p.item.PF 
          || p.item.PFR || p.item.FT || p.item.FTA || p.item['3FGA'] || p.item['3FG']
          || p.item['2FGA'] || p.item['2FG'] || p.item.TO || p.item.AST || p.item.STL
          || p.item.BLK || p.item.BLKR || p.item.DEFL || p.item.CD || p.item.OFF || p.item.DEF
          || p.item.MIN || p.item.TF ) {
          if (!p.item.played)
          {
            p.item.played = true;
            needsRefresh = true;
          }
        }

      
        if (p.item['2FG']) {
          _2pt = p.item['2FG'] * 2;
        }
        if (p.item['3FG']) {
          _3pt = p.item['3FG'] * 3;
        }
        if (p.item['FG']) {
          _2pt = (p.item['FG'] - (p.item['3FG']||0)) * 2;
        }
        if (p.item.FT)        {
          _ft = p.item.FT;
        }
        if (p.item.OFF)        {
          _OR = p.item.OFF;
        }
        if (p.item.DEF)        {
          _DR = p.item.DEF;
        }
        if (p.item.PTS !== (_2pt + _3pt + _ft)) {
          p.item.PTS = _2pt + _3pt + _ft;
          needsRefresh = true;
        }
        if (p.item.REB !== (_OR + _DR)) {
          p.item.REB = _OR + _DR;
          needsRefresh = true;
        }

        if (needsRefresh)
        {
          p.grid.updateItem(p.item);
        }
      },
      fields
    };
  })
});

function editTemplate(value){
  if(!this.editing)
      return this.itemTemplate.apply(this, arguments);

  var $result = this.editControl = this._createTextBox();
  $result.val(value);
  $result.prop('tabindex', -1);
  return $result;
}