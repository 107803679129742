import Route from '@ember/routing/route';
/* global moment*/
export default Route.extend({
  setupController(controller, model) {
    let season = model.season;
    let start=season.get('start') || moment();
    controller.set('start', start.format('MM/DD/YYYY'));
    controller.set('seasonType', season.get('seasonType'));
    controller.set('name', season.get('name'));
    this._super(controller, model);
  }
});
