
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

/* global moment*/
export default Controller.extend( {
  init(){
    this._super(...arguments);
    this.set('include720', true);
    this.set('include360', true);
  },

  intl: service(),
  session: service(),
  uploader: service(),
  metrics: service(),
  quality: computed('include4k', 'include1080', 'include720', 'include480', 'include360', function() {
    let ret=[];
    if (this.get('include4k')) { ret.push(4000);}
    if (this.get('include1080')) { ret.push(1080);}
    if (this.get('include720')) { ret.push(720);}
    if (this.get('include480')) { ret.push(480);}
    if (this.get('include360')) { ret.push(360);}
    return ret;
  }),
  queryParams: ['game', 'destination'],
  overtimes: 0,

  canSave: computed('_save.isRunning', 'hasUpload', function() {
    if  (!this.get('_save.isRunning'))
    {
      return this.get('hasUpload');
    }
    return false;
  }),

  canAdd: computed('model.game.rawFilmStatus', 'hasUpload', function(){
    let filmStatus = this.get('model.game.rawFilmStatus');
    if (filmStatus && (filmStatus === 'uploading' || filmStatus === 'processing' ))
    {
      if (this.get('hasUpload')) 
      {
        return false;
      }
    }
    return true;
  }),

  actions: {
    save() {
      this.get('_save').perform();
    },
    cancel() {
      this.get('_cancel').perform();
    },
    fileLoaded(f)
    {
      let uploader=this.get('uploader');
      let game  = this.get('model.game');
      uploader.addFile('filmURL', f, game);
    }
  },

  hasUpload: computed('uploads.[]', function(){
    let uploads=this.get('uploads');
    if (uploads && uploads.length>0)
    {
      return true;
    }
    return false;
  }),

  uploads: computed('uploader.uploads.[]', 'model.game.id', function(){
    let gameId  = this.get('model.game.id');
    let uploads = this.get('uploader.uploads');
    return uploads.filterBy('id', gameId);
  }),

  _cancel: task(function* () {
    let uploader=this.get('uploader');
    yield uploader.cancelUploads();
    this.get('metrics').trackEvent('GoogleAnalytics', {
      category: 'upload-film',
      action: 'cancel'
    });
  }),

  _save: task(function* () {
    let uploader=this.get('uploader');
    let destination = this.get('destination');
    let game  = this.get('model.game');
    let homeTeam=yield game.get('homeTeam.name');
    let awayTeam=yield game.get('awayTeam.name');
    let location=yield game.get('location');
    let description = yield game.get('description');
    if (location)
    {
      location=` at ${location}`;
    }
    else
    {
      location = '';
    }
    let dt = moment(yield game.get('date')).format('MM-DD-YYYY');
    let gameName=`${dt}  ${homeTeam} vs ${awayTeam}${location}`;
    let accountId = game.get('account');
    let quality=this.get('quality');
    let season = game.get('season');
    uploader.saveUploads(game, gameName, accountId, season, quality, description);
    this.get('metrics').trackEvent('GoogleAnalytics', {
      category: 'upload-film',
      action: 'upload'
    });
    if (destination) {
      this.transitionToRoute(destination);
    }
    else
    {
      this.transitionToRoute('game', game);
    }
  })
});
