import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
import $ from 'jquery';
export default Controller.extend({
  session: service(),
  _delete: task(function* () {
    let game = this.get('deletingGame');
    if (game) {
      yield game.destroyRecord();
    }
  }),
  actions: {
    delete() {
      this.get('_delete').perform();
    },

    confirmDelete(game) {
      $('.ui.modal').modal('show');
      this.set('deletingGame', game);
    },
    denyModal() {

    }
  },
  games: computed('model.{homeGames.[],awayGames.[]}', 'deletingGame', function() {
    let teamseason = this.get('model.teamSeason.id');
    let allGames = this.store.peekAll('bask-game').filter(function(item){
      let st=item.get('currentState.stateName');
      if (st && st.startsWith('root.loaded')) {
        return ((item.belongsTo("homeTeam").id() === teamseason) || (item.belongsTo("awayTeam").id() === teamseason ));
      }
      return false;
    });    
    return allGames.sortBy('sortableDate');
  })
});
