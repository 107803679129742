import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
import $ from 'jquery';
import { inject as service } from '@ember/service';
import { all } from 'rsvp';
import { computed } from '@ember/object';
import { filterBy } from '@ember/object/computed';
import ENV from '../../config/environment';

export default Controller.extend({
  session: service(),
  slug: computed('model.slugs[]', function(){
    let slugs = this.get('model.slugs');
    let account = this.get('model.account')
    if (slugs && slugs.length && account && account.get('isPublic'))
    {
      return slugs[0].get('displayName');
    }
    return null;
  }),
  publicUrl: computed('slug', function(){
    let slug = this.get('slug');
    if (slug) {
      return `${ENV.publicSiteURL}/${slug}/video/`;
    }
    return null;
  }),
  validVideos: filterBy('model.videos', 'isValid', true),
  sortedVideos: computed('validVideos', function () {
    return this.get('validVideos').sortBy('sortableDate').reverse();
  }),
  deleteAll: task(function* () {
    let promises=[];
    let videos = this.get('model.videos');
    videos.forEach((v)=>{
      promises.push(v.destroyRecord());
    });
    return yield all(promises);
  }).drop(),
  _shareFilm: task(function* (video) {
    let link = video.get('link');
    let image = video.get('poster');
    let shouldShare = !video.get('share');
    if (!link && shouldShare)
    {
      let api = this.get('st-api');
      if (api) {
        let dest = `${this.get('publicUrl')}${video.id}`;
        let title = video.get('name');
        let desc = video.get('description');
        
        link = yield api.link(dest, title, desc, image);
        if (link && link.shortLink)
        {
          video.set('link', link.shortLink);
        }
      }
    }
    video.set('share', shouldShare);
    yield video.save();
  }),
  actions: {
    denyModal() {

    },
    delete() {
      let video = this.get('deletingVideo');
      let promise = video.destroyRecord();
      this.set('deletionPromise', promise);
      return promise;
    },
    confirmDelete(video) {
      $('.ui.modal').modal('show');
      this.set('deletingVideo', video);
    },
    shareFilm(video) {
      this.get('_shareFilm').perform(video);
    }
  }
});
