define("stattracker-live/adapters/play-by-play-report", ["exports", "stattracker-shared/adapters/play-by-play-report"], function (_exports, _playByPlayReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _playByPlayReport.default;
    }
  });
});
