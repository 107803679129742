import Ember from 'ember';
import UploadMixin from '../../mixins/fb-upload';
const { inject: { service } } = Ember;
const { Controller } = Ember;
import { task } from 'ember-concurrency';
export default Controller.extend(UploadMixin, {
  actions: {
    save() {
      this.get('_save').perform();
    },
    cancel() {
      this.transitionToRoute('team.allseasons', this.get('model.team.id'));
    }

  },
  _save: task(function* () {
    let team = this.get('model.team');
    let accountId = team.get('account.id');
    let model = yield this.saveUploads(team, accountId);
    this.clear();
    yield model.save();
    this.transitionToRoute('team.allseasons', team.id);
  })
});
