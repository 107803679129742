import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import downloads from '../../mixins/downloads-mixin';
export default Controller.extend(downloads, {
  session: service(),
  init() {
    this._super();
    this.set('downloads',this.store.peekAll('download'));
  }
});
