import live from './live'
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { all } from 'rsvp';
export default live.extend({
  lazyload: service(),
  beforeModel() {
    return this.get('lazyload').loadVideo();
  },
  setupController(controller, model) {
    controller.set('leftColor', `#${model.homeTeam.get('homeColor')}`);
    controller.set('rightColor', `#${model.awayTeam.get('awayColor')}`);
    this._super(controller, model);
    return this.get('_ensureData').perform(model.game);
  },
  resetController(controller, isExiting, transition) {
    if (isExiting) {
      controller.set('reelName', '');
      let events = controller.get('sortedEvents');
      events.forEach(u=>{
        u.set('use', false);
      })
    }
  },
  _ensureData: task(function* (game) {
    let homeTeam = yield game.get('homeTeam');
    let awayTeam = yield game.get('awayTeam');
    let playerGames=yield this.store.query('bask-player-game', {
      orderBy: 'game',
      equalTo: game.id
    });
    
    let homePlayers = yield homeTeam.get('playerSeasons');
    let awayPlayers = yield awayTeam.get('playerSeasons');
    let promises = [];
    if (homePlayers) {
      homePlayers.forEach((ps)=>{
        let hpg = playerGames.findBy('playerSeason', ps.id);
        if (!hpg)
        {
          let pg = this.store.createRecord('bask-player-game', {
            account: ps.get('account.id'),
            game: game.id,
            player: ps.get('player.id'),
            playerSeason: ps.id
          });
          promises.push(pg.save());
        }
      });
    }
    if (awayPlayers) {
      awayPlayers.forEach((ps)=>{
        let apg = playerGames.findBy('playerSeason', ps.id);
        if (!apg)
        {
          let pg = this.store.createRecord('bask-player-game', {
            account: ps.get('account.id'),
            game: game.id,
            player: ps.get('player.id'),
            playerSeason: ps.id
          });
          promises.push(pg.save());
        }
      });
    }  
   
    return yield all(promises);
  }),
});
