define("stattracker-live/components/live-player-status", ["exports", "stattracker-shared/components/live-player-status"], function (_exports, _livePlayerStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _livePlayerStatus.default;
    }
  });
});
