import Controller from '@ember/controller';
import { computed,  } from '@ember/object';
import { task } from 'ember-concurrency';
import ENV from '../../config/environment';
import { inject as service } from '@ember/service';
/* global StripeCheckout */
export default Controller.extend({
  sortedPlans: computed('model.plans.[]', function() {
    let plans=this.get('model.plans');
    let ret=[];
    let breakdowns = plans.filterBy('type','breakdown').sortBy('price');
    breakdowns.forEach((o) => ret.push(o));
    let packages = plans.filterBy('type','breakdown package').sortBy('price');
    packages.forEach((o) => ret.push(o));
    return ret;
  }),

  canChangeSubscription: computed('session.account', function(){
    return this.get('session.account.canChangeSubscription');
  }),

  queryParams: [ 'destination'],
  firebaseApp: service(),
  intl: service(),
  session: service(),
  metrics: service(),

  actions:
  {
  checkout(plan) {
    this.get('_checkout').perform(plan);
    }
  },

  _checkout: task(function* (plan) {
    let destination=this.get('destination');
    let api=this.get('st-api');
    let auth=this.get('firebaseApp').auth();
    let authToken = yield auth.currentUser.getToken();
    let _account = this.get('session.account');
    let _planId = plan.get('id');
    let intl = this.get('intl');
    let _amount=plan.get('price')*100;
    let user = this.get('model.user');
    let _this=this;
    if (_account)
    {
      var handler = StripeCheckout.configure({
        key: ENV.stripe.key,
        image: '/../icon.png',
        locale: 'auto',
        token: function (token, other) { //eslint-disable-line no-unused-vars
          let body=
          {
            plan: _planId,
            stripeToken: token.id,
            account: _account.id,
            receiptEmail: user.get('email')
          };
          api.charge(body, 'prepaid', authToken).then(function(){
            if (destination){ 
              _this.transitionToRoute(destination);
            }
            else
            {
              _this.transitionToRoute('account');
            }
          });
          this.get('metrics').trackEvent('GoogleAnalytics', {
            category: 'billing',
            action: 'add breakdown',
            label: `Plan ${_planId}`
          });
          }
      });
      handler.open({
        name: intl.t('common.stLive').toString(),
        description: plan.get('description'),
        amount: _amount,
        shippingAddress: false,
        billingAddress: true,
        zipCode: true
      }); 
    }
  })
});
