import Ember from 'ember';
import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
const { inject } = Ember;
export default Route.extend({
  session: inject.service(),
  model() {
    return this.get('loadData').perform();
  },
  loadData: task(function* () {
    let downloads = yield this.store.query('download', {
      orderBy: 'team',
      equalTo: this.paramsFor('team')['bask-team_id']
    });
    
    return {
      downloads: downloads.toArray(),
      plan: this.get('session.plan'),
      orderBy: 'team',
      equalTo: this.paramsFor('team')['bask-team_id']
    };
  }),
  setupController(controller,model)
  {
    controller.set('plan', model.plan);
    controller.set('orderBy', model.orderBy);
    controller.set('equalTo', model.equalTo);
    this._super(controller, model);
  }
});