import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import Controller from '@ember/controller';
import $ from 'jquery';
import { inject as service } from '@ember/service';
export default Controller.extend({
  session: service(),
  _delete: task(function* () {
    let game = this.get('deletingGame');
    if (game) {
      yield game.destroyRecord();
    }
  }),
  actions: {
    delete() {
      this.get('_delete').perform();
    },
    confirmDelete(game) {
      $('.ui.modal').modal('show');
      this.set('deletingGame', game);
    },
    denyModal() {

    },
    toggleButtons(game) {
      game.toggleProperty('showingButtons');
    }
  },
  games: computed('model.{season,games.[]}', 'deletingGame', function()    {
    let season = this.get('model.season.id');
    let u = this.store.peekAll('bask-game').filterBy('season', season);
    let k = u.filter(function(item){
      let st=item.get('currentState.stateName');
      return (st && st.startsWith('root.loaded'));
    });
    return k.sortBy('sortableDate');
  })
});
