import Ember from 'ember';
const { Route } = Ember;
export default Route.extend({
  model() {
    return this.modelFor('team');
  },
  resetController(controller, isExiting, transition) {
    if (isExiting) {
      controller.set('firstName', '');
      controller.set('lastName', '');
      controller.set('number', '');
      controller.set('awayNumber', '');
      controller.set('year', '');
      controller.set('homeTown', '');
      controller.set('height', '');
      controller.set('weight', '');
      controller.set('email', '');
      controller.set('phone', '');
    }
  },
  actions: {
    create(firstName, lastName, number, awayNumber, year, homeTown, height, weight, email, phone) {
      let player = this.store.createRecord('bask-player', {
        firstName,
        lastName,
        number,
        awayNumber,
        year,
        homeTown,
        height,
        weight,
        email,
        phone
      });
      let  { team } = this.modelFor('team');
      player.set('team', team);
      team.get('players').addObject(player);
      player.save();
      team.save();
      this.transitionTo('team');
    },
    cancel() {
      this.transitionTo('team');
    }
  }
});
