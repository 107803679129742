import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import UploadMixin from '../../mixins/fb-upload';
import { task } from 'ember-concurrency';
export default Controller.extend(UploadMixin, {
  session: service(),
  queryParams: ['team'],
  team: null,
  actions: {
    save() {
      this.get('_save').perform();
    },
    cancel() {
      let player = this.get('model.playerSeason');
      player.rollbackAttributes();
      this.transitionToRoute('teamseason.active-players', this.get('model.teamSeason.id'));
    }
  },
  _save: task(function* () {
    let  playerSeason = this.get('model.playerSeason');
    let accountId = playerSeason.get('account');
    yield this.saveUploads(playerSeason, accountId);
    yield playerSeason.save();
    this.clear();
    this.transitionToRoute('teamseason.active-players', this.get('model.teamSeason.id'));
  })
});
