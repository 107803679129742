import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
export default Route.extend({
  model() {
    let model = this.modelFor('teamseason');
    return this.get('loadData').perform(model);
  },
  loadData: task(function* (model) {
    let reels = yield this.store.query('video', {
        orderBy: 'teamSeason',
        equalTo: model.teamSeasonId
      });
    return {
      reels
    };
  })
});
