import { task, all } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
export default Controller.extend({
  queryParams: ['playerId', 'teamSeasonId'],
  filter: 'baskTeam',
  filters: computed(function() {
    return [{
      id: 'baskTeam',
      name: 'Team'
    }, {
      id: 'baskSeason',
      name: 'Season'
    }, {
      id: 'all',
      name: 'All'
    }];
  }),
  isTeamSeason: computed('teamSeasonId', function(){
    return isPresent(this.get('teamSeasonId'));
  }),
  display: computed('model.all[]', 'filter', function() {
    let filter = this.get('filter');
    return this.get('filterTask').perform(filter)
  }),
  filterTask: task(function*(filter) {
    
    let allPlayers = yield this.get('model.all');
    const teamSeason = yield this.get('model.primary');
    const teamId = yield teamSeason.get('team.id');
    const season = yield this.get('model.season');
    const seasonId = season.get('id');
    let result=[];
    let length=yield allPlayers.get('length');
    for (var k=0; k<length ;k++)
    {
      let player=yield allPlayers.objectAt(k);
      if (yield teamSeason.isPlayingOnThisTeamThisSeason(player.id))
      {
        continue;
      }
      if (filter === 'baskSeason') {
        if (yield player.isPlayingThisSeason(seasonId))
        {
          result.push(player);
        }
      }
      else if (filter === 'baskTeam') {
        if (yield player.isOnThisTeam(teamId))
        {
          result.push(player);
        }
      }
      else { // handles all
        result.push(player);
      }
    }
    return result;
  }).restartable(),
  canSubmit: computed('add', function() {
    return this.get('add');
  }),
  actions: {
    submit() {
      let add = this.get('add');
      add = add.map((player) => {
        let playerseason = this.store.createRecord('bask-player-season');
        let ts = this.get('model.primary');
        this.get('addPlayerSeason').perform(ts, playerseason, player);
      });
      return all(add).then(() => this.transitionToRoute(this.get('returnRoute'), this.get('model.primary.id')));
    }

  },
  addPlayerSeason: task(function* (ts, playerseason, player) {
    let _t =  yield ts.get('team');
    _t.get('players').pushObject(player);
    yield _t.save();
    playerseason.set('teamSeason', ts);
    playerseason.set('player', player);
    playerseason.set('account', this.get('model.account.id'));
    playerseason.set('firstName', player.get('firstName'));
    playerseason.set('lastName', player.get('lastName'));
    playerseason.set('number', player.get('number'));
    playerseason.set('awayNumber', player.get('awayNumber'));
    playerseason.set('year', player.get('year'));
    playerseason.set('homeTown', player.get('homeTown'));
    playerseason.set('height', player.get('height'));
    playerseason.set('weight', player.get('weight'));
    playerseason.set('isStarter', player.get('isStarter'));
    playerseason.set('imageURL', player.get('imageURL'));
    playerseason.set('email', player.get('email'));
    playerseason.set('phone', player.get('phone'));
    playerseason.set('displayOrder', player.get('displayOrder'));
    playerseason.set('tags', player.get('tags'));
    yield playerseason.save();
    ts.get('playerSeasons').pushObject(playerseason);
    ts.get('players').pushObject(player);
    player.get('playerSeasons').pushObject(playerseason);
    yield player.get('teamSeasons').pushObject(ts);
    yield player.save();
    yield ts.save();
    yield playerseason.save();
  })
});
