import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import Controller from '@ember/controller';
import $ from 'jquery';
import { inject as service } from '@ember/service';
import emailVal from '../../mixins/email-validation';
/* global moment*/
export default Controller.extend(emailVal, {
  intl: service(),
  session: service(),
  metrics: service(),
  hasName: computed('model.name', function() {
    let name = this.get('model.name');
    return name && name.length > 0;
  }),
  hasEmail: computed('model.email', function() {
    let email = this.get('model.email');
    return this._checkEmail(email);
  }),
  locale: computed('model.cultureName', {
    get(key){ //eslint-disable-line no-unused-vars
      let cultureName = this.get('model.cultureName');
      if (!cultureName)
      {
        return 'en-us';
      }
      return cultureName;
    },
    set(key, value){
      this.set('model.cultureName', value);
    }
  }),
  timeZone: computed('model.timeZone', {
    get(key){ //eslint-disable-line no-unused-vars
      let timezone = this.get('model.timeZone');
      if (!timezone)
      {
        return moment.tz.guess();
      }
      return timezone;
    },
    set(key, value){
      this.set('model.timeZone', value);
    }
  }),
  slugCount: computed.alias('model.slugs.length'),
  canAddSlug: computed('session.isLeague', 'slugCount',  function() {
    let isLeague = this.get('session.isLeague');
    if (isLeague === true)
    {
      return this.get('slugCount')<=9;
    }
    return this.get('slugCount')<=2;
  }),
  canSave: computed('save.isRunning', 'hasName', 'hasEmail', function() {
    return !this.get('save.isRunning') && this.get('hasName') && this.get('hasEmail');
  }),
  languages: computed('intl.locales', function(){
    let intl=this.get('intl');
    let locales = intl.get('locales');
    let ret=[];
    locales.forEach((u)=> {
      ret.push({
        id: u,
        name: intl.t(`translations.${u}`)
      });
    });
    return ret;
  }),
  timezones: computed( function(){
    let zones=moment.tz.names()
    let ret=[];
    zones.forEach((u)=> {
      ret.push({
        id: u,
        name: u
      });
    });
    return ret;
  }),
  save: task(function* () {
    let account = this.get('model');
    if (account) {
      let rt=[];
      if (this.get('reportBuildPDF')) rt.push('pdf');
      if (this.get('reportBuildHTML')) rt.push('html');
      if (this.get('reportBuildXLSX')) rt.push('xlsx');
      if (this.get('reportBuildMaxPreps')) rt.push('maxpreps');
      account.set('reportTypes', rt.length>0?rt.join('|'):undefined);
      yield account.save();
    }
  }).drop(),
  actions: {
    denyModal() {

    },
    delete() {
      let slug = this.get('deletingSlug');
      let promise = slug.destroyRecord();
      this.get('metrics').trackEvent('GoogleAnalytics', {
        category: 'slug',
        action: 'delete'
      });
      this.set('deletionPromise', promise);
      return promise;
    },
    confirmDelete(slug) {
      $('.ui.modal').modal('show');
      this.set('deletingSlug', slug);
    },
  }
});
