import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import track from './track'
import { inject as service } from '@ember/service';
import ENV from '../../config/environment';
/* global moment */
export default track.extend({
  intl: service(),
  rate: 1,
  currentClipTime: 0,

  init () {
    this._super(...arguments);
    this.setup= {
      playbackRates: [0.5, 1, 1.1, 1.25, 1.5]
    };
    this.set('include720', true);
    this.set('include360', true);
  },

  homeOnLeft: true,
  leftColor: '#FFFFFF',
  rightColor: '#FFFFFF',
  queryParams: ['clipTime', 'playbackRate', 'destination', 'filter'],
  quality: computed('include4k', 'include1080', 'include720', 'include480', 'include360', function() {
    let ret=[];
    if (this.get('include4k')) { ret.push(4000);}
    if (this.get('include1080')) { ret.push(1080);}
    if (this.get('include720')) { ret.push(720);}
    if (this.get('include480')) { ret.push(480);}
    if (this.get('include360')) { ret.push(360);}
    return ret;
  }),
  actions: {
    ready(player, component) {
      // This is pretty much the minimum required for a useful video player.
      component.bindPropertyToPlayer(player, 'src');
      component.sendActionOnPlayerEvent(player, 'loadedmetadata');
      component.sendActionOnPlayerEvent(player, 'play');
      component.sendActionOnPlayerEvent(player, 'playing');
      component.sendActionOnPlayerEvent(player, 'pause');
      component.sendActionOnPlayerEvent(player, 'stalled');
      component.sendActionOnPlayerEvent(player, 'waiting');
      component.sendActionOnPlayerEvent(player, 'ratechange');
      component.sendActionOnPlayerEvent(player, 'timeupdate');
      this.set('player', player);
      
      let clipTime=this.get('clipTime');
      if (clipTime)
      {
        player.currentTime(clipTime);
      }
      let rate = this.get('playbackRate');
      if (rate)
      {
        player.defaultPlaybackRate(rate);
      }
    },
    play(player, component) { //eslint-disable-line no-unused-vars
      let clockPause = this.get('clockPause');
      if (clockPause)
      {
        let clockStart=this.get('clockStart');
        let game = this.get('model.game');
        let diff = clockPause.diff(clockStart);
        game.set('clockPause', undefined);
        game.set('clockStart', moment().subtract(diff, 'ms'));
        this.get('saveGame').perform(game);
      }
      let clockStart = this.get('clockStart');
      if (clockStart)
      {
        this.allowRateChange(false);
      }
    },
    playing(player, component) { //eslint-disable-line no-unused-vars
      console.log('playing');
    },
    pause(player, component) { //eslint-disable-line no-unused-vars
      let clockStart = this.get('clockStart');
      if (clockStart)
      {
        let game = this.get('model.game');
        game.set('clockPause', moment());
        this.set('clockPauseRate', this.get('rate'));
        this.get('saveGame').perform(game);
      }
      this.allowRateChange(true);
    },
    stalled(player, component) { //eslint-disable-line no-unused-vars
      if (this.get('clockStarted'))
      {
        console.log('stalled while clock running')
      }
      else {
        console.log('stalled');
      }
    },
    waiting(player, component) { //eslint-disable-line no-unused-vars
      if (this.get('clockStarted'))
      {
        console.log('waiting while clock running')
      }
      else {
        console.log('waiting');
      }
    },
    ratechange(player, component) { //eslint-disable-line no-unused-vars
      let rate=player.playbackRate();
      this.set('rate', rate);
    },
    loadedmetadata(player, component) { //eslint-disable-line no-unused-vars
      this.set('filmLength', player.duration());
    },
    seekClipStart(evt) {
      let player = this.get('player');
      let clipStart = evt.get('clipStart');

      let periodCurrent = evt.get('period');
      let periodSecondsLeft = evt.get('secondsRemaining');
      let game = this.get('model.game');
      if (player && clipStart !== undefined)
      {
        player.pause();
        player.currentTime(clipStart);
      }
      if (game && periodCurrent !== undefined && periodSecondsLeft !== undefined)
      {
        game.set('clockStart', undefined);
        game.set('clockPause', undefined);
        game.set('periodSecondsLeft', periodSecondsLeft);
        game.set('periodCurrent', periodCurrent);
        this.get('saveGame').perform(game);
      }
    },
    timeupdate(player, component) //eslint-disable-line no-unused-vars
    {
      this.set('currentClipTime', player.currentTime());
    },
    useAll(){
      let events=this.get('sortedEvents');
      let useAll=!this.get('useAll')
      events.forEach(u=>{
        u.set('use', useAll);
      });
      this.set('useAll', useAll);
    }
  },
  allowRateChange(allow)
  {
    let player=this.get('player');
    if (player)
    {
      if (allow)
      {
        player.controlBar.playbackRateMenuButton.enable();
      }
      else
      {
        player.controlBar.playbackRateMenuButton.disable();
      }
    }
  },
  canMakeReel: computed('makeReel.isRunning', 'reelName', function() {
    let reelName = this.get('reelName');
    return !this.get('makeReel.isRunning') && reelName && reelName.length>0;
  }),
  rawFilm: computed.alias('model.game.rawFilm'),
  rawFilmStatus: computed.alias('model.game.rawFilmStatus'),

  filmStatus: computed('rawFilmStatus', function() {
    let status=this.get('rawFilmStatus');
    let intl = this.get('intl');
    if (status) {
      return intl.t(`common.${status}`);
    }
    else
    {
      return null;
    }
  }),

  rawFilmAvailable: computed('rawFilm','rawFilmStatus', function() {
    let film = this.get('rawFilm');
    if (film)
    {
      return this.get('rawFilmStatus') === 'complete';
    }
    return false;
  }),

  videoTime: function() {
    let player = this.get('player');
    if (player)
    {
      return player.currentTime();
    }
    return undefined;
  },
  makeReel: task(function* (game){
    let api = this.get('st-api');
    let allSegments = [];
    let filmLength = this.get('filmLength');
    let halfDefaultClipLength = ENV.defaultClipLength/2;
    let events = this.get('sortedEvents').toArray();
    let reelName = this.get('reelName');
    let season = this.get('model.game.season');
    let source = game.get('fullFilm');
    let quality = this.get('quality');
    let homeTeam=yield game.get('homeTeam.name');
    let awayTeam=yield game.get('awayTeam.name');
    let gameName=`${homeTeam} vs ${awayTeam}`;
    for (let i = 0; i<events.length; i++) {
      let t= events[i];
      if (t.get('use'))
      {
        let playerNumber = yield t.get('playerObject.number');
        let playerName = yield t.get('playerObject.name');
        let description = t.get('description');
        let caption = `${playerNumber?'#'+playerNumber+' ':''}${playerName?playerName+'-':''}${description}`;
        let clipStart = t.get('clipStart');
        if (clipStart)
        {
          let clipStop = t.get('clipStop');
          if (!clipStop)
          {
            let clipMidpoint=clipStart;
            clipStart = (clipMidpoint - halfDefaultClipLength) > 0 ? clipMidpoint - halfDefaultClipLength : 0;
            clipStop = (clipMidpoint +  halfDefaultClipLength) <= filmLength ? clipMidpoint + halfDefaultClipLength : filmLength;
          }
          allSegments.push({
            clipStart,
            clipStop,
            source,
            caption
          });
        }
      }
    }
    allSegments.sort((current, next) => {
      if ((typeof current !== 'object') || (typeof next !== 'object')) {
        return -1;
      }
      if (current.clipStart < next.clipStart) {
        return -1;
      }
      if (current.clipStart > next.clipStart) {
        return 1;
      }
      return 0;
    });
    let segments = [];
    allSegments.reduce((accumulator, currentValue, currentIndex, incoming) =>{
      if (accumulator.length)
      {
        let prev=accumulator[accumulator.length-1];
        if (prev.clipStop > currentValue.clipStart)
        {
          prev.clipStop=currentValue.clipStart;
        }
      }
      accumulator.push(currentValue);
      return accumulator;
    }, segments);
    if (api && segments && segments.length)
    {
      var result = yield api.clipFilm({
        account: this.get('session.accountId'),
        game: game.get('id'),
        segments,
        season,
        reelName,
        description: gameName,
        quality
      });
      this.transitionToRoute('game');
    }
  }),

  saveGame: task(function* (game) {
    yield game.save();
  }),

  saveEvent: task(function* (evt) {
    yield evt.save();
  })
});
