import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
export default Route.extend({
  lazyload: service(),
  session: service(),
  beforeModel() {
    return this.get('lazyload').loadStripe();
  },
  model() {
    return this.get('loadData').perform();
  },
  loadData: task(function* () {
    let session = this.get('session');
    let user = yield this.store.find('user', session.get('userId'));
    let plans = yield this.store.query('plan', {});
    return {
      plans,
      user
    }
  }),
});