import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
export default Controller.extend({
  session: service(),
  _delete: task(function* (video) {
    if (video) {
      yield video.destroyRecord();
    }
  }),
  actions: {
    delete(video) {
      this.get('_delete').perform(video);
    },
  },
  reels: computed('model.reels', function() {
    let teamseason = this.get('model.teamSeason.id');
    let allReels = this.store.peekAll('video').filter(function(item){
      let st=item.get('teamSeason');
      return (st === teamseason);
    });    
    return allReels.sortBy('sortableDate');
  })
});
