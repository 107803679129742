import Ember from 'ember';

const { Route, inject: { service } } = Ember;
export default Route.extend({
  session: service(),
  model() {
    return this.get('session.user');
  },
  setupController(controller, model) {
    controller.set('oldEmail', model.get('email'));
    controller.set('useGravatar', model.get('useGravatar'));
    this._super(controller, model);
  }
});
