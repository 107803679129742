import UploadMixin from '../../mixins/fb-upload';
import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
import emailVal from '../../mixins/email-validation';
import { computed } from '@ember/object';
export default Controller.extend(UploadMixin, emailVal, {
  queryParams: ['team'],
  team: null,
  validEmail: computed('model.player.email', function() { //empty is valid
    let email = this.get('model.player.email');
    if (email && email.length > 0)
    { 
      return this._checkEmail(email);
    }
    return true;
  }),
  validNumber: computed('model.player.number', function() { //empty is valid
    let n = this.get('model.player.number');
    if (n && n.length <= 2 && n.length>0) {
      let parsed=Number.parseInt(n);
      if (Number.isNaN(parsed)) {
        return false;
      }
      return true;
    }
    return true;
  }),
  validAwayNumber: computed('model.player.awayNumber', function() { //empty is valid
    let n = this.get('model.player.awayNumber');
    if (n && n.length <= 2 && n.length>0) {
      let parsed=Number.parseInt(n);
      if (Number.isNaN(parsed)) {
        return false;
      }
      return true;
    }
    return true;
  }),
  canSave: computed('_save.isRunning', 'validEmail', 'validNumber', 'validAwayNumber', function() {
    return !this.get('_save.isRunning') && this.get('validEmail') && this.get('validNumber') && this.get('validAwayNumber');
  }),
  actions: {
    save() {
      this.get('_save').perform();
    },
    cancel() {
      let { player } = this.get('model');
      player.rollbackAttributes();
      if (this.get('team'))
      {
        this.transitionToRoute('team.players', this.get('team'));
      }
      else
      {
        this.transitionToRoute('player.visuals', player.id);
      }
    }
  },
  _save: task(function* () {
    let { player } = this.get('model');
    let team = this.get('team');
    let accountId = player.get('account');
    yield this.saveUploads(player, accountId);
    yield player.save();
    this.clear();
    if (team)
    {
      this.transitionToRoute('team.players', team);
    }
    else
    {
      this.transitionToRoute('player.visuals', player.id);
    }
  })
});
