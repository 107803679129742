import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
export default Route.extend({
  model(params) {
    let playerSeasonId = params['bask-player-season_id'];
    if (isPresent(playerSeasonId)) {
      return this.get('loadData').perform(playerSeasonId);
    }
  },
  loadData: task(function* (playerSeasonId) { //eslint-disable-line no-unused-vars
    let playerSeason = yield this.store.find('bask-player-season', playerSeasonId);
    let playerId = yield playerSeason.get('player.id');
    let account = yield playerSeason.get('account');
    let teamSeason = yield playerSeason.get('teamSeason');
    let playerSeasonReportTask = this.get('loadPlayerSeasonReport').perform(playerId);
    return {
      account,
      playerId,
      playerSeason,
      teamSeason,
      playerSeasonReportTask: {data:playerSeasonReportTask}
    }; 
  }),
  loadPlayerSeasonReport: task(function* (playerId) {
    return yield this.store.find('player-report', playerId ); 
  })
});