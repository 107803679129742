import Ember from 'ember';

const { Route, inject: { service }, isEmpty, RSVP: { hash:RSVPHash } } = Ember;
export default Route.extend({
  session: service(),

  model(params) {
    let teamseasonId = params.teamseason;
    if (!isEmpty(teamseasonId)) {
      return this.store.find('bask-team-season', teamseasonId).then((teamseason) => {
        return RSVPHash({
          account: this.get('session.account'),
          teamseason
        });
      });
    } else {
      return RSVPHash({
        account: this.get('session.account')
      });
    }
  },
  resetController(controller, isExiting, transition) {
    if (isExiting) {
      controller.set('queryParams', null);
      controller.set('season', null);
      controller.set('firstName', '');
      controller.set('lastName', '');
      controller.set('number', '');
      controller.set('awayNumber', '');
      controller.set('year', '');
      controller.set('homeTown', '');
      controller.set('height', '');
      controller.set('weight', '');
      controller.set('email', '');
      controller.set('phone', '');
    }
  },
  actions: {
    cancel() {
      let teamseason = this.get('model.teamseason');
      this.transitionTo('teamseason');
    }
  }
});
