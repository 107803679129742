import { A } from '@ember/array';
import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
export default Route.extend({
  model(params) {
    let timing = params.timing;
    let game = params.game;
    if (!isEmpty(timing) && !isEmpty(game)) {
      return this.get('loadData').perform(timing, game);
    }
  },

  loadData: task(function* (timingId, gameId) {
    let timing = yield this.store.findRecord('timing', timingId, { adapterOptions: { 'gameId' : gameId } });
    let game = yield this.store.find('bask-game', gameId);
    let periodCount = yield game.get('periodCount');
    let periodCurrent = yield game.get('periodCurrent');
    return {
      timing,
      game,
       periodCount,
      periodCurrent,
      originalClipStart: timing.get('clipStart'),
      originalClipStop: timing.get('clipStop')
    };
  })
});
