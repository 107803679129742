import Ember from 'ember';
import { task } from 'ember-concurrency';

const { Controller, inject, computed } = Ember;
export default Controller.extend({
  session: inject.service(),
  hasEmail: computed('email', function() {
    let email=this.get('email');
    return (email&&email.length > 5);
  }),
  canSave: computed('save.isRunning', 'hasEmail', function() {
    return !this.get('save.isRunning') && this.get('hasEmail');
  }),
  save: task(function* () {
    let store = this.get('store');
    let user=yield store.find('user',this.get('session.userId'));
    if (user) {
      user.set('email',this.get('email'));
      user.set('status',1);
      yield user.save();
      this.set('session.userStatus',1); //showWelcome;
      this.get('target._routerMicrolib').refresh();
    }
  }).drop(),
  actions: {
    denyModal() {

    }
  }
});
