import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { assert } from '@ember/debug';
import { computed } from '@ember/object';
export default Controller.extend({
  queryParams: ['inviteId', 'destination'],
  session: service(),
  intl: service(),
  fastboot: service(),
  firebaseApp: service(),
  loginMode: 1, // 1==login; 2==forgotPassword

  acceptInvitation: task(function* (inviteId, user) {
    let invite = yield this.store.find('invite', inviteId);
    let account = yield invite.get('account');
    let users = yield account.get('users');
    let accounts = yield user.get('accounts');
    users.pushObject(user);
    accounts.pushObject(account);
    yield account.save();
    yield user.save();
    yield invite.destroyRecord();
  }).drop(),

  login: task(function* (provider) {
    try {
      let email = this.get('email');
      let password = this.get('password');
      let inviteId = this.get('inviteId');

      let options = {
        provider,
        email,
        password,
        redirect: false
      };
      let session = this.get('session');
      yield session.authenticateUser(options);
      if (isPresent(inviteId)) {
        let user = yield this.store.find('user', session.get('userId'));
        assert('No current valid user in session data', user);
        yield this.get('acceptInvitation').perform(inviteId, user);
      }
    } catch (err) {
      this.set('_errorMessage', err.message || err);
      throw err;
    }
  }).drop(),

  isLoggingIn: computed.or('login.{isRunning,last.isSuccessful}'),
  errorMessage: computed('_errorMessage', 'session.error', function(){
    return this.get('_errorMessage') || this.get('session.error');
  }),
  actions: {
    resetPassword() {
      let _this=this;
      let email = this.get('email');
      let intl = this.get('intl');
      if (isPresent(email) && email.length > 5) {
        this.get('firebaseApp').auth().sendPasswordResetEmail(email).then(function() {
          _this.set('successMessage', `${intl.t('password.resetSent')} ${email}`);
        }).catch((err) => this.set('errorMessage', err.message || err));
      }
      else {
        this.set('_errorMessage', intl.t('password.emailRequired'));
      }
    }
  }
});
