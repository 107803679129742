import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
import { isEqual } from '@ember/utils';
import { A } from '@ember/array';
export default Controller.extend({
  queryParams: ['destination'],
  selectionCount: 0,
  actions: {
    selectTeam(team) {
      team.toggleProperty('isSelected');
      let isSelected = team.get('isSelected');
      let selectionCount = this.get('selectionCount');
      if (isSelected) {
        selectionCount = selectionCount + 1;
      } else {
        selectionCount = selectionCount - 1;
        if (selectionCount < 0) {
          selectionCount = 0;
        }
      }
      this.set('selectionCount', selectionCount);
    },
    onImport() {
      this.get('_import').perform();
    }
  },
  _import: task(function* () {
    let season = this.get('model.season');
    let account = this.get('model.account');
    let teams = this.get('model.seasonsTeams');
    let imports=A();
    this.get('model.teams').forEach(function(team) {
      if (team.isSelected) {
        let shouldSkip = false;
        teams.toArray().forEach(function(seasonTeam) {
          let teamId = seasonTeam.get('id');
          if (isEqual(teamId, team.id)) {
            shouldSkip = true;
          }
        });
        if (!shouldSkip) {
          imports.pushObject(team);
        }
      }
      team.set('isSelected', false);
    }, this);
    for (let i=0;i<imports.length;i++) {
      let team=imports[i];
      let newTeamSeason = this.store.createRecord('bask-team-season', {
        team,
        season,
        account: account.id
      });
      season.get('teamSeasons').pushObject(newTeamSeason);
      yield team.save();
      yield newTeamSeason.save();
    }
    yield season.save();

    let destination = this.get('destination');

    if (destination) {
      return this.transitionToRoute(destination);
    }
    this.transitionToRoute('season.teamseasons', season.get('id'));
  })
});
