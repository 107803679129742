import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
import $ from 'jquery';
import { inject as service } from '@ember/service';
import { all } from 'rsvp';
import { computed } from '@ember/object';
export default Controller.extend({
  session: service(),
  deleteAll: task(function* () {
    let promises=[];
    let videos = this.get('model.videos');
    videos.forEach((v)=>{
      promises.push(v.destroyRecord());
    });
    return yield all(promises);
  }).drop(),
  prepaidBreakdowns: computed('model.{plans,prepaid}', function () {
    let plans = this.get('model.plans').filterBy('type','breakdown').sortBy('monthPrice');
    let prepaid = this.get('model.prepaid');
    let _prepaid = prepaid.toArray();
    prepaid.forEach((i) => {
      let plan = plans.findBy('id', i.get('plan'));
      if (plan)
      {
        i.set('name', plan.get('name'))
        i.set('description', plan.get('description'));
      }
    });
    return _prepaid;
  }),
  actions: {
    denyModal() {

    },
    delete() {
      let video = this.get('deletingVideo');
      let promise = video.destroyRecord();
      this.set('deletionPromise', promise);
      return promise;
    },
    confirmDelete(video) {
      $('.ui.modal').modal('show');
      this.set('deletingVideo', video);
    },
  }
});
