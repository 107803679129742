import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import roster from '../../mixins/roster';
export default Controller.extend(roster, {
  queryParams: ['teamSeasonId'],
  actions: {
    finalize() {
      this.get('_finalize').perform();
    }
  },
  _finalize: task(function* () {
    let teamSeason = this.get('model.teamSeason');
    let team = this.get('model.team');
    let accountId = team.get('account.id');
    let entries = this.get('rosterEntries');
    let players = team.get('players');
    let playerSeasons = teamSeason.get('playerSeasons');
    for (let i = 0; i < entries.length; i++ ) {
      let ps = entries[i];
      let playerSeason = this.store.createRecord('bask-player-season');
      let player = this.store.createRecord('bask-player');
      this.shimPlayer(ps, accountId, team, player, playerSeason, teamSeason);
      this.shimPlayerSeason(ps, accountId, teamSeason, player, playerSeason);
      yield player.save();
      yield playerSeason.save();
      players.pushObject(player);
      playerSeasons.pushObject(playerSeason);
    }
    yield teamSeason.save();
    yield team.save();
    this.set('rosterEntries',[]);
    this.transitionToRoute('teamseason.active-players',this.get('model.teamSeason'));
  })
});
