import Ember from 'ember';

const { Route, inject: { service }, RSVP: { hash: RSVPHash } } = Ember;
export default Route.extend({
  session: service(),
  model() {
    let model = this.modelFor('team');
    return RSVPHash({
      possibleSeasons: this.store.query('bask-season', {
        orderBy: 'account',
        equalTo: this.get('session.accountId')
      }),
      removeSeasons: model.teamSeasons.getEach('season')
    }).then((hash) => {
      model.seasons = seasons;
      return model;
    });
  },

  setupController(controller, model) {
    controller.set('actions', {
      changeSeason(args) {
        debugger;
      }
    });
    this._super(controller, model);
  },

  actions: {
    create(seasonName) {
      let player = this.store.createRecord('bask-player', {
        name,
        number,
        awayNumber,
        year,
        homeTown,
        height,
        weight,
        email,
        phone
      });
      let { team } = this.modelFor('team');
      player.set('team', team);
      team.get('players').addObject(player);
      player.save();
      team.save();
      this.transitionTo('team');
    },
    cancel() {
      this.controller.setProperties({
        name: '',
        number: '',
        awayNumber: '',
        year: '',
        homeTown: '',
        height: '',
        weight: '',
        email: '',
        phone: ''
      });
      this.transitionTo('team');
    }
  }
});
