import ENV from '../../config/environment';
import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
/* global moment*/
export default Controller.extend({
  session: service(),
  metrics: service(),
  _revokeAccess: task(function* (accountProp, user) {
    let account = this.get('model.account');
    let adminAccounts = user.get('adminAccounts');
    let activeAccount = user.get('activeAccount');
    if (activeAccount === account.get('id')) {
      if (adminAccounts && adminAccounts.length) {
        let firstAdminAccount = adminAccounts.objectAt(0);
        user.set('activeAccount', firstAdminAccount.get('id'));
      } else {
        user.set('activeAccount', undefined);
      }
    }
    let users = account.get('users');
    if (users) {
      users.removeObject(user);
    }
    let accounts = user.get(accountProp);
    if (accounts) {
      accounts.removeObject(account);
    }
    yield user.save();
    yield account.save();
  }),
  _removeInvite: task(function* (invite) {
    yield invite.destroyRecord();
    yield this.get('model.account').save();
    this.get('metrics').trackEvent('GoogleAnalytics', {
      category: 'account',
      action: 'remove invite'
    });
  }),
  _sendInvite: task(function* (email, resend) {
    if (isPresent(email)) {
      let account = this.get('model.account');
      let inviteId = undefined;
      if (resend)
      {
        inviteId = resend.get('id');
        resend.set('expiration',moment().add(ENV.APP.inviteDurationAmount, ENV.APP.inviteDurationUnit));
        yield resend.save();
      }
      else
      {
        let invite = this.store.createRecord('invite', {
          inviterName: this.get('model.user.name'),
          expiration: moment().add(ENV.APP.inviteDurationAmount, ENV.APP.inviteDurationUnit),
          email,
          account
        });
        yield invite.save();
        inviteId = invite.get('id');
        let invites = account.get('invites');
        invites.pushObject(invite);
        yield account.save();
      }
      
      
      let params = {
        inviterName: this.get('model.user.name'),
        account: account.get('id'),
        invite: inviteId,
        email,
        type: 'invite'
      };
      let api = this.get('st-api');
      if (api) {
        yield api.send(params);
        this.get('metrics').trackEvent('GoogleAnalytics', {
          category: 'account',
          action: 'send invite'
        });
      }
    }
  }),
  _promoteUser: task(function* (user) {
    let account = this.get('model.account');
    user.get('accounts').removeObject(account);
    user.get('adminAccounts').addObject(account);
    yield user.save();
    yield account.save();
    this.get('metrics').trackEvent('GoogleAnalytics', {
      category: 'account',
      action: 'promote user'
    });
  }),
  _demoteAdmin: task(function* (admin) {
    let account = this.get('model.account');
    admin.get('adminAccounts').removeObject(account);
    admin.get('accounts').addObject(account);
    yield admin.save();
    yield account.save();
    this.get('metrics').trackEvent('GoogleAnalytics', {
      category: 'account',
      action: 'demote admin'
    });
  }),
  actions: {
    sendInvite(email, resend) {
      this.get('_sendInvite').perform(email, resend);
    },

    removeInvite(invite) {
      this.get('_removeInvite').perform(invite);
    },

    revokeAccess(accountProp, user) {
      this.get('_revokeAccess').perform(accountProp, user);
    },

    demoteAdmin(admin) {
      this.get('_demoteAdmin').perform(admin);
    },

    promoteUser(user) {
      this.get('_promoteUser').perform(user);
    }
  }
});
