import Ember from 'ember';
import { task } from 'ember-concurrency';
const { Route, inject, isPresent } = Ember;
export default Route.extend({
  session: inject.service(),
  model(params) {
    if (isPresent(params['bask-team_id'])) {
      return this.get('loadModel', params['bask-team_id']);
    }
  },

  loadModel: task(function* (teamId) {
    let account = this.get('session.account');
    let team = yield this.store.find('bask-team', teamId);
    let players = yield team.get('players');
    let teams = yield account.get('teams');
    return {
      account,
      team,
      teams,
      players
    };
  })
});
