
import { task, timeout } from 'ember-concurrency';
import ENV from '../../config/environment';
const DEBOUNCE_MS = 250;
import { isBlank } from '@ember/utils';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
export default Controller.extend({
  isValid: false,
  actions: {
    save() {
      this.get('saveTask').perform();
    },
  },
  canSave: computed('saveTask.isRunning', 'isValid', 'add',  function() {
    return !this.get('saveTask.isRunning') && this.get('isValid') && this.get('add');
  }),
  saveTask: task(function* () {
    let slug = undefined;
    let account = undefined;
    try {
      let store = this.get('store');
      account = this.get('model.account');
      slug = yield this.get('model.slug');
      let _slug = this.get('slug');
      let desiredSlug = yield store.peekRecord('slug', _slug);
      if (desiredSlug)
      {
        if (desiredSlug.get('isValid'))
        {
          var slugAccountId = yield desiredSlug.get('account.id');
          if (slugAccountId !== account.get('id')) {
            account = undefined; slug = undefined;
            return;
          }
        }
        else
        {
          yield store.recordForId('slug', _slug).unloadRecord(); // remove the unresolved record
        }
      }
      if (slug) {
        if (slug.get('id') !== _slug) {
          yield slug.destroyRecord();
          slug = this.store.createRecord('slug', {
            account,
            id: _slug
          });
        }
      }
      else {
        slug = yield this.store.createRecord('slug', {
          account,
          id: _slug
        });
      }
    } catch (e) { //eslint-disable-line
      console.log(e.message || e); //eslint-disable-line
    } finally {
      if (account && slug) {
        var add = this.get('add');
        if (add) {
          var plan = account.get('plan');
          if (plan === 'team') {
            var teamSeasonName = yield add.get('name');
            slug.set('teamSeason', add.get('id'));
            slug.set('season', add.get('season.id'));
            slug.set('reference', teamSeasonName);
          }
          else if (plan === 'league') {
            slug.set('season', add.get('id'));
            slug.set('reference', add.get('name'));
          }
          slug.set('plan', plan);
        }
        slug.set('displayName', this.get('displayName'))
        slug.set('publicUrl', this.get('publicUrl'));
        account.get('slugs').addObject(slug);
        yield account.save();
        yield slug.save();
        this.get('metrics').trackEvent('GoogleAnalytics', {
          category: 'slugs',
          action: 'add'
        });
        this.transitionToRoute('public');
      }
      else {
        this.transitionToRoute('public');
      }
    }
  }).drop(),
  checkSlug: task(function* (term) {
    if (isBlank(term)) {
      this.set('publicUrl', "");
      this.set('isValid', false);
      return;
    }
    else if (term.toLowerCase() === 'sitemap')
    {
      this.set('publicUrl', "Not a valid page");
      this.set('isValid', false);
      return;
    }

    // Pause here for DEBOUNCE_MS milliseconds. Because this
    // task is `restartable`, if the user starts typing again,
    // the current search will be canceled at this point and
    // start over from the beginning. This is the
    // ember-concurrency way of debouncing a task.
    yield timeout(DEBOUNCE_MS);
    var slug = this.generate(term);
    this.set('displayName', term);
    var plan = this.get('plan');
    try {
      let desiredSlug = yield this.store.findRecord('slug', slug);
      if (desiredSlug) {
        if (desiredSlug.get('account.id') === this.get('model.id') || desiredSlug.get('currentState.stateName') === 'root.deleted.saved') {
          this.set('publicUrl', this.publicURL(plan, slug));
          this.set('isValid', true);
        }
        else {
          this.set('publicUrl', "Already in use");
          this.set('isValid', false);
        }
      }
    } catch(e) {
      this.set('publicUrl', this.publicURL(plan, slug));
      this.set('isValid', true);
    } finally {
      this.set('slug', slug);
    }
  }).restartable(),
  publicURL: function publicURL(plan, slug) {
    var url = ENV.publicSiteURL;
    return `${url}/${slug}`;
  },
  generate: function generate() {
    const _opts = {
      maxLen: 100,                   // truncates beyond maxLen
      lowercaseOnly: true,
      regexRemovePattern: /((?!([a-z0-9])).)/gi, // matches opposite of [a-z0-9]
      joinString: '-',                   // e.g. - may be: '-', '_', '#'
      trimWhitespace: true
    };
    const reJoinString = new RegExp(_opts.joinString + '+', 'g'),
      args = Array.prototype.slice.call(arguments);

    let tag;

    if (!args || args.length === 0)
      throw new Error('generate method must be passed at least one argument');

    // Validate, trim all arguments:
    for (let i = 0; i < args.length; i++) {
      if (typeof args[i] !== 'string')
        throw new Error('all supplied arguments must be Strings');

      if (_opts.trimWhitespace)
        args[i] = args[i].trim();
    }

    // Join strings and convert whitespace between words to join string
    tag = args.join(_opts.joinString);
    tag = tag.replace(/\s/g, _opts.joinString);
    if (_opts.lowercaseOnly)
      tag = tag.toLowerCase();
    // Regex away anything "unsafe", but ignore the join string!
    tag = tag.replace(_opts.regexRemovePattern, function (match) {
      if (match === _opts.joinString)
        return match;

      return '';
    });

    // Truncate in excess of maxLen
    if (tag.length > _opts.maxLen)
      tag = tag.substring(0, _opts.maxLen);

    // Remove any duplicates of the join string using this pattern: /<join string>+/g
    tag = tag.replace(reJoinString, _opts.joinString);

    return tag;
  },
  session: service(),
  metrics: service()
});