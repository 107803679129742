import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';

export default Route.extend({
  lazyload: service(),
  beforeModel() {
    return this.get('lazyload').load(['stripe','grid','video']);
  },
  model(params) {
    let gameId = params['bask-game_id'];
    if (isPresent(gameId)) {
      return this.store.find('bask-game', gameId);
    }
  },
  actions: {
    trackGame(game, destination)
    {
      let qp = undefined;
      if (destination) {
        qp = {
          queryParams:{
            destination
          }
        };
      }
      
      qp ? this.transitionTo('game.track',game.id, qp) : this.transitionTo('game.track',game.id);
    },
    breakdownGame(game, destination)
    {
      let qp = undefined;
      if (destination) {
        qp = {
          queryParams:{
            destination
          }
        };
      }
     qp ? this.transitionTo('game.breakdown',game.id, qp) : this.transitionTo('game.breakdown',game.id);
  
    }
  }
});
