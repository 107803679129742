import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
export default Route.extend({
  session: service(),
  model(params) {
    let teamSeasonId = params.teamseason_id;
    if (!isEmpty(teamSeasonId)) {
      return this.get('loadData').perform(teamSeasonId);
    }
  },

  loadData: task(function* (teamSeasonId) {
    let teamSeason = yield this.store.find('bask-team-season', teamSeasonId);
    let season = yield teamSeason.get('season');
    let teamReportTask = this.get('loadTeamReport').perform(teamSeasonId, season.id);
    let team = yield teamSeason.get('team');
    let homeGames = yield teamSeason.get('homeGames');
    let awayGames = yield teamSeason.get('awayGames');
    let players = yield teamSeason.get('playerSeasons');
    return {
      teamSeasonId,
      teamSeason,
      teamReportTask: {data:teamReportTask},
      season,
      team,
      homeGames,
      awayGames,
      players
    };
  }),
  loadTeamReport: task(function* (teamSeasonId, seasonId) {
    return yield this.store.findRecord('team-report', teamSeasonId, { adapterOptions: { seasonId } });
  })
});
