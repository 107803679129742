import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import Controller from '@ember/controller';
import $ from 'jquery';
import { inject as service } from '@ember/service';
export default Controller.extend( {
  session: service(),
  metrics: service(),
  slugCount: computed.alias('model.slugs.length'),
  canAddSlug: computed('session.isLeague', 'slugCount',  function() {
    let isLeague = this.get('session.isLeague');
    if (isLeague === true)
    {
      return this.get('slugCount')<=9;
    }
    return this.get('slugCount')<=2;
  }),
  save: task(function* () {
    let account = this.get('model');
    if (account) {
      yield account.save();
    }
  }).drop(),
  actions: {
    denyModal() {

    },
    delete() {
      let slug = this.get('deletingSlug');
      let promise = slug.destroyRecord();
      this.get('metrics').trackEvent('GoogleAnalytics', {
        category: 'slug',
        action: 'delete'
      });
      this.set('deletionPromise', promise);
      return promise;
    },
    confirmDelete(slug) {
      $('.ui.modal').modal('show');
      this.set('deletingSlug', slug);
    },
  }
});
