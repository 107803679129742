import track from './track'
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import { bind } from '@ember/runloop';
import { inject as service } from '@ember/service';
/* global moment */
export default track.extend({
  intl: service(),
  rate: 1,
  currentClipTime: 0,

  init () {
    this._super(...arguments);
    this.setup= {
      playbackRates: [0.5, 1, 1.1, 1.25, 1.5]
    };
  },

  homeOnLeft: true,
  leftColor: '#FFFFFF',
  rightColor: '#FFFFFF',
  queryParams: ['clipTime', 'playbackRate', 'destination', 'filter'],

  actions: {
    ready(player, component) {
      // This is pretty much the minimum required for a useful video player.
      component.bindPropertyToPlayer(player, 'src');
      component.sendActionOnPlayerEvent(player, 'loadedmetadata');
      component.sendActionOnPlayerEvent(player, 'play');
      component.sendActionOnPlayerEvent(player, 'playing');
      component.sendActionOnPlayerEvent(player, 'pause');
      component.sendActionOnPlayerEvent(player, 'stalled');
      component.sendActionOnPlayerEvent(player, 'waiting');
      component.sendActionOnPlayerEvent(player, 'ratechange');
      component.sendActionOnPlayerEvent(player, 'timeupdate');
      this.set('player', player);
      
      let clipTime=this.get('clipTime');
      if (clipTime)
      {
        player.currentTime(clipTime);
      }
      let rate = this.get('playbackRate');
      if (rate)
      {
        player.defaultPlaybackRate(rate);
      }
    },
    play(player, component) { //eslint-disable-line no-unused-vars
      let clockPause = this.get('clockPause');
      if (clockPause)
      {
        let clockStart=this.get('clockStart');
        let game = this.get('model.game');
        let diff = clockPause.diff(clockStart);
        game.set('clockPause', undefined);
        game.set('clockStart', moment().subtract(diff, 'ms'));
        this.get('saveGame').perform(game);
      }
      let clockStart = this.get('clockStart');
      if (clockStart)
      {
        this.allowRateChange(false);
      }
    },
    deleteTiming(timing) {
      this.get('_deleteTiming').perform(timing);
    },
    playing(player, component) { //eslint-disable-line no-unused-vars
      console.log('playing');
    },
    pause(player, component) { //eslint-disable-line no-unused-vars
      let clockStart = this.get('clockStart');
      if (clockStart)
      {
        let game = this.get('model.game');
        game.set('clockPause', moment());
        this.set('clockPauseRate', this.get('rate'));
        this.get('saveGame').perform(game);
      }
      this.allowRateChange(true);
    },
    stalled(player, component) { //eslint-disable-line no-unused-vars
      if (this.get('clockStarted'))
      {
        console.log('stalled while clock running')
      }
      else {
        console.log('stalled');
      }
    },
    waiting(player, component) { //eslint-disable-line no-unused-vars
      if (this.get('clockStarted'))
      {
        console.log('waiting while clock running')
      }
      else {
        console.log('waiting');
      }
    },
    ratechange(player, component) { //eslint-disable-line no-unused-vars
      let rate=player.playbackRate();
      this.set('rate', rate);
    },
    loadedmetadata(player, component) { //eslint-disable-line no-unused-vars
      this.set('filmLength', player.duration());
    },
    seekClipStart(evt) {
      let player = this.get('player');
      let clipStart = evt.get('clipStart');

      let periodCurrent = evt.get('period');
      let periodSecondsLeft = evt.get('secondsRemaining');
      let game = this.get('model.game');
      if (player && clipStart !== undefined)
      {
        player.pause();
        player.currentTime(clipStart);
      }
      if (game && periodCurrent !== undefined && periodSecondsLeft !== undefined)
      {
        game.set('clockStart', undefined);
        game.set('clockPause', undefined);
        game.set('periodSecondsLeft', periodSecondsLeft);
        game.set('periodCurrent', periodCurrent);
        this.get('saveGame').perform(game);
      }
    },
    setEventTime(evt) {
      let player = this.get('player');
      let game = this.get('model.game');
      let periodSecondsLeft = game.get('periodSecondsLeft');
      let periodCurrent = game.get('periodCurrent');
      if (player)
      {
        let clipStart=player.currentTime();
        evt.set('clipStart', clipStart);
        evt.set('secondsRemaining', periodSecondsLeft);
        evt.set('periodCurrent', periodCurrent);
        this.get('saveEvent').perform(evt);
      }
    },
    timeupdate(player, component) //eslint-disable-line no-unused-vars
    {
      this.set('currentClipTime', player.currentTime());
    }
  },

  clock: function(start) {
    let player=this.get('player');
    if (player && start){
      player.play();
    }
  },

  allowRateChange(allow)
  {
    let player=this.get('player');
    if (player)
    {
      if (allow)
      {
        player.controlBar.playbackRateMenuButton.enable();
      }
      else
      {
        player.controlBar.playbackRateMenuButton.disable();
      }
    }
  },

  toggleCourtEndsCallback: computed(function(){
    return bind(this, function () {
      let homeOnLeft=!this.get('homeOnLeft');
      if (homeOnLeft)
      {
        this.set('leftColor', this.get('homeTeamEntry.homeColor'));
        this.set('rightColor', this.get('awayTeamEntry.awayColor'));
      }
      else
      {
        this.set('rightColor', this.get('homeTeamEntry.homeColor'));
        this.set('leftColor', this.get('awayTeamEntry.awayColor'));
      }
      this.set('homeOnLeft', homeOnLeft);
    });
  }),

  finalizeCallback: computed(function(gameId){ //eslint-disable-line no-unused-vars
    return bind(this, function (gameId) {
      this.transitionToRoute('game.finalize',gameId);
    });
  }),

  timingClickCallback: computed(function (timing) { //eslint-disable-line no-unused-vars
    return bind(this, function (timing) {
      if (timing)
      {
        this.set('selectedTimelineTiming',timing);
        let player = this.get('player');
        let clipStop = timing.get('clipStop');
        let periodCurrent = timing.get('period');
        let periodSecondsLeft = timing.get('secondsRemainingStop');
        let game = this.get('model.game');
        if (player && clipStop !== undefined)
        {
          player.pause();
          player.currentTime(clipStop);
        }
        if (game && periodCurrent !== undefined && periodSecondsLeft !== undefined)
        {
          game.set('clockStart', undefined);
          game.set('clockPause', undefined);
          game.set('periodSecondsLeft', periodSecondsLeft);
          game.set('periodCurrent', periodCurrent);
          this.get('saveGame').perform(game);
        }
      }
      else
      {
        this.set('selectedTimelineTiming',undefined);
      }
    });
  }),

  rawFilm: computed.alias('model.game.rawFilm'),
  rawFilmStatus: computed.alias('model.game.rawFilmStatus'),

  filmStatus: computed('rawFilmStatus', function() {
    let status=this.get('rawFilmStatus');
    let intl = this.get('intl');
    if (status) {
      return intl.t(`common.${status}`);
    }
    else
    {
      return null;
    }
  }),

  rawFilmAvailable: computed('rawFilm','rawFilmStatus', function() {
    let film = this.get('rawFilm');
    if (film)
    {
      return this.get('rawFilmStatus') === 'complete';
    }
    return false;
  }),

  videoTime: function() {
    let player = this.get('player');
    if (player)
    {
      return player.currentTime();
    }
    return undefined;
  },

  saveGame: task(function* (game) {
    yield game.save();
  }),

  saveEvent: task(function* (evt) {
    yield evt.save();
  }),

  _deleteTiming: task(function* (timing) {
    if (!timing.get('deleting')) {
      timing.set('deleting', true);
      let eventLedger = this.get('model.eventLedger');
      let timings = eventLedger.get('timings');
      let game = this.get('model.game');
      let saveGame = false;
      if (game)
      {
        if (game.get('isScheduled'))
        {
          game.set('status', 'live');
          saveGame = true;
        }
        else if (game.get('isFinished'))
        {
          game.set('status', 'edited');
          saveGame = true;
        }
      }
      
      if (saveGame)
      {
        yield game.save();
      }

      if (timings && eventLedger) {
        timings.removeObject(timing);
        eventLedger.set('status', `${timing.id}`);
        yield eventLedger.save();
        yield timing.destroyRecord();
      }
    }
  })
});
