import groupBy from 'stattracker-shared/utils/group-by';
import mapValues from 'stattracker-shared/utils/mapValues';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  session: service(),
  firebaseApp: service(),
  model(params) {
    let api = this.get('st-api');
    let session = this.get('session');
    let _this = this;
    if (api) {
      return api.lookup(params).then(function (result) {
        if (session && result && result.season && result.customToken) {
          if (!session.get('isAuthenticated')) {
            let auth = _this.get('firebaseApp').auth();
            return auth.signInWithCustomToken(result.customToken).then(function(authResult){
              session.setActiveReference(result.account, result.season, result.owner, 'league', params.slug);

              return RSVPHash({
                activeSeason: result.season,
                games: _this.store.query('bask-game', {
                  orderBy: 'season',
                  equalTo: result.season
                }),
                standings: _this.store.query('bask-standing', {
                  orderBy: 'season',
                  equalTo: result.season
                })
              }).then((hash) => {
                hash.standings = hash.standings.toArray().map((d) => JSON.parse(JSON.stringify(d)));
                // hash.games = hash.games.toArray().sort((a, b) => {
                //   return b.get('date').valueOf() - a.get('date').valueOf();
                // });
                hash.standings = _this.groupStandingsByLeagueAndDivision(hash.standings);
                // hash.displayGames = hash.games.rejectBy('status', 'scheduled').rejectBy('status', 'canceled');
                // hash.displayGames = groupBy(hash.displayGames, (u) => u.get('status'));
                // hash.displayGames.finished = groupBy(hash.displayGames.finished, (g) => g.get('date').format('MMM Mo'));
                // hash.scheduledGames = hash.games.filterBy('status', 'scheduled');
                return hash;
              }).catch((err) => {
                console.log(err);
              });
            })
            .catch(function (error) {
              console.log(error);
            });
          }

          
        } else {
          _this.transitionTo('index');
        }
      });
    }
  },
  groupStandingsByLeagueAndDivision(standings) {
    let noLeague = standings.filter((d) => isEmpty(d.league));
    noLeague.setEach('league', 'No League');
    let noDivision = standings.filter((d) => isEmpty(d.division));
    noDivision.setEach('division', 'No Division');
    standings = groupBy(standings, 'league');
    standings = mapValues(standings, (value) => groupBy(value, 'division'));
    return standings;
  }
});

