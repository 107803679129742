import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
export default Route.extend({
    lazyload: service(),
    beforeModel() {
      return this.get('lazyload').loadGrid();
    },
    model() {
      let game = this.modelFor('game');
      return this.get('loadData').perform(game);
    },
    loadData: task(function* (game) {
      let homeTeamSeason = yield game.get('homeTeam');
      let awayTeamSeason = yield game.get('awayTeam');
      let homeTeam = homeTeamSeason.get('team');
      let awayTeam = awayTeamSeason.get('team');
      let playerGames= yield this.store.query('bask-player-game', {
        orderBy: 'game',
        equalTo: game.id
      });
      let homeTeamGame = game.get('homeTeamGame');
      let awayTeamGame = game.get('awayTeamGame');
      let homeScore = game.get('homeScore');
      let awayScore = game.get('awayScore');
      let homePlayers = yield homeTeamSeason.get('players');
      let awayPlayers = yield awayTeamSeason.get('players');
      let homePlayerSeasons = yield homeTeamSeason.get('playerSeasons');
      let awayPlayerSeasons = yield awayTeamSeason.get('playerSeasons');
      let currentPlayerGames = playerGames.mapBy('player');
      let awayPlayerGames=[];
      awayPlayers.forEach((player)=>{
        var playerGame={};
        if (currentPlayerGames.includes(player.id))
        {
            var pg=playerGames.findBy('player',player.id);
            if (pg)
            {
                this.shimPlayerGame(playerGame,pg);
                playerGame.Name=player.get('name');
                playerGame.number=player.get('number');
                playerGame.account=game.get('account');
            }
        }
        else
        {
            
            playerGame.Name=player.get('name');
            playerGame.number=player.get('number');
            playerGame.player=player.get('id');
            playerGame.game=game.get('id');
            playerGame.account=game.get('account');
            playerGame.PTS=0;
            playerGame.REB=0;
        }
        awayPlayerGames.push(playerGame);
      });
            
      let homePlayerGames=[];
      homePlayers.forEach((player)=>{
        var playerGame={};
        if (currentPlayerGames.includes(player.id))
        {
            var pg=playerGames.findBy('player',player.id);
            if (pg)
            {
                this.shimPlayerGame(playerGame,pg);
                playerGame.Name=player.get('name');
                playerGame.number=player.get('number');
                playerGame.account=game.get('account');
            }
        }
        else
        {
            playerGame.Name=player.get('name');
            playerGame.number=player.get('number');
            playerGame.player=player.get('id');
            playerGame.game=game.get('id');
            playerGame.account=game.get('account');
            playerGame.PTS=0;
            playerGame.REB=0;
        }
        homePlayerGames.push(playerGame);
      });
      return {
        homePlayerGames,
        awayPlayerGames,
        homePlayerSeasons,
        awayPlayerSeasons,
        homeScore,
        awayScore,
        homeTeamGame,
        awayTeamGame,
        awayTeam,
        homeTeam,
        awayTeamSeason,
        homeTeamSeason,
        game,
        playerGames
      };         
  }),
  shimPlayerGame: function(obj,embObj)
  {
    obj.id=embObj.get('id');
    obj.account=embObj.get('account');
    obj.game=embObj.get('game');
    obj.player=embObj.get('player');
    obj.playerSeason=embObj.get('playerSeason');
    obj.PM = embObj.get('plusMinus');
    obj.PTS=embObj.get('points');
    obj.POT=embObj.get('pointsOffTurnovers');
    obj.SCP=embObj.get('secondChancePoints');
    obj.FBP=embObj.get('fastbreakPoints');
    obj.PF=embObj.get('fouls');
    obj.PFR=embObj.get('foulsReceived');
    obj.FT=embObj.get('ftm');
    obj.FTA=embObj.get('fta');
    obj["3FGA"]=embObj.get('_3pa');
    obj["3FG"]=embObj.get('_3pm');
    obj["2FGA"]=embObj.get('_2pa');
    obj["2FG"]=embObj.get('_2pm');
    obj.FGA=embObj.get('_3pa')+embObj.get('_2pa');
    obj.FG=embObj.get('_3pm')+embObj.get('_2pm');
    obj.TO=embObj.get('turnovers');
    obj.AST=embObj.get('assists');
    obj.STL=embObj.get('steals');
    obj.BLK=embObj.get('blocks');
    obj.BLKR=embObj.get('blocksReceived');
    obj.DEFL=embObj.get('deflections');
    obj.CD=embObj.get('chargesTaken');
    obj.OFF=embObj.get('offensiveRebounds');
    obj.DEF=embObj.get('defensiveRebounds');
    obj.REB=obj.OFF+obj.DEF;
    obj.MIN=embObj.get('secondsPlayed') ? Math.ceil(embObj.get('secondsPlayed')/60):0;
    obj.played=embObj.get('played');
  }
});
