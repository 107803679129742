import { computed } from '@ember/object';
import Controller from '@ember/controller';
import $ from 'jquery';
import { inject as service } from '@ember/service';
export default Controller.extend({
  session: service(),
  validSeasons: computed.filterBy('seasons','isValid',true),
  seasons: computed('model.seasons', 'session.activeSeasonId', function() {
    let seasons = this.get('model.seasons');
    let _seasonId = this.get('session.activeSeasonId');
    let activeSeason = seasons.findBy('id', _seasonId);
    if (activeSeason) {
      activeSeason.set('isActive', true);
    }
    seasons.forEach((p) => {
      let _id = p.get('id');
      if (_id !== _seasonId) {
        p.set('isActive', false);
      }
    });
    return seasons;
  }),
  actions: {
    delete() {
      let season = this.get('deletingSeason');
      return season.destroyRecord();
    },
    makeActive(season) {
      let seasons = this.get('model.seasons');
      let session = this.get('session');
      let seasonId = session.get('activeSeasonId');
      let activeSeason = seasons.findBy('id', season.id);
      let as = seasons.findBy('id', seasonId);
      let account = this.get('model.account');
      if (as) {
        as.set('isActive', false);
        as.save().then(() => {
          seasons.forEach((p) => {
            let _id = p.get('id');
            if (_id !== seasonId) {
              p.set('isActive', false);
            }
          });
          if (account) {
            session.setActiveSeasonId(season.id);
            account.set('activeSeason', season.id);
            activeSeason.set('isActive', true);
            activeSeason.save();
            account.save();
          }
        });
      }
      else
      {
        seasons.forEach((p) => {
          p.set('isActive', false); 
        });
        if (account) {
          session.setActiveSeasonId(season.id);
          account.set('activeSeason', season.id);
          activeSeason.set('isActive', true);
          activeSeason.save();
          account.save();
        }
      }
    },
    confirmDelete(season) {
      $('.ui.modal').modal('show');
      this.set('deletingSeason', season);
    },
    denyModal() {

    },
    toggleButtons(season) {
      season.toggleProperty('showingButtons');
    }
  }

});
