import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),
  setupController(controller, model) {
    controller.set('plan', model.get('plan'));
    let pts = [];
    let reportTypes = model.get('reportTypes');
    if (reportTypes) {
      pts = reportTypes.split('|');
      pts.forEach(u=>{
        if (u === 'pdf') controller.set('reportBuildPDF', true);
        if (u === 'xlsx') controller.set('reportBuildXLSX', true);
        if (u === 'html') controller.set('reportBuildHTML', true);
        if (u === 'maxpreps') controller.set('reportBuildMaxPreps', true);
      })
    }
    this._super(controller, model);
  }
});