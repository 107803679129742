define("stattracker-live/components/game-flow", ["exports", "stattracker-shared/components/game-flow"], function (_exports, _gameFlow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _gameFlow.default;
    }
  });
});
