import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { A } from '@ember/array';
export default Route.extend({
  lazyload: service(),
  beforeModel() {
    return this.get('lazyload').loadVideo();
  },
  setupController(controller, model) {
    this._super(controller, model);
    return this.get('_ensureData').perform(model, controller);
  },
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('reelName', '');
      controller.set('reelDesc', '');
      let events = controller.get('sortedEvents');
      events.forEach(u=>{
        u.set('use', false);
      })
    }
  },
  _ensureData: task(function* (model, controller) {
    let allEvents = A();
    if (model.homeGames)
    {
      let hg=yield model.homeGames.toArray();
      for (let i = 0;i<hg.length;i++)
      {
        let game = hg[i];
        if (game.get('hasFilm'))
        {
          let eventLedger = yield this.store.find('game-event-ledger',game.id);
          if (eventLedger) {
            let events = yield eventLedger.get('events').toArray();
            for (let j=0;j<events.length;j++)
            {
              let evt=events[j];
              if (evt.get('teamSeason') === model.teamSeasonId && evt.get('hasClip'))
              {
                evt.set('gameRef', game);
                allEvents.pushObject(evt);
              }
            }
          }
        }
      }
    }
    if (model.awayGames)
    {
      let ag=yield model.awayGames.toArray();
      for (let i = 0;i<ag.length;i++)
      {
        let game = ag[i];
        let eventLedger = yield this.store.find('game-event-ledger',game.id);
        if (eventLedger) {
          let events = yield eventLedger.get('events').toArray();
          for (let j=0;j<events.length;j++)
          {
            let evt=events[j];
            if (evt.get('teamSeason') === model.teamSeasonId && evt.get('hasClip'))
            {
              evt.set('gameRef', game);
              allEvents.pushObject(evt);
            }
          }
        }
      }
    }
    
    controller.set('allEvents', allEvents);
  }),
});
