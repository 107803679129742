import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { all } from 'rsvp';
export default Route.extend({
  session: service(),
  model(params) {
    return this.get('loadData').perform(params);
  },
  loadData: task(function* (params) {
    let primary=undefined;
    if (isPresent(params.teamSeasonId)) {
      primary = yield this.store.find('bask-team-season', params.teamSeasonId);
    } else {
      return new Error('No Team Season available');
    }
    let season = primary.get('season');
    let account = this.get('session.account');
    let _all = this.store.query('bask-player', {
      orderBy: 'account',
      equalTo: account.id
    });
    let playerSeasons = yield primary.get('playerSeasons');
    let promises=[];
    _all.forEach((p)=>{
      promises.push(p.get('teams'));
    })
    let teams = yield all(promises);
    return {
      playerSeasons,
      all:_all,
      primary,
      account,
      season,
      teams
    }
  }),
  setupController(controller, model) {
    controller.set('returnRoute', 'teamseason.active-players');
    this._super(controller, model);
  },
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('playerId', undefined);
    }
  }
});
