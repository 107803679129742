import { isPresent } from '@ember/utils';
import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
export default Route.extend({
  model(params) {
    let playerId = params.player_id;
    if (isPresent(playerId)) {
      return this.get('loadData').perform(playerId);
    }
  },
  loadData: task(function* (playerId) {
    let player = yield this.store.find('bask-player', playerId);
    let playerReportTask = this.get('loadPlayerReport').perform(playerId);
    return {
      player,
      playerReportTask: {data:playerReportTask},
      teams: yield player.get('teams'),
      playerSeasons: yield player.get('playerSeasons'),
      teamSeasons: yield player.get('teamSeasons')
    };
  }),
  loadPlayerReport: task(function* (playerId) {
    return  yield this.store.find('player-career-report', playerId ); 
  }),
});

