import UploadMixin from '../../mixins/fb-upload';
import { task } from 'ember-concurrency';
import Controller from '@ember/controller';

/* global moment*/
export default Controller.extend(UploadMixin, {
  queryParams: ['team', 'destination'],
  actions: {
    save() {
      this.get('_save').perform();
    }
  },
  _save: task(function* () {
    let attrs=this.getProperties([
      'name',
      'seasonType',
      'tags',
      'start'
    ]);
    if (attrs.start) {
      attrs.start = moment(attrs.start, 'MM/DD/YYYY');
    }
    else
    {
      attrs.start = moment();
    }
    let teamId=this.get('team');
    let teamSeason = undefined;
    let _team = undefined;
    if (teamId) {
      _team = yield this.store.find('bask-team', teamId);
      teamSeason = this.store.createRecord('bask-team-season');
    }
    let season = this.store.createRecord('bask-season', attrs);
    let account = this.get('model');
    let accountId = account.get('id');
    season.set('account', accountId);
    if (teamSeason && _team) {
      teamSeason.set('team', _team);
      teamSeason.set('season', season);
      teamSeason.set('account', accountId);
      yield teamSeason.save();
      _team.get('teamSeasons').pushObject(teamSeason);
      yield _team.save();
      season.get('teamSeasons').pushObject(teamSeason);
    }
    let model = yield this.saveUploads(season, accountId);
    this.clear();
    yield model.save();
    yield account.save();
    this.transitionToRoute('seasons');
  })
});
