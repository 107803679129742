define("stattracker-live/models/game-report", ["exports", "stattracker-shared/models/game-report"], function (_exports, _gameReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _gameReport.default;
    }
  });
});
