import UploadMixin from '../../mixins/fb-upload';
import Controller from '@ember/controller';
import emailVal from '../../mixins/email-validation';
import { computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
export default Controller.extend(UploadMixin, emailVal, {
  session: service(),
  // seasonName is a queryParam for display purposes only
  queryParams: ['teamId', 'teamSeasonId', 'seasonName'],
  hasFirstName: computed('firstName', function() {
    let fn=this.get('firstName');
    return fn && fn.length>0;
  }),
  hasLastName: computed('lastName', function() {
    let ln=this.get('lastName');
    return ln && ln.length>0;
  }),
  validName: computed.or('hasFirstName','hasLastName'),
  validEmail: computed('email', function() { //empty is valid
    let email = this.get('email');
    if (email && email.length > 0)
    { 
      return this._checkEmail(email);
    }
    return true;
  }),
  validNumber: computed('number', function() { //must have number
    let n = this.get('number');
    if (!n) return false;
    if (n.length <= 2 && n.length>0) {
      let parsed=Number.parseInt(n);
      if (Number.isNaN(parsed)) {
        return false;
      }
      return true;
    }
    return false;
  }),
  validAwayNumber: computed('awayNumber', function() { //empty is valid
    let n = this.get('awayNumber');
    if (!n) return true;
    if (n && n.length <= 2 && n.length>0) {
      let parsed=Number.parseInt(n);
      if (Number.isNaN(parsed)) {
        return false;
      }
      return true;
    }
    return false;
  }),
  canCreate: computed('_create.isRunning', 'validEmail', 'validNumber', 'validAwayNumber', 'validName', function() {
    return !this.get('_create.isRunning') && this.get('validEmail') && this.get('validNumber') && this.get('validAwayNumber') && this.get('validName');
  }),
  actions: {
    onImport() {
      let team = this.get('model.team');
      this.transitionToRoute('import-teamplayer', team.id);
    },

    create() {
      this.get('_create').perform();
    },

    cancel() {
      let model = this.get('model');
      if (model.teamSeason) {
        this.transitionToRoute('teamseason.active-players', model.teamSeason.id);
      } else {
        this.transitionToRoute('team.players', model.team.id);
      }
    }
  },
  _create: task(function* () {
    let props = this.getProperties([
      'firstName',
      'lastName',
      'number',
      'awayNumber',
      'year',
      'homeTown',
      'height',
      'weight',
      'email',
      'phone'
    ]);
    let player = this.store.createRecord('bask-player', props );
    let team = this.get('model.team');
    if (team) {
      let model = this.get('model.team');
      let  team  = model;
      let accountId =this.get('session.accountId');
      player.set('account', accountId);
      player.set('team', team);
      team.get('players').addObject(player);
      yield player.save();
      yield this.saveUploads(player, accountId);
      yield team.save();
      let teamSeasonId = this.get('teamSeasonId');
      if (teamSeasonId) {
        let teamSeason = yield this.store.findRecord('bask-team-season', teamSeasonId);
        let playerseason = this.store.createRecord('bask-player-season', props);
        playerseason.set('teamSeason', teamSeason);
        playerseason.set('player', player);
        playerseason.set('account', accountId);
        playerseason.set('isStarter', player.get('isStarter'));
        playerseason.set('imageURL', player.get('imageURL'));
        playerseason.set('displayOrder', player.get('displayOrder'));
        playerseason.set('tags', player.get('tags'));
        yield playerseason.save();
        teamSeason.get('playerSeasons').pushObject(playerseason);
        teamSeason.get('players').pushObject(player);
        player.get('playerSeasons').pushObject(playerseason);
        yield player.get('teamSeasons').pushObject(teamSeason);
        yield player.save();
        yield teamSeason.save();
        yield playerseason.save();
        this.transitionToRoute('playerseasons.add');
      } else {
        this.transitionToRoute('team.players', team.id);
      }
    }
  })
});
