import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import periodHeaders from '../../mixins/period-headers-mixin';
/* global moment*/
export default Controller.extend(periodHeaders, {
  intl: service(),

  canSave: computed('save.isRunning', 'teamSeason', function() {
    let saving = this.get('save.isRunning');
    let ts = this.get('teamSeason');
    return !saving && ts;
  }),

  init() {
    this._super();
    this.set('originator', moment());
  },

  _save: task(function* () {
    let event = this.get('model.event');
    let game = this.get('model.game');
    let gameId = this.get('model.game.id');
    let saveGame=false;
    if (game) 
    {
      if (game.get('isScheduled'))
      {
        game.set('status', 'live');
        saveGame=true;
      }
      else if (game.get('isFinished'))
      {
        game.set('status', 'edited');
        saveGame=true;
      }
    }
    let oldScored=this.get('scored');
    let scored = undefined;
    let homeTeamSeason = this.get('model.homeTeamSeason');
    let isHome = event.get('teamSeason') === homeTeamSeason;
    let type0 = event.get('type0');
    if (type0 === 0) {
      scored = event.get('shotMade') ? event.get('type1') : 0;
    }
    if (scored!==oldScored)
    {
      game.decrementProperty(isHome ? 'homeScore' : 'awayScore', oldScored);
      if (scored!==undefined){
        game.incrementProperty(isHome ? 'homeScore' : 'awayScore', scored);
      }
      saveGame=true;
    }
    
    let playerSeasonId=this.get('playerSeason');
    if (playerSeasonId === 'undefined')
    {
      event.set('playerSeason', undefined);
    }
    let oldPlayerSeasonId = this.get('oldPlayerSeason');
    let playerSeasons = this.get(isHome?'homePlayers':'awayPlayers');
    let _playerSeason = playerSeasons.filterBy('id', playerSeasonId); //these return an array
    if (oldPlayerSeasonId && playerSeasonId!==oldPlayerSeasonId)
    {
      let _oldPlayerSeason = playerSeasons.filterBy('id', oldPlayerSeasonId); //these return an array
      let _updates = [event];
      if (_oldPlayerSeason &&  _oldPlayerSeason.length)
      {
        let oldPlayerGame = _oldPlayerSeason[0].get('playerGame');
        yield oldPlayerGame.rollback(_updates, this.get('originator'));
      }
      if (_playerSeason && _playerSeason.length)
      {
        let playerGame = _playerSeason[0].get('playerGame');
        yield playerGame.update(_updates, this.get('originator'));
      }
    }
    else if (_playerSeason && _playerSeason.length)
    {
      let playerGame = _playerSeason[0].get('playerGame');
      let _updates = [event];
      yield playerGame.update(_updates, this.get('originator'));
    }
    
    if (saveGame)
    {
      yield game.save();
    }
    if (event) {
      yield event.save( { adapterOptions: { 'gameId' : gameId } });
    }
    let qp=undefined;
    if (this.get('clipTime'))
    {
      qp={
        queryParams:{
          clipTime: this.get('clipTime')
        }
      };
      if (this.get('rate'))
      {
        qp.queryParams.playbackRate = this.get('rate');
      }
    }
    if (qp) {
      this.transitionToRoute(`game.${this.get('destination')}`, this.get('model.game'), qp );
    }
    else
    {
      this.transitionToRoute(`game.${this.get('destination')}`, this.get('model.game'));
    }
  }).drop(),

  actions: {
    save() {
      this.get('_save').perform();
    },
    cancel() {
      let qp = undefined;
      if (this.get('clipTime'))
      {
        qp={
          queryParams:{
            clipTime: this.get('clipTime')
          }
        };
        if (this.get('rate'))
        {
          qp.queryParams.playbackRate = this.get('rate');
        }
      }
      if (qp) {
        this.transitionToRoute(`game.${this.get('destination')}`, this.get('model.game'), qp );
      }
      else
      {
        this.transitionToRoute(`game.${this.get('destination')}`, this.get('model.game'));
      }
    }
  },

  timeLeftInPeriod: computed('model.event.secondsRemaining', function () {
    let secondsLeft = this.get('model.event.secondsRemaining');
    let sec = (secondsLeft % 60);
    sec = sec > 9 ? sec : '0' + sec;
    let min = Math.floor(secondsLeft / 60);
    return `${min}:${sec}`;
  }),

  queryParams: ['game', 'event', 'clipTime', 'destination', 'rate'],

  period: computed('model.event.period', {
    get(key) { //eslint-disable-line no-unused-vars
      return this.get('model.event.period');
    },
    set(key, val) {
      let orig = this.get('model.event.period');
      if (orig !== val) {
        this.set('model.event.period', val);
      }
      return val;
    }
  }),

  periods: computed('model.{periodCurrent,periodCount}', function() {
    let periodCount = this.get('model.periodCount');
    let periodCurrent = this.get('model.periodCurrent');
    return this._periods(periodCount, periodCurrent, 0);
  }),

  hasSubType: computed('statType', function() {
    return this.getStatSubTypeCount(this.get('statType')) > 0;
  }),

  hasSubSubType: computed('statType', function () {
    return this.get('statType') === 0;
  }),

  shotMade: computed.alias('model.event.shotMade'),

  statType: computed('model.event.type0', {
    get(key) { //eslint-disable-line no-unused-vars
      return this.get('model.event.type0');
    },
    set(key, val) {
      let orig = this.get('model.event.type0');
      if (orig !== val) {
        this.set('model.event.type0', val);
        this.set('model.event.type1', null);
        this.set('model.event.type2', null);
      }
      return val;
    }
  }),

  statTypes: computed('statType', function () {
    let result = [];
    let intl = this.get('intl');
    for (let i = 0; i <= 13; i++) {
      let t = intl.t(this.getStatType(i));
      result.push({
        id: i,
        name: t
      });
    }
    return result;
  }),

  statSubType: computed('model.event.type1', {
    get(key) { //eslint-disable-line no-unused-vars
      return this.get('model.event.type1');
    },
    set(key, val) {
      let orig = this.get('model.event.type1');
      if (orig !== val) {
        this.set('model.event.type1', val);
        this.set('model.event.type2', null);
      }
      return val;
    }
  }),

  statSubTypes: computed('statSubType', 'statType', function () {
    let result = [];
    let intl = this.get('intl');
    let type0 = this.get('statType');
    for (let i = this.getStatSubTypeStart(type0); i < this.getStatSubTypeCount(type0); i++) {
      let t = intl.t(this.getStatSubType(type0, i));
      result.push({
        id: i,
        name: t
      });
    }
    return result;
  }),

  statSubSubType: computed('model.event.type2', {
    get(key) { //eslint-disable-line no-unused-vars
      let evt=this.get('model.event');
      return evt.getFGExtra();
    },
    set(key, val) {
      let type2=0;
      val.forEach(v => {
        type2 |= v;
      })
      let evt=this.get('model.event');
      evt.set('type2', type2);
      return val;
    }
  }),

  statSubSubTypes: computed('statSubSubType', 'statSubType', 'statType', function () {
    let result = [];
    let intl = this.get('intl');
    let type0 = this.get('statType');
    if (type0 === 0) //shot
    {
      result.push({
        id: 1,
        name: intl.t('event.fg.scp')
      });
      result.push({
        id: 2,
        name: intl.t('event.fg.pot')
      });
      result.push({
        id: 4,
        name: intl.t('event.fg.fb')
      });
    }
    return result;
  }),

  teamSeason: computed('model.event.teamSeason', {
    get(key) { //eslint-disable-line no-unused-vars
      return this.get('model.event.teamSeason');
    },
    set(key, val) {
      this.set('model.event.teamSeason', val);
      this.set('model.event.playerSeason', null);
      return val;
    }
  }),

  playerSeason: computed.alias('model.event.playerSeason'),

  isHome: computed('model.homeTeamSeason', 'teamSeason', function () {
    return this.get('model.homeTeamSeason') === this.get('teamSeason');
  }),

  playerSeasons: computed('model.{homePlayerSeasons,awayPlayerSeasons}', 'isHome', function () {
    return this.get('isHome') ? this.get('model.homePlayerSeasons') : this.get('model.awayPlayerSeasons');
  }),

  homePlayers: computed('model.{homePlayerSeasons.[],playerGames.[]}', function() {
    let p = this.get('model.homePlayerSeasons');
    let pg = this.get('model.playerGames');
    return p.forEach((item) => {
      return item.set('playerGame', pg.findBy('playerSeason', item.id));
    });
  }),

  awayPlayers: computed('model.{awayPlayerSeasons.[],playerGames.[]}', function() {
    let p = this.get('model.awayPlayerSeasons');
    let pg = this.get('model.playerGames');
    return p.forEach((item) => {
      return item.set('playerGame', pg.findBy('playerSeason', item.id));
    });
  }),

  getStatType(type0) {
    let pre = 'event.';
    switch (type0) {
      case 0: // FG
        return `${pre}fg.cmn`;
      case 1: // REB
        return `${pre}reb.cmn`;
      case 2: // TO
        return `${pre}to`;
      case 3: // AST
        return `${pre}ast`;
      case 4: // BLK
        return `${pre}blk`;
      case 5: // STL
        return `${pre}stl`;
      case 6: // Foul
        return `${pre}foul.cmn`;
      case 7: // SUB
        return `${pre}sub.cmn`;
      case 8: // DEF
        return `${pre}def`;
      case 9: // TMT
        return `${pre}tmt.cmn`;
      case 10: // CD
        return `${pre}cd`;
      case 11: // POSS
        return `${pre}poss.cmn`;
      case 12: // BLK REC
        return `${pre}blk.rec`;
      case 13: // FOUL REC
        return `${pre}foul.rec`;
    }
  },

  getStatSubTypeStart(type0) {
    if (type0 == 0) {
      return 1;
    }
    return 0;
  },

  getStatSubTypeCount(type0) {
    switch (type0) {
      case 0: return 4;
      case 1: return 2;
      case 6: return 6;
      case 7: return 2;
      case 9: return 4;
      case 11: return 2;
    }
    return 0;
  },

  getStatSubType(type0, type1) {
    let pre = 'event.';
    switch (type0) {
      case 0: {
        // FG
        switch (type1) {
          case 1:
            return `${pre}fg.ft`;
          case 2:
            return `${pre}fg.2p`;
          case 3:
            return `${pre}fg.3p`;
        }
      } break;
      case 1: {
        // REB 'event.reb.d' : 'event.reb.o'
        switch (type1) {
          case 0:
            return `${pre}reb.o`;
          case 1:
            return `${pre}reb.d`;
        }
      } break;
      case 6: { // Foul
        switch (type1) {
          case 0:
            return `${pre}foul.p`;
          case 1:
            return `${pre}foul.o`;
          case 2:
            return `${pre}foul.i`;
          case 3:
            return `${pre}foul.t`;
          case 4:
            return `${pre}foul.f`;
          case 5:
            return `${pre}foul.d`;
        }
      } break;
      case 7: { // SUB
        switch (type1) {
          case 0:
            return `${pre}sub.in`;
          case 1:
            return `${pre}sub.out`;
        }
      } break;
      case 9: {  // TMT
        switch (type1) {
          case 0:
            return `${pre}tmt.s20`;
          case 1:
            return `${pre}tmt.s30`;
          case 2:
            return `${pre}tmt.s60`;
          case 3:
            return `${pre}tmt.cmb`;
        }
      } break;
      case 11: {  // POS
        switch (type1) {
          case 0:
            return `${pre}poss.tip`;
          case 1:
            return `${pre}poss.tieup`;
        }
      } break;
    }
  }
});