import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import cmMixin from '../../mixins/color-manipulation';  
export default Route.extend(cmMixin, {
  session: service(),
  lazyload: service(),
  beforeModel() {
    return this.get('lazyload').load(['stripe','grid']);
  },
  model() {
    let game = this.modelFor('game');
    return this.get('loadData').perform(game);
  },
  loadData: task(function* (game) {
    let session = this.get('session');
    let prepaid = yield session.get('account.prepaidBreakdowns');
    let plans = yield this.store.query('plan', {});
    let user = yield this.store.find('user', session.get('userId'));
    let homeTeamSeason = yield game.get('homeTeam');
    let awayTeamSeason = yield game.get('awayTeam');
    let playerGames = yield this.store.query('bask-player-game', {
        orderBy: 'game',
        equalTo: game.id
      });
    let homeTeam = yield homeTeamSeason.get('team');
    let awayTeam = yield awayTeamSeason.get('team');
    let homeColor = `#${homeTeam.get('homeColor')}`;
    let awayColor = `#${awayTeam.get('awayColor')}`;
    let homeSecondColor = this.pSBC((homeColor === '#FFFFFF'?-.05:.25),homeColor);
    let awaySecondColor = this.pSBC((awayColor === '#FFFFFF'?-.05:.25),awayColor);
    document.body.style.setProperty('--home-primary-color', homeColor);
    document.body.style.setProperty('--away-primary-color', awayColor);
    document.body.style.setProperty('--home-secondary-color', homeSecondColor);
    document.body.style.setProperty('--away-secondary-color', awaySecondColor);
    document.body.style.setProperty('--home-text-color', this.getTextColor(homeColor));
    document.body.style.setProperty('--away-text-color', this.getTextColor(awayColor));
   return {
      prepaid,
      plans,
      user,
      game,
      homeTeam,
      awayTeam,
      homePlayers: yield homeTeamSeason.get('playerSeasons'),
      awayPlayers: yield awayTeamSeason.get('playerSeasons'),
      awayTeamSeason,
      homeTeamSeason,
      playerGames,
      homeColor,
      awayColor
    };
  }),
  actions: {
    willTransition(transition){
      // if the transition is not in the same route
      if (transition.targetName.indexOf(this.fullRouteName) != 0) {
        document.body.style.removeProperty('--home-primary-color');
        document.body.style.removeProperty('--away-primary-color');
        document.body.style.removeProperty('--home-secondary-color');
        document.body.style.removeProperty('--away-secondary-color');
        document.body.style.removeProperty('--home-text-color');
        document.body.style.removeProperty('--away-text-color');
      }
    }
  }
});
