import Ember from 'ember';

const { Controller, inject: { service } } = Ember;
export default Controller.extend({
  queryParams: ['teamseason'],
  teamseason: null,

  actions: {

    save() {
      let teamseason = this.get('model.teamseason');
      if (teamseason) {
        let player = this.store.createRecord('bask-player', this.getProperties([
          'firstName',
          'lastName',
          'number',
          'awayNumber',
          'year',
          'homeTown',
          'height',
          'weight',
          'email',
          'phone'

        ]));

        let account = this.get('model.account');
        player.set('account', account.id);
        teamseason.get('team').then(function(team) {
          let teams = player.get('teams');
          teams.pushObject(team);
          let teamseasons = player.get('teamSeasons');
          teamseasons.pushObject(teamseason);
          let players = team.get('players');
          players.pushObject(player);
          let seasonPlayers = teamseason.get('players');
          seasonPlayers.pushObject(player);
          player.save();
          account.save();
          teamseason.save();
          team.save();
        });
        this.transitionToRoute('teamseason', teamseason);

      } else {
        let player = this.store.createRecord('bask-player', this.getProperties([
          'firstName',
          'lastName',
          'number',
          'awayNumber',
          'year',
          'homeTown',
          'height',
          'weight',
          'email',
          'phone'
        ]));
        let account = this.get('model.account');
        player.set('account', account);
        account.get('players').pushObject(player);
        let accountId = account.get('id');
        this.saveUploads(player, accountId).then((model) => {
          this.clear();
          model.save();
          account.save();
          this.transitionToRoute('team');
        });
      }
    },
    onImport() {
      let teamseason = this.get('model.teamseason');
      this.transitionToRoute('import-players', teamseason.id);
    }

  }
});
