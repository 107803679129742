
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
export default Route.extend({
  session: service(),

  model() {
    return this.get('loadData').perform();
  },
  loadData: task(function* () {
    let account = yield this.get('session.account');
    
    var all = undefined;
    var plan=yield account.get('plan');
    if (plan==='league')
    {
      all = yield this.store.query('bask-season', {
        orderBy: 'account',
        equalTo: account.id
      });
    }
    else if (plan==="team")
    {
      all = yield this.store.query('bask-team-season', {
        orderBy: 'season',
        equalTo: account.get('activeSeason')
      });
    }
    return {
      all,
      account,
      plan
    };
    
  }),
  resetController(controller, isExiting, transition) {
    if (isExiting) {
      controller.set('publicUrl', '');
      controller.set('displayName', '');
      controller.set('teamSeason', '');
      controller.set('season', '');
    }
  },
  setupController(controller, model) {
    var plan=model.account.get('plan');
    if (plan==='team')
    {
      controller.set('modelType', 'team');
    }
    else if (plan==='league')
    {
      controller.set('modelType', 'season');
    }
    controller.set('plan', plan);
    if (model.slug)
    {
      controller.set('publicUrl', model.slug.get('publicUrl'));
      controller.set('displayName', model.slug.get('displayName'));
    }
    controller.set('display', model.all.filter((item) => {
      return true;//!model.teamSeasons.mapBy(controller.get('modelType') + '.id').includes(item.get('id'));
    }));
    this._super(controller, model);
  },
  actions: {
    cancel() {
      this.transitionTo('public');
    }
  }
});
