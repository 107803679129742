import Route from '@ember/routing/route'; 
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),
  lazyload: service(),
  beforeModel() {
    return this.get('lazyload').load(['stripe','timezone']);
  },
  model() {
    return this.get('session.account');
  },
  renderTemplate() {
    let session = this.get('session');
    if (session.get('isAuthenticated')) {
      this.render('account');
    } else {
      this.render('default');
    }
  }
});
