import Ember from 'ember';

const { Controller } = Ember;
export default Controller.extend({
  actions: {
    onImport() {
      let team = this.get('model.team');
      this.transitionToRoute('import-teamplayer', team.id);
    }
  }
});
