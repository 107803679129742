import { computed } from '@ember/object'
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
export default Controller.extend({
  session: service(),
  userAccounts: computed('model.{accounts.[],adminAccounts.[]}', function() {
    let accounts=this.get('model.accounts');
    let adminAccounts=this.get('model.adminAccounts');
    if (accounts&&adminAccounts){
      let a=accounts.toArray();
      let aa=adminAccounts.toArray();
      return a.concat(aa);
    }
    return [];
  }),
});
