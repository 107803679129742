import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import periodHeaders from '../../mixins/period-headers-mixin';
export default Controller.extend(periodHeaders,{
  intl: service(),
  adjustClip: true,

  canSave: computed('save.isRunning', 'teamSeason', function() {
    let saving = this.get('save.isRunning');
    let ts = this.get('teamSeason');
    return !saving && ts;
  }),

  _save: task(function* () {
    let timing = this.get('model.timing');
    let game = this.get('model.game');
    let gameId = this.get('model.game.id');
    let periodCount = this.get('model.game.periodCount');
    let period = timing.get('period');
    let periodSeconds = this.get('model.game.periodSeconds');
    let overtimeSeconds = this.get('model.game.periodOvertimeSeconds');
    var saveGame=false;
    if (game.get('isScheduled'))
    {
      game.set('status', 'live');
      saveGame = true;
    }
    else if (game.get('isFinished'))
    {
      game.set('status', 'edited');
      saveGame = true;
    }
    if (saveGame)
    {
      yield game.save();
    }
    if (timing) {
      if (period>=periodCount) //overtime
      {
        let reg=periodCount*periodSeconds;
        let overtimes=period-periodCount+1;
        timing.set('clockStart',reg+(overtimes*overtimeSeconds)-timing.get('secondsRemaining'));
        timing.set('clockStop',reg+(overtimes*overtimeSeconds)-timing.get('secondsRemainingStop'));
      }
      else
      {
        timing.set('clockStart',(periodSeconds*(period+1))-timing.get('secondsRemaining'));
        timing.set('clockStop',(periodSeconds*(period+1))-timing.get('secondsRemainingStop'));
      }
      yield timing.save( { adapterOptions: { 'gameId' : gameId } });
    }
    let qp = undefined;
    if (this.get('clipTime'))
    {
      qp={
        queryParams:{
          clipTime: this.get('model.timing.clipStop')
        }
      };
    }
    if (qp)
    {
      this.transitionToRoute(`game.${this.get('destination')}`, this.get('model.game'), qp );
    }
    else
    {
      this.transitionToRoute(`game.${this.get('destination')}`, this.get('model.game'));
    }

  }).drop(),

  actions: {
    save() {
      this.get('_save').perform();
    },
    cancel() {
      let qp = undefined;
      if (this.get('clipTime'))
      {
        qp={
          queryParams:{
            clipTime: this.get('clipTime')
          }
        };
      }
      if (qp)
      {
        this.transitionToRoute(`game.${this.get('destination')}`, this.get('model.game'), qp );
      }
      else
      {
        this.transitionToRoute(`game.${this.get('destination')}`, this.get('model.game'));
      }
    }
  },

  timeLeftInPeriod: computed('model.timing.secondsRemaining', function () {
    let secondsLeft = this.get('model.timing.secondsRemaining');
    let sec = (secondsLeft % 60);
    sec = sec > 9 ? sec : '0' + sec;
    let min = Math.floor(secondsLeft / 60);
    return `${min}:${sec}`;
  }),

  queryParams: ['game', 'timing', 'destination', 'clipTime'],

  period: computed('model.timing.period', {
    get(key) { //eslint-disable-line no-unused-vars
      return this.get('model.timing.period');
    },
    set(key, val) {
      let orig = this.get('model.timing.period');
      if (orig !== val) {
        this.set('model.timing.period', val);
      }
      return val;
    }
  }),

  secondsRemaining: computed('model.timing.secondsRemaining', {
    get(key) { //eslint-disable-line no-unused-vars
      return this.get('model.timing.secondsRemaining');
    },
    set(key, val) {
      let orig = this.get('model.timing.secondsRemaining');
      if (orig !== val) {
        let offset = orig-val;
        if (this.get('adjustClip'))
        {
          this.incrementProperty('model.timing.clipStart',offset);
        }
        this.set('model.timing.secondsRemaining', val);
      }
      return val;
    }
  }),

  secondsRemainingStop: computed('model.timing.secondsRemainingStop', {
    get(key) { //eslint-disable-line no-unused-vars
      return this.get('model.timing.secondsRemainingStop');
    },
    set(key, val) {
      let orig = this.get('model.timing.secondsRemainingStop');
      if (orig !== val) {
        let offset = orig-val;
        if (this.get('adjustClip'))
        {
          this.incrementProperty('model.timing.clipStop',offset);
        }
        this.set('model.timing.secondsRemainingStop', val);
      }
      return val;
    }
  }),

  periods: computed('model.{periodCurrent,periodCount}', function() {
    let periodCount = this.get('model.periodCount');
    let periodCurrent = this.get('model.periodCurrent');
    return this._periods(periodCount, periodCurrent, 0);
  })
});