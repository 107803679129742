import ENV from '../../config/environment';
import $ from 'jquery';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
/* global StripeCheckout */
export default Controller.extend({
  firebaseApp: service(),
  intl: service(),
  session: service(),
  metrics: service(),
  monthPricing: true,

  hasVideoSubscription: computed('session.isVideo', function(){
    return this.get('session.isVideo');
  }),

  plans: computed('model.{plans,account.plan,account.subscriptionProvider}', function () {
    let provider = this.get('model.account.subscriptionProvider');
    let canChangeSubscription = this.get('model.account.canChangeSubscription');
    let plans = this.get('model.plans').filterBy('type','live').sortBy('monthPrice');
    let _plans = plans.toArray();
    let _planId = this.get('model.account.plan');
    let activeCost = 0.0;
    let activePlan = _plans.findBy('id', _planId);
    if (activePlan) {
      activePlan.set('isActive', true);
      activeCost = activePlan.get('monthPrice');
      if (!canChangeSubscription) {
        activePlan.set('mustCancelElsewhere', true);
        activePlan.set('cancellationInstructions', this.get('intl').t(`plan.cancellation.${provider}`, {htmlSafe:true}));
      }
    }
    plans.forEach((p) => {
      let _id = p.get('id');
      let _cost = p.get('monthPrice');
      if (_id !== _planId) {
        p.set('isActive', false);
        p.set('isDowngrade', _cost < activeCost);
        p.set('isUpgrade', _cost > activeCost);
      }
    });
    return _plans;
  }),

  actions: {
    openModal: function (name) {
      $('.ui.' + name + '.modal').modal('show');
    },
    approveModal: function (element, component) { //eslint-disable-line no-unused-vars
      var v = element.toArray();
      if (v.length) {
        if (v[0].id === 'Close') {
          this.get('_closeAccount').perform();
        }
        else if (v[0].id === 'FreeVersion') {
          this.get('_goFree').perform();
        }
        else if (v[0].id === 'StopVideo') {
          this.get('_stopVideo').perform();
        }
      }
      return true;
      
    },
    denyModal: function (element, component) { //eslint-disable-line no-unused-vars
      return true;
    },
    togglePricing() {
      this.toggleProperty('monthPricing');
    },
    checkout(plan) {
      this.get('_checkout').perform(plan);
    },
    checkoutVideo() {
      let plans = this.get('model.plans').filterBy('type','live').sortBy('monthPrice');
      let plan = null;
      plans.forEach((p)=>{
        if (p.get('stripeVideoPlan'))
        {
          plan=p;
        }
      })
      this.get('_checkoutVideo').perform(plan);
    }
  },

  _closeAccount: task(function* () {
    let _account = this.get('model.account');
    this.get('metrics').trackEvent('GoogleAnalytics', {
      category: 'billing',
      action: 'closeAccount',
      label: `Prior Plan ${_account.get('plan')}`
    });
    yield _account.destroyRecord();
    
    this.get('session').invalidate();
  }),

  _stopVideo: task(function* () {
    let api=this.get('st-api');
    if (api)
    {
      let auth=this.get('firebaseApp').auth();
      let authToken = yield auth.currentUser.getToken();
      let _account = this.get('model.account');
      let body=
      {
        plan: 'noVideo',
        account: _account.id
      };
      yield api.charge(body,'video', authToken);
      this.get('metrics').trackEvent('GoogleAnalytics', {
        category: 'billing',
        action: 'stopVideo'
      });
    }
  }),

  _goFree: task(function* () {
    let api=this.get('st-api');
    if (api)
    {
      let auth=this.get('firebaseApp').auth();
      let authToken = yield auth.currentUser.getToken();
      let _account = this.get('model.account');
      this.get('metrics').trackEvent('GoogleAnalytics', {
        category: 'billing',
        action: 'goFreeAccount',
        label: `Prior Plan ${_account.get('plan')}`
      });
      let body=
      {
        plan: 'free',
        account: _account.id
      };
      yield api.charge(body,'live', authToken);
    }
  }),

  _checkout: task(function* (plan) {
      let api=this.get('st-api');
      let auth=this.get('firebaseApp').auth();
      let authToken = yield auth.currentUser.getToken();
      let _account = this.get('model.account');
      let _planId = plan.get('id');
      let _monthPricing = this.get('monthPricing');
      let _amount = (_monthPricing ? plan.get('monthPrice') : plan.get('yearPrice')) * 100;
      let _this=this;
      var handler = StripeCheckout.configure({
        key: ENV.stripe.key,
        image: '/icon.png',
        locale: 'auto',
        token: function (token, other) { //eslint-disable-line no-unused-vars
          let body=
          {
            plan: _planId,
            stripeToken: token.id,
            account: _account.id,
            monthlySubscription: _monthPricing
          };
          api.charge(body,'live', authToken);
          _this.get('metrics').trackEvent('GoogleAnalytics', {
            category: 'billing',
            action: 'subscribe',
            label: `Plan ${_planId}`
          });
        }
      });
      handler.open({
        name: 'Stattracker Live',
        description: plan.get('description'),
        amount: _amount,
        panelLabel: this.get('monthPricing') ? '{{amount}} Per Month' : '{{amount}} Per Year',
        shippingAddress: false,
        billingAddress: true,
        zipCode: true
      });
      return false;
  }),

  _checkoutVideo: task(function* (plan) {
    let intl = this.get('intl');
    let api=this.get('st-api');
    let _planId = plan.get('id');
    let auth=this.get('firebaseApp').auth();
    let authToken = yield auth.currentUser.getToken();
    let _account = this.get('model.account');
    let _this=this;
    var handler = StripeCheckout.configure({
      key: ENV.stripe.key,
      image: '/icon.png',
      locale: 'auto',
      token: function (token, other) { //eslint-disable-line no-unused-vars
        let body=
        {
          plan: _planId,
          stripeToken: token.id,
          account: _account.id
        };
        api.charge(body,'video', authToken);
        _this.get('metrics').trackEvent('GoogleAnalytics', {
          category: 'billing',
          action: 'subscribeVideo',
          label: `Plan ${_planId}`
        });
      }
    });
    handler.open({
      name: 'Stattracker Live',
      description: intl.t('app.meteredVideo').toString(),
      panelLabel: intl.t('app.addVideo').toString(),
      shippingAddress: false,
      billingAddress: true,
      zipCode: true
    });
    return false;
})
});
