import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
export default Route.extend({
  model() {
    return this.get('loadData').perform();
  },
  loadData: task(function* () {
    let accounts = yield this.store.findAll('account');
    return {
      accounts
    };
  })
});
