import apigClientFactory from './st-api/apigClient';
import ENV from '../config/environment';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default Service.extend({
  firebaseApp: service(),
  api: null,
  init() {
    let params = {
      region: 'us-west-2',
      endpoint: ENV.stApi
    };

    let api = apigClientFactory.newClient(params);
    this.set('api', api);
  },
  send(body) {
    let params = {};
    body = body || {};
    let additionalParams = {};

    let api = this.get('api');
    if (api) {
      return api.send(params, body, additionalParams);
    }
  },
  import(body) {
    let params = {};
    body = body || {};
    let additionalParams = {};

    let api = this.get('api');
    if (api) {
      return api.import(params, body, additionalParams);
    }
  },
  finalize(body) {
    let params = {};
    body = body || {};
    let additionalParams = {};

    let api = this.get('api');
    if (api) {
      return api.finalize(params, body, additionalParams);
    }
  },
  heal(body) {
    let params = {};
    body = body || {};
    let additionalParams = {};

    let api = this.get('api');
    if (api) {
      return api.heal(params, body, additionalParams);
    }
  },
  restore(body) {
    let params = {};
    body = body || {};
    let additionalParams = {};

    let api = this.get('api');
    if (api) {
      return api.restore(params, body, additionalParams);
    }
  },
  migrate(body) {
    let params = {};
    body = body || {};
    let additionalParams = {};

    let api = this.get('api');
    if (api) {
      return api.migrate(params, body, additionalParams);
    }
  },
  breakdown(body) {
    let params = {};
    body = body || {};
    let additionalParams = {};

    let api = this.get('api');
    if (api) {
      return api.breakdown(params, body, additionalParams);
    }
  },
  clipFilm(body) {
    let params = {};
    body = body || {};
    let additionalParams = {};

    let api = this.get('api');
    if (api) {
      return api.clipFilm(params, body, additionalParams);
    }
  },
  lookup(body)  {
    let url = `${ENV.ffApi}/lookup?slug=${body.slug}`;
    return new RSVP.Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();

      xhr.open('GET', url);
      xhr.onreadystatechange = handler;
      xhr.responseType = 'json';
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.send();

      function handler() {
        if (this.readyState === this.DONE) {
          if (this.status === 200) {
            resolve(this.response);
          } else {
            reject(new Error(`lookup: \`${  url  }\` failed with status: [${  this.status  }]`));
          }
        }
      }
    });
  },
  charge(body, chargeType, t)  {
    let url = `${ENV.ffApi}/charge?account=${body.account}&type=${chargeType}`;
    return new RSVP.Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();

      xhr.open('PUT', url);
      xhr.onreadystatechange = handler;
      xhr.responseType = 'json';
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Authorization',`Bearer ${t}`);
      xhr.send(JSON.stringify(body));

      function handler() {
        if (this.readyState === this.DONE) {
          if (this.status === 200) {
            resolve(this.response);
          } else {
            reject(new Error(`charge: \`${  url  }\` failed with status: [${  this.status  }]`));
          }
        }
      }
    });
  },
  link(l, title, desc, image, info)  {
    let request = {
      "dynamicLinkInfo": {
        "domainUriPrefix": ENV.linkPrefix,
        "link": l,
        "socialMetaTagInfo": {
          "socialTitle": title,
          "socialDescription": desc,
          "socialImageLink": image
        }
      },
      "suffix": {
        "option": "UNGUESSABLE"
      }
    }
    if (info)
    {
      if (info.androidInfo)
      {
        request.androidInfo=info.androidInfo;
      }
      if (info.iosInfo)
      {
        request.iosInfo=info.iosInfo;
      }
      if (info.navigationInfo)
      {
        request.navigationInfo=info.navigationInfo;
      }
      if (info.analyticsInfo)
      {
        request.analyticsInfo=info.analyticsInfo;
      }
    }

    let url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${ENV.firebase.apiKey}`;
    return new RSVP.Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();

      xhr.open('POST', url);
      xhr.onreadystatechange = handler;
      xhr.responseType = 'json';
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.send(JSON.stringify(request));

      function handler() {
        if (this.readyState === this.DONE) {
          if (this.status === 200) {
            resolve(this.response);
          } else {
            reject(new Error(`link: \`${  url  }\` failed with status: [${  this.status  }]`));
          }
        }
      }
    });
  },
  token(body, t)  {
    let url = `${ENV.ffApi}/token?account=${body.account}&uid=${body.uid}`;
    return new RSVP.Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();

      xhr.open('GET', url);
      xhr.onreadystatechange = handler;
      xhr.responseType = 'json';
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Authorization',`Bearer ${t}`);
      xhr.send();

      function handler() {
        if (this.readyState === this.DONE) {
          if (this.status === 200) {
            resolve(this.response);
          } else {
            reject(new Error(`token: \`${  url  }\` failed with status: [${  this.status  }]`));
          }
        }
      }
    });
  },
  validate(body)  {
    let url = `${ENV.ffApi}/validate?id=${body.id}`;
    return new RSVP.Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();

      xhr.open('GET', url);
      xhr.onreadystatechange = handler;
      xhr.responseType = 'json';
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.send();

      function handler() {
        if (this.readyState === this.DONE) {
          if (this.status === 200) {
            resolve(this.response);
          } else {
            reject(new Error(`validate: \`${  url  }\` failed with status: [${  this.status  }]`));
          }
        }
      }
    });
  },
  img(body) {
    let params = {};
    body = body || {};
    let additionalParams = {};

    let api = this.get('api');
    if (api) {
      return api.img(params, body, additionalParams);
    }
  }
});
