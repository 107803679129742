import { task } from 'ember-concurrency';
import { filterBy, filter } from '@ember/object/computed';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default Controller.extend( {
  queryParams: ['destination'],

  init () {
    this._super(...arguments);
    this.setup= {
      playbackRates: [0.5, 1, 1.5, 2]
    };
  },

  _delete: task(function* () {
    let game = this.get('deletingGame');
    if (game) {
      yield game.destroyRecord();
    }
    this.transitionToRoute('index');
  }),
  actions: {
    delete() {
      this.get('_delete').perform();
    },

    confirmDelete(game) {
      $('.ui.modal').modal('show');
      this.set('deletingGame', game);
    },
    denyModal() {

    },
    ready(player, component) {
      // This is pretty much the minimum required for a useful video player.
      component.bindPropertyToPlayer(player, 'src');
    }
  },

  session: service(),
  intl: service(),
  courtName: 'USHS',
  courtNumber: 0,
  fieldGoals: filterBy('model.events', 'type0', 0),
  shots: filter('fieldGoals', (shot) => shot.get('type1') > 1),
  reels: filter('model.reels', (reel) => reel.get('type') === 'reel'),
  homePlayers: computed('model.{homePlayers.[],playerGames.[]}', function() {
    let p = this.get('model.homePlayers');
    let pg = this.get('model.playerGames');
    return p.forEach((item) => {
      return item.set('playerGame', pg.findBy('playerSeason', item.id));
    });
  }),

  awayPlayers: computed('model.{awayPlayers.[],playerGames.[]}', function() {
    let p = this.get('model.awayPlayers');
    let pg = this.get('model.playerGames');
    return p.forEach((item) => {
      return item.set('playerGame', pg.findBy('playerSeason', item.id));
    });
  }),

  canBreakdown: computed('isInBreakdown', 'rawFilm', function() {
    let isInBreakdown=this.get('isInBreakdown');
    let rawFilm = this.get('rawFilm');
    if (!isInBreakdown)
    {
      return !isEmpty(rawFilm);
    }
    return false;
  }),
  filmClippingAvailable: computed('rawFilm', function() {
    let rawFilm = this.get('rawFilm');
    return !isEmpty(rawFilm);
  }),
  isInBreakdown: computed.alias('model.game.isInBreakdown'),
  rawFilm: computed.alias('model.game.rawFilm'),
  rawFilmStatus: computed.alias('model.game.rawFilmStatus'),

  filmStatus: computed('rawFilmStatus', function() {
    let status=this.get('rawFilmStatus');
    let intl = this.get('intl');
    if (status) {
      return intl.t(`common.${status}`);
    }
    else {
      return null;
    } 
  }),

  rawFilmAvailable: computed('rawFilm','rawFilmStatus', function() {
    let film = this.get('rawFilm');
    if (film)
    {
      return this.get('rawFilmStatus') === 'complete';
    }
    return false;
  })
});
