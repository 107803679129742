import Ember from 'ember';
import { task } from 'ember-concurrency';
import liveData from '../mixins/live-data';
import Controller from '@ember/controller';
const { inject } = Ember;
export default Controller.extend(liveData, {
  init() {
    this._super();
  },
  session: inject.service(),
  closeWelcome: task(function* () {
    let store = this.get('store');
    let user=yield store.find('user',this.get('session.userId'));
    if (user) {
      user.set('status',0);
      yield user.save();
      this.set('session.userStatus',0); //showWelcome;
      this.get('target._routerMicrolib').refresh();
    }
  }),
  actions: {
    transition(location, data) {
      this.transitionToRoute(location, data);
    }
  }
});
