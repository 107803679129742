import config from './config/environment';
import EmberRouter from '@ember/routing/router';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { get } from '@ember/object';

const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL,
  metrics: service(),
  fastboot: service(),

  didTransition() {
    this._super(...arguments);
    this._trackPage();
  },

  _trackPage() {
    scheduleOnce('afterRender', this, () => {
      if (!this.get('fastboot.isFastBoot'))
      {
        let page = document.location.pathname;
        let title = this.getWithDefault('currentRouteName', 'unknown');

        get(this, 'metrics').trackPage({ page, title });
      }
    });
  }
});
Router.map(function() {
  this.route('account', function() {
    this.route('profile');
    this.route('billing');
    this.route('users');
    this.route('video');
    this.route('breakdowns');
  });

  this.route('slugs', function() {
    this.route('add');
  });
  this.route('slug', function() {
    this.route('edit', { path: '/:slug_id' });
  });

  this.route('seasons', function() {
    this.route('add');
  });

  this.route('season', { path: 'season/:season_id' }, function() {
    this.route('edit');
    this.route('teamseasons');
    this.route('games');
    this.route('downloads');
  });

  this.route('teams', function() {
    this.route('add');
  });

  this.route('team', { path: 'team/:bask-team_id' }, function() {
    this.route('edit');
    this.route('allseasons');
    this.route('players');
    this.route('downloads');
  });

  this.route('games', function() {
    this.route('add');
  });

  this.route('game', { path: 'game/:bask-game_id' }, function() {
    this.route('timeline');
    this.route('filter');
    this.route('edit');
    this.route('live');
    this.route('finalize');
    this.route('track');
    this.route('upload-film');
    this.route('breakdown');
    this.route('submit');
    this.route('add-reel');
  });

  this.route('teamseasons', function() {
    this.route('add');
  });

  this.route('teamseason', { path: 'teamseason/:teamseason_id' }, function() {
    this.route('active-players');
    this.route('games');
    this.route('boxscore');
    this.route('visuals', function() {
      this.route('home');
      this.route('away');
    });
    this.route('downloads');
    this.route('edit');
    this.route('add-reel');
    this.route('reels');
  });

  this.route('players', function() {
    this.route('add');
  });

  this.route('player', { path: 'player/:player_id' }, function() {
    this.route('boxscore');
    this.route('edit');
    this.route('allseasons');
    this.route('downloads');
    this.route('visuals');
  });

  this.route('pricing');
  this.route('login');
  this.route('sign-up');
  this.route('report', { path: 'report/:game-report_id' }, function() {
    this.route('boxscore');
    this.route('play-by-play');
    this.route('visuals', function() {
      this.route('home');
      this.route('away');
    });
    this.route('downloads');
  });
  this.route('import-teams', { path: 'import-teams/:bask-season_id' });
  this.route('import-players', { path: 'import-players/:bask-team-season_id' }, function() {});
  this.route('import-teamplayer', { path: 'import-teamplayer/:bask-team_id' }, function() {});
  this.route('catch-all', { path: '*:' });
  this.route('import');

  this.route('playerseason', { path: 'playerseason/:bask-player-season_id' }, function() {
    this.route('boxscore');
    this.route('edit');
    this.route('downloads');
    this.route('visuals');
  });

  this.route('playerseasons', function() {
    this.route('add');
    this.route('add-roster');
  });
  this.route('schedule');
  this.route('standings');
  this.route('event', function() {
    this.route('edit');
  });
  this.route('super');
  this.route('video', { path: 'video/:video_id'});

  this.route('timing', function() {
    this.route('edit');
  });

  this.route('breakdowns', function() {
    this.route('add');
  });
  this.route('features');
  this.route('public');
  this.route('filtered-report', { path: 'filtered-report/:bask-team-season_id' });
});
export default Router;
