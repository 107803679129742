import { computed } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import $ from 'jquery';
export default Controller.extend({
  session: service(),
  sortedPlayers: computed('model.players.[]', function() {
    return this.get('model.players').sortBy('sortableNumber');
  }),
  actions: {
    delete() {
      let player = this.get('deletingPlayer');
      return player.destroyRecord();
    },
    confirmDelete(player) {
      $('.ui.modal').modal('show');
      this.set('deletingPlayer', player);
    },
    denyModal() {

    },
    toggleButtons(player) {
      player.toggleProperty('showingButtons');
    }
  }

});
