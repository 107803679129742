import Service from '@ember/service';
import { run } from '@ember/runloop';
import { Promise as EmberPromise } from 'rsvp';
import { task } from 'ember-concurrency';
import ENV from '../config/environment';
export default Service.extend({

  init() {
    this._super(...arguments);
  },
  load(needs){
    if (needs)
    {
      return this.get('_load').perform(needs);
    }
  },
  loadStripe() {
    return loadScript("https://checkout.stripe.com/checkout.js");
  },
  loadTimezone() {
    return loadScript("https://cdnjs.cloudflare.com/ajax/libs/moment-timezone/0.5.23/moment-timezone-with-data-2012-2022.min.js");
  },
  loadGrid() {
    return this.get('_loadGrid').perform();
  },
  loadVideo() {
    return this.get('_loadVideo').perform();
  },
  _load:task (function* (needs){
    for (var v=0;v<needs.length;v++)
      {
        switch (needs[v])
        {
          case 'stripe': yield this.loadStripe(); break;
          case 'grid': yield this.loadGrid(); break;
          case 'video': yield this.loadVideo(); break;
          case 'timezone': yield this.loadTimezone(); break;
        }
      }
  }), 
  _loadVideo: task(function* () {
    yield loadCSS("https://vjs.zencdn.net/7.4.1/video-js.min.css"); //eslint-disable-line
    yield loadScript("https://vjs.zencdn.net/7.4.1/video.min.js");
    yield loadScript("https://cdnjs.cloudflare.com/ajax/libs/videojs-youtube/2.6.0/Youtube.js");
  }),
  _loadGrid: task(function* () {
    if (ENV.stage === 'dev')
    {
      yield loadCSS("https://cdnjs.cloudflare.com/ajax/libs/jsgrid/1.5.3/jsgrid.min.css"); //eslint-disable-line
      yield loadCSS("https://cdnjs.cloudflare.com/ajax/libs/jsgrid/1.5.3/jsgrid-theme.min.css"); //eslint-disable-line
      yield loadScript("https://cdnjs.cloudflare.com/ajax/libs/jsgrid/1.5.3/jsgrid.js");
    }
    else
    {
      yield loadCSS("https://cdnjs.cloudflare.com/ajax/libs/jsgrid/1.5.3/jsgrid.min.css"); //eslint-disable-line
      yield loadCSS("https://cdnjs.cloudflare.com/ajax/libs/jsgrid/1.5.3/jsgrid-theme.min.css"); //eslint-disable-line
      yield loadScript("https://cdnjs.cloudflare.com/ajax/libs/jsgrid/1.5.3/jsgrid.min.js");
    }
  }),
});
let loadedScripts = {};

function loadScript(url) {
  let promise = loadedScripts[url];
  if (!promise) {
    promise = new EmberPromise((resolve, reject) => {
      let element = document.createElement('script');
      element.type = 'text/javascript';
      element.async = false;
      element.addEventListener('load', () => {
        run(() => {
          resolve();
        });
      }, false);
      element.addEventListener('error', () => {
        let error = new Error(`Could not load script ${url}`);
        run(() => {
          reject(error);
        });
      }, false);

      element.src = url;

      let firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(element, firstScript);
    });

    loadedScripts[url] = promise;
  }

  return promise;
}
