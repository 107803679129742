import UploadMixin from '../../mixins/fb-upload';
import { task } from 'ember-concurrency';
import roster from '../../mixins/roster';
import { isBlank } from '@ember/utils';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
export default Controller.extend(UploadMixin, roster, {
  queryParams: ['season', 'destination'],
  season: null,
  createTeamSeason: true,
  actions: {
    save() {
      this.get('_save').perform();
    }
  },
  canSave: computed('_save.isRunning', 'hasName', function() {
    return !this.get('_save.isRunning') && this.get('hasName');
  }),
  hasName: computed('name', function() { //eslint-disable-line
    return !isBlank(this.get('name'))
  }),
  _save: task(function* () {
    let entries = this.get('rosterEntries');
    let season = this.get('model.season');
    let createTeamSeason = this.get('createTeamSeason');
    if (season && createTeamSeason) {
      let attrs = this.getProperties([
        'name',
        'abbr',
        'mascot',
        'homeColor',
        'awayColor',
        'division',
        'email',
        'league',
        'tags'
      ]);

      let team = this.store.createRecord('bask-team', attrs);
      yield team.save();
      let account = this.get('model.account');
      team.set('account', account);
      account.get('teams').pushObject(team);
      let teamSeason = this.store.createRecord('bask-team-season', {
        season,
        team,
        account: account.id
      });
      let teamSeasons = season.get('teamSeasons');
      teamSeasons.pushObject(teamSeason);
      let accountId = account.get('id');
      let model = yield this.saveUploads(team, accountId);
      this.clear();
      yield model.save();
      yield account.save();
      yield teamSeason.save();
      yield season.save();

    if (entries.length) {
      let players = team.get('players');
      let playerSeasons = teamSeason.get('playerSeasons');
      for (let i = 0; i < entries.length; i++ ) {
        let ps = entries[i];
        let playerSeason = this.store.createRecord('bask-player-season');
        let player = this.store.createRecord('bask-player');
        this.shimPlayer(ps, accountId, team, player, playerSeason, teamSeason);
        this.shimPlayerSeason(ps, accountId, teamSeason, player, playerSeason);
        yield player.save();
        yield playerSeason.save();
        players.pushObject(player);
        playerSeasons.pushObject(playerSeason);
      }
      yield teamSeason.save();
      yield team.save();
      this.set('rosterEntries',[]);
    }

      let destination = this.get('destination');
      if (destination) {
        return this.transitionToRoute(destination);
      }
      this.transitionToRoute('season.teamseasons', season.get('id'));

    } else {
      let team = this.store.createRecord('bask-team', this.getProperties([
        'name',
        'abbr',
        'mascot',
        'homeColor',
        'awayColor',
        'division',
        'email',
        'league',
        'tags'
      ]));
      let account = this.get('model.account');
      team.set('account', account);
      account.get('teams').pushObject(team);
      let accountId = account.get('id');
      let model = yield this.saveUploads(team, accountId);
      this.clear();
      yield model.save();
      yield account.save();

      if (entries.length) {
        let players = team.get('players');
        for (let i = 0; i < entries.length; i++ ) {
          let ps = entries[i];
          let player = this.store.createRecord('bask-player');
          this.shimPlayer(ps, accountId, team, player, undefined, undefined);
          yield player.save();
          players.pushObject(player);
        }
        yield team.save();
        this.set('rosterEntries',[]);
      }

      let destination = this.get('destination') || 'teams';
      this.transitionToRoute(destination);
    }
  })
});
