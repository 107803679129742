import UploadMixin from '../../mixins/fb-upload';
import { task } from 'ember-concurrency';
import Controller from '@ember/controller';

/* global moment */
export default Controller.extend(UploadMixin, {
  actions: {
    save() {
      this.get('_save').perform();
    },
    cancel() {
      let season = this.get('model.season');
      season.rollbackAttributes();
      this.transitionToRoute('seasons');
    }
  },
  _save: task(function* () {
    let season = this.get('model.season');
    let attrs = this.getProperties([
      'start',
      'seasonType',
      'name'
    ]);
    if (attrs.start) {
      attrs.start = moment(attrs.start, 'MM/DD/YYYY');
    }
    else
    {
      attrs.start = moment();
    }

    season.setProperties(attrs);
    let accountId = season.get('account');
    yield this.saveUploads(season, accountId);
    this.clear();
    yield season.save();
    this.transitionToRoute('seasons');
  })
});
