
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import UploadMixin from '../mixins/fb-upload';
import { Lambda } from 'aws-sdk';
import ENV from '../config/environment';

const region = 'us-west-2';

/*global moment*/
export default Controller.extend(UploadMixin, {
  init() {
    this._super();
    this.set('seasonId', "");
    this.set('finalize', false);
    this.set('accountId',this.get('session.user.activeAccount'));
    this.set('srcAccountId', this.get('accountId'));
    this.set('destAccountId', this.get('accountId'));
    this.set('srcEnv', 'dev');
    this.set('destEnv', 'dev');
    this.set('srcSeason', '');
    this.set('videoSeason', '');
    this.set('include720', true);
    this.set('include360', true);
  },
  actions: {
    updateSelected(index, txt, value) {
      if (value)
      {
        this.set('accountId', index);
      }
    }
  },
  session: service(),
  hasAccountId: computed('accountId', function() {
    return this.get('accountId').length > 0;
  }),
  hasConfirmedAccountId: computed('confirmId', 'accountId', function() {
    let accountId=this.get('accountId');
    let confirmId=this.get('confirmId');
    return (accountId && accountId.length>0 && confirmId === accountId);
  }),
  hasSrcAccountId: computed('srcAccountId', function() {
    return this.get('srcAccountId').length > 0;
  }),
  hasDestAccountId: computed('destAccountId', function() {
    return this.get('destAccountId').length > 0;
  }),
  hasSrcEnv: computed('srcEnv', function() {
    return this.get('srcEnv').length > 0;
  }),
  hasDestEnv: computed('destEnv', function() {
    return this.get('destEnv').length > 0;
  }),
  canSave: computed('save.isRunning', 'hasAccountId', function() {
    return !this.get('save.isRunning') && this.get('hasAccountId');
  }),
  canHeal: computed('heal.isRunning', 'hasConfirmedAccountId', function() {
    return !this.get('heal.isRunning') && this.get('hasConfirmedAccountId');
  }),
  canRestore: computed('restore.isRunning', 'hasConfirmedAccountId', function() {
    return !this.get('restore.isRunning') && this.get('hasConfirmedAccountId');
  }),
  canBackup: computed('backup.isRunning', 'hasConfirmedAccountId', function() {
    return !this.get('backup.isRunning') && this.get('hasConfirmedAccountId');
  }),
  canCreateTokenUrl: computed('token.isRunning', 'hasAccountId', function() {
    return !this.get('token.isRunning') && this.get('hasAccountId');
  }),
  canMigrate: computed('migrate.isRunning', 'hasDestAccountId', 'hasSrcAccountId', 'hasSrcEnv', 'hasDestEnv', function() {
    let srcId=this.get('srcAccountId');
    let destId =this.get('destAccountId');
    let srcEnv =this.get('srcEnv');
    let destEnv =this.get('destEnv');
    let notRunning = !this.get('migrate.isRunning');
    //return notRunning && srcId && destId && srcEnv && destEnv && srcId!==destId && destEnv !== srcEnv;
    return notRunning && srcId && destId && srcEnv && destEnv && srcId!==destId;
  }),
  canProcess: computed('reprocessVideo.isRunning', 'hasAccountId', 'videoSeason', function() {
    let accountId=this.get('accountId');
    let season =this.get('videoSeason');
    let notRunning = !this.get('reprocessVideo.isRunning');
    return notRunning && accountId && season;
  }),
  quality: computed('include4k', 'include1080', 'include720', 'include480', 'include360', function() {
    let ret=[];
    if (this.get('include4k')) { ret.push(4000);}
    if (this.get('include1080')) { ret.push(1080);}
    if (this.get('include720')) { ret.push(720);}
    if (this.get('include480')) { ret.push(480);}
    if (this.get('include360')) { ret.push(360);}
    return ret;
  }),
  migrate: task(function* () {
    let api = this.get('st-api');
    if (api) {
      yield api.migrate({
        "sourceEnv": this.get('srcEnv'),
        "sourceAccount": this.get('srcAccountId'),
        "destEnv": this.get('destEnv'),
        "destAccount": this.get('destAccountId'),
        "account": this.get('srcAccountId'),
        "season": this.get('srcSeason')
      });
    }
    
  }).drop(),
  generateToken: task(function* () {
    let auth=this.get('firebaseApp').auth();
    let token = yield auth.currentUser.getToken();
    let uid = this.get('session.user.id');
    if (token && uid)
    {
      let api = this.get('st-api');
      let params ={
        'account': this.get('accountId'),
        uid 
      };
      if (api) {
         let result = yield api.token(params, token);
         if (result && result.token)
         {
           this.set('tokenUrl', `${this.get('tokenUrl')}?token=${result.token}`);
         }
      }
    }
  }),
  reset: task(function* () {
    let user = this.get('session.user');
    if (user) {
      let userId=user.get('id');
      let accounts=this.get('model.accounts');
      let myAccount=undefined;
      accounts.forEach((a)=>{ 
        if (a.get('owner') === userId)
        {
          myAccount=a;
        }
      });
      if (myAccount)
      {
        this.set('accountId',myAccount.get('id'));
        user.set('activeAccount',myAccount.get('id'));
        yield user.save();
        yield this.get('session._loadCurrentSession').perform();
      }
    }
  }),
  heal: task(function* () {
    let api = this.get('st-api');
      if (api) {
        yield api.heal({
          "account":this.get('accountId')
        });
      }
  }),
  reprocessVideo: task(function* (){
    let accountId = this.get('accountId');
    let quality=this.get('quality');
    let season = this.get('videoSeason');
    let game = this.get('videoGame');
    let store = this.store;
    let games = [];
    if (season && game)
    {
      games.push(yield store.find('bask-game',game));
    }
    else if (season)
    {
      let q = yield store.query('bask-game', {
        orderBy: 'season',
        equalTo: season
      });
      
      games = yield q.toArray();
    }
    
    for (var i=0;i<games.length;i++)
    {
      var g=games[i];
      if (g.get('hasFilm'))
      {
        let loadTeam = (prop) => {
          let id = g.get(prop + '.id');
          return store.query('bask-team', {
            orderBy: 'teamSeasons/'+ id,
            equalTo: true
          }).then((result) => result.toArray()[0]);
        };
        

        let homeTeam=yield loadTeam('homeTeam');
        let homeTeamName = yield homeTeam.get('name');
        let awayTeam=yield loadTeam('awayTeam');
        let awayTeamName = yield awayTeam.get('name');
        let location=yield g.get('location');
        if (location)
        {
          location=` at ${location}`;
        }
        else
        {
          location = '';
        }
        let dt = moment(yield g.get('date')).format('MM-DD-YYYY');
        let gameName=`${dt}  ${homeTeamName} vs ${awayTeamName}${location}`;
    
        yield invokeVideo(g.get('id'),accountId,season,gameName,quality);
      }
    }
  }),
  backup: task(function* () {
    yield invokeBackup(this.get('accountId'));
  }),
  restore: task(function* () {
    let api = this.get('st-api');
      if (api) {
        yield api.restore({
          "account":this.get('accountId'),
          "destAccount": this.get('accountId')
        });
      }
  }),
  save: task(function* () {
    let user = this.get('session.user');
    if (user) {
      let oldAccount = user.get('activeAccount');
      if (oldAccount !== this.get('accountId'))
      {
        user.set('activeAccount',this.get('accountId'));
        yield user.save();
        yield this.get('session._loadCurrentSession').perform();
      }
    }
    let uploads = this.get('uploads');
    if (uploads.length)
    {
      let accountId = this.get('accountId');
      let seasonId = this.get('seasonId');
      let season = undefined;
      if (seasonId)
      {
        season = yield this.store.findRecord('bask-season', seasonId);
      }
      if (!season) {
        season=this.store.createRecord('bask-season', {
          "name": "Import",
          "start": moment()
        });
        seasonId=season.get('id');
        season.set('account', accountId);
        yield season.save();
      }
      this.set('root', 'imports');
      
      yield this.saveUploads(season, accountId );
      this.clear();

      let api = this.get('st-api');
      if (api) {
        yield api.import({
          "season": seasonId,
          "account":accountId,
          "objectName": `${this.get('root')}/${accountId}/baskSeasons/${seasonId}/import`,
          "finalize": this.get('finalize')
        });
      }
    }
  }).drop(),
});
function invokeVideo(gameId, account, season, name, quality)
{
  return new Promise(function(resolve, reject) {
    var awsLambda = new Lambda({
        region,
        accessKeyId: 'AKIAJJIEFQ3VOQP334KQ',
        secretAccessKey: 'H93caPbYDBDq7D2JhCgIrqSdr8Q1oAJYCQvlMa2C'
    })
    awsLambda.invoke({
        FunctionName: 'arn:aws:lambda:us-west-2:172166497234:function:video', // arn of your lambda function
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
          account,
          season,
          gameId,
          name,
          stage: ENV.stage,
          quality
        })
    }, function (err, data) {
        if (err) {
          return reject(err);
        }
        resolve(JSON.parse(data.Payload));
    });
  });
}
function invokeBackup(account)
{
  return new Promise(function(resolve, reject) {
    var awsLambda = new Lambda({
        region,
        accessKeyId: 'AKIAIPEMQFV2QK5E3GNQ',
        secretAccessKey: '/Y2IGKQLzTUWPaTzU2FugiKbcojC4Tc968ByO2XH'
    })
    awsLambda.invoke({
        FunctionName: 'arn:aws:lambda:us-west-2:172166497234:function:_backup', // arn of your lambda function
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
          "Records": [
            {
              "Sns": {  
                "Message": JSON.stringify({
                 env: ENV.stage,
                 account
                })
              }
            }
          ]
        })
    }, function (err, data) {
        if (err) {
          return reject(err);
        }
        resolve(JSON.parse(data.Payload));
    });
  });
}