define("stattracker-live/adapters/bask-event", ["exports", "stattracker-shared/adapters/bask-event"], function (_exports, _baskEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _baskEvent.default;
    }
  });
});
