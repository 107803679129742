import Controller from '@ember/controller'
import { computed } from '@ember/object';
import playerVisualMixin from '../../mixins/player-career-visual-mixin';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
export default Controller.extend(playerVisualMixin, {
  session: service(),
  intl: service(),

  init() {
    this._super();
  },

  aspect: .42857,
  refreshClass: "ui button",

  caption: computed('model.player.status', function(){
    let status = this.get('model.player.status');
    let key = `common.generate`;
    if (status === 'reporting') {
      key = 'game.status.reporting'
    } else if (status === 'finished') {
      key = 'common.refresh';
      if (this.get('isDirty'))
      {
        this.set('isDirty', false);
        this.get('_updateReport').perform();
      }
    }
    return this.get('intl').t(key);
  }),

  canRefresh: computed('model.player.status', function(){
    let status = this.get('model.player.status');
    if (status !== 'reporting')
    {
      this.set('refreshClass', 'ui button');
    }
    else {
      this.set('refreshClass', 'ui button disabled');
    }
    return status !== 'reporting';
  }),

  actions: {
    refreshStats(){
      this.set('refreshClass', 'ui button disabled');
      this.set('isDirty', true);
      let model=this.get('model');
      let params = {
          player: model.player.id,
          account: model.player.get('account'),
          reportType: 'all',
          report: 'playerCareer'
      };
      let api = this.get('st-api');
      if (api) {
        return api.finalize(params).then((response) => {
          console.log(response);
          //this.transitionToRoute('index');
        });
      }
    }
  },

  _updateReport: task(function* () {
    let playerReport = yield this.store.findRecord('player-career-report', this.get('model.player.id'), {reload: true});
    this.setupVisuals(true, true, this, playerReport);
    this.set('refreshClass', 'ui button');
  })
});
