import Ember from 'ember';
const { Route, isPresent, inject: { service }, RSVP: { hash } } = Ember;
export default Route.extend({
  session: service(),
  model(params) {

    let promise = {};
    if (isPresent(params.teamId)) {
      promise.primary = this.store.find('bask-team', params.teamId);
    } else if (isPresent(params.seasonId)) {
      promise.primary = this.store.find('bask-season', params.seasonId);
    } else {
      return new Error('No Season or Team to attach TeamSeason to');
    }
    promise.account = this.get('session.account');
    return hash(promise).then((promiseHash) => {
      if (isPresent(params.teamId)) {
        promiseHash.all = this.store.query('bask-season', {
          orderBy: 'account',
          equalTo: promiseHash.account.id
        });
      } else {
        promiseHash.all = promiseHash.account.get('teams');
      }
      promiseHash.teamSeasons = promiseHash.primary.get('teamSeasons');
      return hash(promiseHash);
    });
  },

  setupController(controller, model) {
    let isAddingToTeam = isPresent(controller.get('teamId'));
    controller.set('isAddingToTeam', isAddingToTeam);
    controller.set('modelType', isAddingToTeam ? 'season' : 'team');
    controller.set('returnRoute', isAddingToTeam ? 'team.allseasons' : 'season.teamseasons');

    controller.set('display', model.all.filter((item) => {
      return !model.teamSeasons.mapBy(controller.get('modelType') + '.id').includes(item.get('id'));
    }));
    this._super(controller, model);
  },
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('teamId', undefined);
    }
  }
});
