import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
export default Controller.extend({
  intl: service(),
  init() {
    let filterTypes = [];
    filterTypes.push({ name: this.get('intl').t('app.report.leagueFilter'), id:'league'});
    filterTypes.push({ name: this.get('intl').t('app.report.neutralFilter'), id:'neutralCourt'});
    filterTypes.push({ name: this.get('intl').t('app.report.playoffFilter'), id: 'playoff'});
    this.set('filterTypes', filterTypes);
    this.set('filterType', 'league');
    this._super(...arguments);
  },
  actions: {
    generateReport() {
      this.get('_generateReport').perform();
    },
    cancel() {
      this.transitionToRoute(`teamseason.downloads`, this.get('model.teamSeason'));
    }
  },
  _generateReport: task(function* () {
    let customFilter = this.get('customFilter');
    let api = this.get('st-api');
    if (api) {
      let account = this.get('model.account.id');
      let season = this.get('model.season.id');
      let teamSeason = this.get('model.teamSeason.id');
      let params = {
        season,
        account,
        reportType: 'teamSeason',
        teamSeason,
        filter: this.get('filterType')
      };
      if (customFilter) {
        params['filter'] = this.get('filter');
      }
      yield api.finalize(params);
      this.transitionToRoute(`teamseason.downloads`, this.get('model.teamSeason'));
    }
  })
});
