import Ember from 'ember';
import liveData from '../mixins/live-data';
import Controller from '@ember/controller';
const {  inject } = Ember;
export default Controller.extend(liveData, {
  init() {
    this._super();
    this.set('games',this.store.peekAll('bask-game'));
    this.set('standings',this.store.peekAll('bask-standing'));
  },
  session: inject.service(),
  actions: {
    transition(location, data) {
      this.transitionToRoute(location, data);
    }
  }
});
