// jscs:disable
import apiGateway from './apiGatewayClient';
import uritemplate from './url-template';

/*
 * Copyright 2010-2016 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

let apigClientFactory = {};
apigClientFactory.newClient = function(config) {
  let apigClient = {};
  if (config === undefined) {
    config = {
      accessKey: '',
      secretKey: '',
      sessionToken: '',
      region: '',
      apiKey: undefined,
      defaultContentType: 'application/json',
      defaultAcceptType: 'application/json',
      endpoint: ''
    };
  }
  if (config.accessKey === undefined) {
    config.accessKey = '';
  }
  if (config.secretKey === undefined) {
    config.secretKey = '';
  }
  if (config.apiKey === undefined) {
    config.apiKey = '';
  }
  if (config.sessionToken === undefined) {
    config.sessionToken = '';
  }
  if (config.region === undefined) {
    config.region = 'us-east-1';
  }
  // If defaultContentType is not defined then default to application/json
  if (config.defaultContentType === undefined) {
    config.defaultContentType = 'application/json';
  }
  // If defaultAcceptType is not defined then default to application/json
  if (config.defaultAcceptType === undefined) {
    config.defaultAcceptType = 'application/json';
  }

  // Remove trailing '/'
  if (config.endpoint.substr(-1) === '/') {
    config.endpoint = config.endpoint.substr(0, config.endpoint.length - 1);
  }
  let urlParts = config.endpoint.split('/');
  let tag = `/${urlParts[urlParts.length - 1]}`;
  let endpoint = config.endpoint.substr(0, config.endpoint.length - tag.length);

  let sigV4ClientConfig = {
    accessKey: config.accessKey,
    secretKey: config.secretKey,
    sessionToken: config.sessionToken,
    serviceName: 'execute-api',
    region: config.region,
    endpoint,
    defaultContentType: config.defaultContentType,
    defaultAcceptType: config.defaultAcceptType
  };

  let authType = 'NONE';
  if (sigV4ClientConfig.accessKey !== undefined && sigV4ClientConfig.accessKey !== '' && sigV4ClientConfig.secretKey !== undefined && sigV4ClientConfig.secretKey !== '') {
    authType = 'AWS_IAM';
  }

  let simpleHttpClientConfig = {
    endpoint,
    defaultContentType: config.defaultContentType,
    defaultAcceptType: config.defaultAcceptType
  };

  let apiGatewayClient = apiGateway.core.apiGatewayClientFactory.newClient(simpleHttpClientConfig, sigV4ClientConfig);

  apigClient.send = function(params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ['body']);

    let apiRequest = {
      verb: 'post'.toUpperCase(),
      path: tag + uritemplate('/send').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(apiRequest, authType, additionalParams, config.apiKey);
  };
  apigClient.clipFilm = function(params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
    body.type='clipFilm';
    let apiRequest = {
      verb: 'post'.toUpperCase(),
      path: tag + uritemplate('/lrp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(apiRequest, authType, additionalParams, config.apiKey);
  };
  apigClient.import = function(params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
    body.type='import';
    let apiRequest = {
      verb: 'post'.toUpperCase(),
      path: tag + uritemplate('/lrp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(apiRequest, authType, additionalParams, config.apiKey);
  };
  apigClient.finalize = function(params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
    body.type='finalize';
    let apiRequest = {
      verb: 'post'.toUpperCase(),
      path: tag + uritemplate('/lrp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(apiRequest, authType, additionalParams, config.apiKey);
  };
  apigClient.migrate = function(params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
    body.type='migrate';
    let apiRequest = {
      verb: 'post'.toUpperCase(),
      path: tag + uritemplate('/lrp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(apiRequest, authType, additionalParams, config.apiKey);
  };
  apigClient.restore = function(params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
    body.type='restore';
    let apiRequest = {
      verb: 'post'.toUpperCase(),
      path: tag + uritemplate('/lrp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(apiRequest, authType, additionalParams, config.apiKey);
  };
  apigClient.heal = function(params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
    body.type='heal';
    let apiRequest = {
      verb: 'post'.toUpperCase(),
      path: tag + uritemplate('/lrp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(apiRequest, authType, additionalParams, config.apiKey);
  };
  apigClient.breakdown = function(params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
    body.type='breakdown';
    let apiRequest = {
      verb: 'post'.toUpperCase(),
      path: tag + uritemplate('/lrp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(apiRequest, authType, additionalParams, config.apiKey);
  };

  apigClient.img = function(params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ['body']);

    let apiRequest = {
      verb: 'post'.toUpperCase(),
      path: tag + uritemplate('/img').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(apiRequest, authType, additionalParams, config.apiKey);
  };
  return apigClient;
};

export default apigClientFactory;
