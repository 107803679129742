import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { task } from 'ember-concurrency';
export default Route.extend({
  session: service(),
  lazyload: service(),
  beforeModel() {
    return this.get('lazyload').loadGrid();
  },
  model(params) {
    let seasonId = params.season;
    return this.get('loadData').perform(seasonId);
  },
  loadData: task(function* (seasonId) {
    if (!isEmpty(seasonId)) {
      let season = yield this.store.find('bask-season', seasonId)
      return {
        account: this.get('session.account'),
        season
      };
      
    } else {
      let account = this.get('session.account');
      let activeSeason = account.get('activeSeason');
      let seasons = yield this.store.findAll('bask-season');
      let season = null;
      seasons.forEach(u => {
        if (u.get('id') === activeSeason)
        {
          season=u;
        }
      })
      return {
        account,
        season
      };
    }
  }),
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('queryParams', null);
      controller.set('season', null);
      controller.set('name', '');
      controller.set('abbr', '');
      controller.set('mascot', '');
      controller.set('email', '');
      controller.set('division', '');
      controller.set('league', '');
      controller.set('imageURL', '');
      controller.set('logoURL', '');
      controller.set('tags', '');
    }
  },
  actions: {
    cancel() {
      this.transitionTo('teams');
    }
  }
});
